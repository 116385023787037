import * as React from 'react';
import cn from 'classnames';
import SVG from 'react-inlinesvg';

import './SidebarItemIcon.sass';


interface IProps {
    src: string;

    className?: string;
    size?: 'size-12';
}

const SidebarItemIcon: React.SFC<IProps> = (props) => {
    const { className, src, size } = props;

    return (
        <SVG className={cn('SidebarItemIcon', className, size)} cacheGetRequests src={src} />
    );
};

export default SidebarItemIcon;
