import * as React from 'react';
import cn from 'classnames';

import Paper from 'components/Layout/Paper';
import './Button.sass';

export interface IProps {
    className?: string;
    children: React.ReactNode;

    type?: 'primary' | 'hollow' | 'icon' | 'danger';
    onFetchDisabled?: boolean;
    onClick?: (event) => void;
}

const Button: React.SFC<IProps> = (props) => {
    let _ref = React.createRef();

    async function handleClick(e: React.MouseEvent<HTMLAnchorElement>): Promise<void> {
        e.preventDefault();

        if (!props.onFetchDisabled) {
            props.onClick(e);
            return;
        }

        e.persist();
        (e.target as Element).parentElement.classList.add('disabled');
        try {
            await props.onClick(e);
        } finally {
            (e.target as Element).parentElement.classList.remove('disabled');
        }
    }

    const { className, type } = props;
    return (
        <Paper className={cn('Button', className, type)}>
            <a className='Button-anchor' onClick={e => handleClick(e)} ref={_ref as React.RefObject<any>}>
                {props.children}
            </a>
        </Paper>
    );
};

export default Button;
