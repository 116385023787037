import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { LogStreamInfo } from 'api/RadioServiceAPI';
import { ProgramLogsStore } from '../../redux/stores/ProgramLogs/ProgramLogsStore';
import { DropdownItem } from '../Layout/Dropdown/Dropdown';

import Button from 'components/Layout/Button/Button';
import Content from 'components/Content/Content';
import HorizontalScroll from 'components/Layout/HorizontalScroll';
import Header from 'components/Content/Header/Header';
import ProgramLogsHeadline from 'components/Content/ProgramLogsHeadline';
import Paper from 'components/Layout/Paper';
import Space from 'components/Layout/Space';
import UpperText from 'components/Layout/UpperText/UpperText';
import ListStatus from '../List/ListStatus';
import CollapsableTableRow from '../Layout/Table/CollapsableTableRow/CollapsableTableRow';
import NextCollapsableTableRow from '../Layout/Table/CollapsableTableRow/NextCollapsableTableRow';
import CollapsableTable from '../Layout/Table/CollapsableTableRow/CollapsableTable';
import MetaDataConfigurationPage from './ProgramLogsConfiguration/MetaDataConfigurationPage';
import Filter from '../List/Filter';

import utils from '../../utils';

import './ProgramLogsPage.sass';


export interface Props {
    className?: string;
}

export const checkData = ['advertiserId', 'advertiserName', 'campaignName', 'campaignGoal', 'productName', 'productCategory', 'adFormat'];

export function processLogStreamMetaDataInfo(logStreamInfo: LogStreamInfo): string {
    let completed = 0;

    if (!logStreamInfo) {
        return '';
    }

    for (const attr of checkData) {
        if (logStreamInfo[attr]) {
            completed++;
        }
    }

    let percentage = ((100 / checkData.length) * completed);
    if (percentage === Infinity) {
        percentage = 0;
    }

    if (percentage === 100) {
        return 'md-info-good';
    } else if (percentage > 50) {
        return 'md-info-ok';
    } else {
        return 'md-info-bad';
    }
}

class ProgramLogsPage extends Component<Props & RouteComponentProps<{}>> {
    found: boolean = false;
    stationDropdown: DropdownItem[] = [];
    store = new ProgramLogsStore();

    async componentDidMount() {
        const params = utils.searchToParams(this.props.history.location.search);

        this.store.params = Object.assign(
            this.store.params,
            params);

        await this.store.getStations();

        let filterObj = utils.searchToParams(this.store.params.filter, false);
        if (!!this.store.stations.length) {
            filterObj = {
                station: this.store.stations[0].id
            };
        }

        this.store.params = Object.assign(
            {
                filter: utils.paramsToPath('', filterObj).substring(1)
            },
            this.store.params,
            params);

        await this.load();
    }

    async componentWillReceiveProps(nextProps: Props & RouteComponentProps<{}>) {
        if (this.props.location.search !== nextProps.location.search) {
            await this.store.loadFromSearch(nextProps.location.search);
        }
    }

    async load(): Promise<void> {
        await this.store.load();
    }

    processLogStreamTimeStampInfo(logStreamInfo: LogStreamInfo): string {
        let nowTime = Math.floor(Date.now() / 1000).toString();

        if (logStreamInfo.timestamp > nowTime) {
            return 'ProgramLogsLegendCircle green';
        } else if (this.found === false) {
            this.found = true;
            return 'ProgramLogsLegendCircle red';
        } else {
            return 'ProgramLogsLegendCircle gray';
        }
    }

    getTableRowColor(percentage: number) {

        if (percentage === 100) {
            return 'md-info-good';
        } else if (percentage > 50) {
            return 'md-info-ok';
        }
        return 'md-info-bad';
    }

    initStationDropdown(): void {
        if (this.store.stations.length === this.stationDropdown.length) {
            return;
        }
        this.stationDropdown = this.store.stations.map(val => { return { id: val.id, value: val.name }; });
    }

    render() {
        this.found = false;
        this.initStationDropdown();

        const { items } = this.store;

        const header = <Header>
            <h1>Program logs</h1>

            <Space/>

            <UpperText className='Header-upperText' text='Station'>
                <Filter store={this.store} field='station' type='dropdown' dropdownData={this.stationDropdown} />
            </UpperText>


            <Button className='Header-button' onClick={() => this.store.load()} type='primary'>Update</Button>
        </Header>;

        return (
            <Content header={header} className={cn('AdManagementPage')}>
                <Paper className='Content-paper' type='wide'>
                    <ProgramLogsHeadline>{this.store.selectedStation && `${this.store.selectedStation.name} ${this.store.selectedStation.frequency} MHz`}</ProgramLogsHeadline>

                    <HorizontalScroll>
                        <table className='Table table table-list'>
                            <thead>
                                <tr className='headerRow'>
                                    <th className='va-middle'><div className='ProgramLogsLegendCircle gray'></div></th>
                                    <th>Scheduled</th>
                                    <th>Stack</th>
                                    <th>Title</th>
                                    <th>Artist</th>
                                    <th>Trivia</th>
                                    <th>Category</th>
                                    <th>Cart</th>
                                    <th>Duration</th>
                                    <th>MediaType</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items && !!items.length && items.map((lsinfo, index) =>
                                    <CollapsableTable key={lsinfo.id} >
                                        <NextCollapsableTableRow className={processLogStreamMetaDataInfo(lsinfo)}>
                                            <td><div className={this.processLogStreamTimeStampInfo(lsinfo)}></div></td>
                                            <td>{lsinfo.scheduled}</td>
                                            <td>{lsinfo.stack}</td>
                                            <td>{lsinfo.title}</td>
                                            <td>{lsinfo.artist}</td>
                                            <td>{lsinfo.trivia}</td>
                                            <td>{lsinfo.category}</td>
                                            <td>{lsinfo.cart}</td>
                                            <td>{lsinfo.duration}</td>
                                            <td>{lsinfo.mediaType}</td>
                                        </NextCollapsableTableRow>

                                        <CollapsableTableRow colspan={10}>
                                            <MetaDataConfigurationPage cartId={lsinfo.cart} />
                                        </CollapsableTableRow>
                                    </CollapsableTable>
                                )}

                            </tbody>
                        </table>

                    </HorizontalScroll>

                    <ListStatus store={this.store} emptyText='program logs' />

                </Paper>
            </Content>
        );
    }
}

const mapStateToProps = state => ({
    programLogs: state.programLogs
});

export default withRouter( connect(mapStateToProps)(ProgramLogsPage) );
