import * as React from 'react';
import cn from 'classnames';

import Dropdown, { DropdownItem } from './Dropdown';
import OutsideClickHandler from 'components/React/OutsideClickHandler';

import './ExpandableDropdown.sass';

export interface Props {
    className?: string;
    text: string;
    textAsKey?: boolean; // If you send text as id value, this enables finding correct text based on value given (lookup data format)
    direction?: 'down' | 'up';
    data?: DropdownItem[];
    checkbox?: boolean;
    minCheckedItems?: number;
    filter?: boolean;
    placeholder?: string;
    onDataChangeClick?(id: string, value: string): void;
    onSelectAll?: (type: 'all' | 'none') => void;
}

export interface State {
    open: boolean;
}

export class ExpandableDropdown extends React.Component<Props, State> {
    state: State = {
        open: false,
    };

    placeholder: DropdownItem;

    handleButtonClick = (e) => {
        e.preventDefault();
        const { checkbox } = this.props;
        const { open } = this.state;
        if (checkbox && open) {
            return;
        }
        this.setState({ open: !open });
    }

    handleOutsideClick = () => {
        const open = false;
        this.setState({ open });
    }

    getDropdownText = (): string | JSX.Element => {
        const { checkbox, data, text, textAsKey } = this.props;

        if (!text || !text.length) {
            const { placeholder } = this.props;
            return placeholder ? placeholder : 'Select';
        }

        if (checkbox) {
            const checkedItemsCount = data.filter(item => item.checked).length;
            if (checkedItemsCount) {
                const firstItem = data.find(item => item.checked);
                const additionalText = checkedItemsCount > 1 ? <span className='checkbox-additional'>+{checkedItemsCount - 1}</span> : '';
                return <><span>{firstItem.value}</span>{additionalText}</>;
            }
        }

        if (textAsKey) {
            const find = data.find(d => d.id === text);
            if (find) {
                return find.value;
            }
        }

        return text;
    }

    render() {
        const { className, data, text, placeholder, filter, minCheckedItems, onSelectAll, ...rest} = this.props;

        if (placeholder) {
            this.placeholder = {
                id: '-1',
                value: placeholder
            };
        }

        return (
            <OutsideClickHandler onHandleClickOutside={this.handleOutsideClick}>
                <Dropdown
                    onButtonClick={this.handleButtonClick}
                    onDataChangeClick={this.props.onDataChangeClick}
                    open={this.state.open}
                    className={cn('ExpandableDropdown', className)}
                    items={this.placeholder ? [this.placeholder, ...data] : data}
                    placeholder={placeholder}
                    text={this.getDropdownText()}
                    filter={filter}
                    minCheckedItems={minCheckedItems}
                    onSelectAll={onSelectAll}
                    {...rest} />
            </OutsideClickHandler>
        );
    }
}

export default ExpandableDropdown;
