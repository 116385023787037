import * as React from 'react';

import './PageLoader.scss';

export interface Props {
    className?: string;
}

const PageLoader: React.SFC<Props> = (props) => {


    return (
        <div className='lds-center'>
            <div className='lds-ring'><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default PageLoader;
