/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';
import { latLng2Tile } from 'google-map-react/utils';

export const MAX_MERCHANTS_TILE_ZOOM = 9;

export default class MerchantsAPI {
    static async getMerchants(center, zoom: number): Promise<any> {
        if (zoom < MAX_MERCHANTS_TILE_ZOOM) {
            return;
        }

        let tile = latLng2Tile(center, zoom);
        let requests = [];
        for (let i = tile.x - 1; i <= tile.x + 1; i++) {
            for (let j = tile.y - 1; j <= tile.y + 1; j++) {
                requests.push(MerchantsAPI.getMerchantsReq(i, j, zoom));
            }
        }

        let results = await Promise.all(requests);
        return results.reduce((arr, val) => { arr.push(...val); return arr; });
    }

    static async getMerchantsReq(x, y, zoom): Promise<any> {
        let url = `/aggdata/tile?zoomLevel=${zoom}&tileX=${x}&tileY=${y}`;
        return await request(url, 'get');
    }
}
