import React, { Component } from 'react';
import { Validation } from '../../common/src';

import './ValidationErrors.sass';

interface IValidationErrorsProps {
    fieldName: string;
    errors: Validation.Errors.IValidationErrorData[] | null;
}

function capitalize(str: string): string {
    if (!str) {
        return str;
    }
    return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}

export default class ValidationErrors extends Component<IValidationErrorsProps> {
    errorToString(e: Validation.Errors.IValidationErrorData): string {
        if (!this.props.fieldName) {
            return '';
        }

        switch (e.code) {
            case Validation.Errors.ValidationErrorCode.RequireError:
                return `${capitalize(this.props.fieldName)} is required.`;

            case Validation.Errors.ValidationErrorCode.UrlError:
                return `Url is not valid.`;

            case Validation.Errors.ValidationErrorCode.stringOrNull:
                return `${capitalize(this.props.fieldName)} type is not valid.`;

            default:
                return 'Unknown error.';
        }
    }

    render() {
        return (
            <div className='validation-error'>
                {this.props.errors && this.props.errors.map((e, index) =>
                    <span key={index}>{this.errorToString(e)}</span>
                )}
            </div>

        );
    }
}
