import { store } from '../../';
import { request } from '../../api/RequestAPI';

import history from '../../components/React/history';
import ReduxActions from '../actions/index';
import modalStore from './ModalStore';

import utils from '../../utils';

export interface IPageParams {
    page?: number;
    limit?: number;
    filter?: string;
    orderby?: string;
    desc?: string;
    hiddenFilter?: string;
}

export interface IHiddenPageParams {
    sort?: string;
    desc?: boolean;
}

export const alphabeticalRegex = /^[a-zA-Z]+$/i;

export class ListStoreWithPagination<T> {
    reduxActions: ReduxActions;

    isLoading: boolean = true;
    items: T[] = [];
    error: Error;
    itemsFetched: T[] = [];
    itemsCount: number = 0;

    params: IPageParams = {};
    hiddenParams: IHiddenPageParams = {};
    paginationValues: string[] = [];

    constructor(protected path: string, protected listPath: string = '', protected reduxStorePath: string) {
        this.reduxActions = new ReduxActions(this.reduxStorePath);
    }

    preload(): void {
        for (const [key, value] of utils.iterateObject(store.getState()[this.reduxStorePath])) {
            this[key] = value;
        }
        this.reduxActions.update();
    }

    async load(params?: IPageParams): Promise<void> {
        this.isLoading = true;
        this.error = null;
        this.reduxActions.update();

        if (params) {
            this.params = params;
        }

        this.params = Object.assign(
            {
                page: 1
            },
            utils.searchToParams(history.location.search)
        );

        try {
            this.itemsFetched = await request(`${this.path}${this.listPath}`, 'get');
            this.itemsCount = this.itemsFetched.length;

            if (!utils.isEmpty(this.params) && !utils.isEmpty(this.hiddenParams)) {
                this.itemsFetched = utils.sortParams<T>(this.itemsFetched, this.hiddenParams);
                this.initPagination();
                this.invalidatePageParams();
                this.loadFromSearch();
            } else {
                this.items = this.itemsFetched;
            }

            this.reduxActions.setItemsFetched(this.itemsFetched);
            this.reduxActions.setItems<T[]>(this.items);
        } catch (error) {
            this.items = [];
            this.itemsFetched = [];
            this.error = error;
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    async delete(id: number | string) {

        modalStore.delete(`Are you sure you want to delete this item?`, async (buttonIndex) => {
            if (buttonIndex === 1) {
                try {
                    await modalStore.execFullScreen(request(`${this.path}/${id}`, 'delete'));
                    await this.load();
                } catch (error) {
                    modalStore.showError(error);
                }
            }
        });

    }

    invalidatePageParams(): void {

        this.params = Object.assign(
            {
                page: 1
            },
            utils.searchToParams(history.location.search)
        );

        if (this.params && this.hiddenParams) {
            if (this.params.page < 1 || this.params.page > this.paginationValues.length) {
                this.params.page = 1;
                let replacestring = utils.paramsToPath(history.location.pathname, this.params);
                history.replace(replacestring);
            }
        }
    }

    loadFromSearch(): void {
        this.invalidatePageParams();
        const startLetter = this.paginationValues[this.params.page - 1];

        if (this.hiddenParams && this.hiddenParams.sort) {
            if (alphabeticalRegex.test(startLetter)) {
                this.items = this.itemsFetched.filter(item => (item[this.hiddenParams.sort] as string).toLowerCase().startsWith(startLetter.toLowerCase()));
            } else {
                this.items = this.itemsFetched.filter(item => !alphabeticalRegex.test((item[this.hiddenParams.sort][0] as string)));
            }
        }

        if (this.params && this.params.filter) {
            this.items = utils.filterParams(this.items, this.params.filter);
        }

        this.reduxActions.setItems(this.items);
    }

    initPagination(): void {
        this.paginationValues = [];
        for (let startIndex = 0; startIndex < this.itemsCount; startIndex++) {
            let firstValue = (this.itemsFetched[startIndex][this.hiddenParams.sort][0] as string).toUpperCase();

            if (this.paginationValues.findIndex(pv => pv === firstValue) === -1) {
                this.paginationValues.push(firstValue);
            }
        }

        this.paginationValues.sort(a => alphabeticalRegex.test(a) ? 1 : -1);
        let nonAlphabeticalValues: number = 0;

        for (const pv of this.paginationValues) {
            if (alphabeticalRegex.test(pv)) {
                break;
            }
            ++nonAlphabeticalValues;
        }
        if (nonAlphabeticalValues) {
            this.paginationValues.splice(0, nonAlphabeticalValues);
            this.paginationValues.unshift('1-9');
        }
        this.reduxActions.setPagination(this.paginationValues);
    }
}
