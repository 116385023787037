import * as React from 'react';
import cn from 'classnames';
import * as d3 from 'd3';

import BarChartGrid from 'components/Layout/BarChart/BarChartGrid';

import './BarChartZoomBrush.sass';
import BarChartAxis from 'components/Layout/BarChart/BarChartAxis';

const XAxisHeight = 25;
const bottomChartTopMargin = 30;
const bottomChartHeight = 75;
const xAxisBottomHeight = 25;
const chartHeight = 550;

interface Props {
    className?: string;
    data: Array<any>;
    onZoom?: (zoomLevel: number) => void;
    minZoom?: number;
    maxZoom?: number;
}

interface State {
    XScale: d3.ScaleTime<any, any>;
    YScale: d3.ScaleLinear<number, number>;
    xScaleBottom: d3.ScaleTime<any, any>;
    yScaleBottom: d3.ScaleLinear<any, any>;
    width: number;
    height: number;
    barChartRef: React.RefObject<HTMLDivElement>;
    zoom: d3.ZoomBehavior<any, any>;
    onZoomCallback?: any;
    minZoom?: number;
    maxZoom?: number;
    zoomRef: React.RefObject<SVGRectElement>;
    data?: Array<any>;
    dataRef: any;
    dataRefBottom: any;
    axisRef: React.RefObject<SVGGElement>;
    brush: d3.BrushBehavior<any>;
    brushRef: React.RefObject<SVGGElement>;
}

class BarChart extends React.Component<Props, State> {
    state: State = {
        XScale: d3.scaleTime(),
        YScale: d3.scaleLinear(),
        barChartRef: React.createRef(),
        width: 0,
        height: 0,
        zoom: d3.zoom(),
        zoomRef: React.createRef(),
        xScaleBottom: d3.scaleTime(),
        yScaleBottom: d3.scaleLinear(),
        dataRef: [],
        dataRefBottom: [],
        axisRef: React.createRef(),
        brush: d3.brushX(),
        brushRef: React.createRef(),
    };

    constructor(props) {
        super(props);

        this.state.data = props.data;
        this.state.onZoomCallback = props.onZoom;
        this.state.minZoom = props.minZoom;
        this.state.maxZoom = props.maxZoom;
    }

    updateRange = () => {
        if (this.state.barChartRef.current) {
            const {XScale, YScale, data} = this.state;
            let {width} = this.state.barChartRef.current.getBoundingClientRect();

            const height = chartHeight + XAxisHeight + bottomChartHeight + xAxisBottomHeight;
            const maxNumberOfItems = this.itemsPerGroup();

            XScale.range([(width / data.length) / maxNumberOfItems, width - (width / data.length) / maxNumberOfItems]);
            YScale.range([chartHeight, 0]);

            this.setState({XScale, width, height});
        }
    }

    updateDomain = () => {
        const {XScale, YScale} = this.state;
        XScale.domain(d3.extent(this.state.data, (d) => { return (d as any).label as Date; }));
        YScale.domain([0, 100]);
    }

    itemsPerGroup = () => Math.max(...this.state.data.map(d => d.values.length));

    componentDidMount() {
        window.addEventListener('resize', this.updateRange);
        this.updateDomain();
        this.updateRange();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateRange);
    }

    onBrush() {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') {
            return;
        }
        const {XScale, YScale, xScaleBottom, zoomRef, zoom, width, dataRef, axisRef, data} = this.state;
        const maxNumberOfItems = this.itemsPerGroup();
        const barWidth = (width / data.length) / maxNumberOfItems;
        const s = d3.event.selection || xScaleBottom.range();
        XScale.domain(s.map(xScaleBottom.invert, xScaleBottom));
        d3.select(zoomRef.current).call(zoom.transform as any, d3.zoomIdentity
            .scale(width / (s[1] - s[0]))
            .translate(-s[0], 0));

        for (const prop in this.state.data) {
            if (this.state.data[prop]) {
                for (const p in this.state.data[prop].values) {
                    if (dataRef[prop].values[p]) {
                        d3.select(dataRef[prop].values[p].current)
                            .attr('x', XScale(this.state.data[prop].label) - barWidth + parseInt(p, 10) * barWidth)
                            .attr('y', YScale(this.state.data[prop].values[p].value));
                    }
                }
            }
        }

        d3.select(axisRef.current).call(d3.axisBottom(XScale) as any);
    }

    onZoom() {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') {
            return;
        }

        const {XScale, xScaleBottom, YScale, brushRef, brush, dataRef, axisRef, width, data, dataRefBottom} = this.state;
        const maxNumberOfItems = this.itemsPerGroup();

        let classNames = [];
        for (let i = 0; i < this.itemsPerGroup(); i++) {
            const barElement = document.getElementsByClassName('bar')[i];
            classNames.push(barElement.classList.value);
        }

        const t = d3.event.transform;
        const barWidth = (width / data.length) / maxNumberOfItems * t.k;
        if (this.state.onZoomCallback && d3.event.sourceEvent && (d3.event.sourceEvent.type === 'wheel' || d3.event.sourceEvent.sourceEvent)) {
            let bars = this.state.onZoomCallback(t.k);
            this.setState({ data: bars });
        }
        XScale.domain(t.rescaleX(xScaleBottom).domain());
        d3.select(brushRef.current).call(brush.move as any, XScale.range().map(t.invertX, t));

        for (const prop in this.state.data) {
            if (this.state.data[prop]) {
                for (const p in this.state.data[prop].values) {
                    if (dataRef[prop].values[p]) {
                        let className = classNames.find(item => item.indexOf(this.state.data[prop].values[p].label) > 0);

                        d3.select(dataRef[prop].values[p].current)
                            .attr('x', XScale(this.state.data[prop].label) - barWidth + parseInt(p, 10) * barWidth)
                            .attr('y', YScale(this.state.data[prop].values[p].value))
                            .attr('fill', this.state.data[prop].values[p].color)
                            .attr('class', className)
                            .attr('width', barWidth);

                        d3.select(dataRefBottom[prop].values[p].current)
                            .attr('class', className);
                    }
                }
            }
        }

        d3.select(axisRef.current).call(d3.axisBottom(XScale) as any);
    }

    onMouseOverMarkDot = (event) => {
        const bisector = d3.bisector((d) => {return (d as any).label; }).left;

        const {XScale, data, YScale, width} = this.state;

        const barWidth = (width / data.length) / this.itemsPerGroup();

        const tmpClassName = event.currentTarget.classList[1];
        const svg = d3.select(event.currentTarget).node();
        const xPosition = parseFloat(d3.select(event.currentTarget).attr('x'));

        const date = XScale.invert(d3.clientPoint(svg, event)[0]);

        let index = bisector(data, date);

        if (!data[index - 1]) {
            index = 0;
        } else if (!data[index]) {
            index = data.length - 1;
        } else {
            const prev = data[index - 1].label.getTime();
            const curr = data[index].label.getTime();
            const time = date.getTime();

            const over = ((curr - prev) / this.itemsPerGroup());

            index = time > prev && time > (curr - over) && time > over ? index : index - 1;
        }

        const overlayContent = document.getElementById('overlayContent');
        overlayContent.setAttribute('class', `overlayContent ${tmpClassName}`);

        const hoverLine = document.getElementById('hoverLine');
        let bartemp = data[index].values.find(d => d.label === tmpClassName);
        hoverLine.setAttribute('y2', `${chartHeight - YScale(bartemp.value)}`);

        const dotText = document.getElementById('dotText');

        let dotTextHtml = '';
        if (bartemp.count > 0) {
            dotTextHtml = `${bartemp.count} of `;
        }
        dotTextHtml += `${bartemp.total} user${bartemp.total !== 1 ? 's' : ''}`;
        if (bartemp.count > 0) {
            dotTextHtml += `(${bartemp.value}%)`;
        }

        dotText.innerHTML = dotTextHtml;

        const overlay = document.getElementById('overlay');
        overlay.setAttribute('class', 'overlay');
        overlay.setAttribute('transform', `translate(${xPosition + (barWidth / 2)}, ${YScale(bartemp.value)})`);
    }

    render() {
        const {className} = this.props;
        const {XScale, YScale, width, height, data, barChartRef, zoom, zoomRef, xScaleBottom, yScaleBottom, dataRef, axisRef, brushRef, brush, dataRefBottom} = this.state;

        const grid = d3.axisRight(YScale)
            .ticks(5)
            .tickSize(width);

        XScale.domain(d3.extent(data, (d) => { return (d as any).label as Date; }));

        const XAxis = d3.axisBottom(XScale);

        const maxNumberOfItems = this.itemsPerGroup();

        xScaleBottom.range([(width / data.length) / maxNumberOfItems, width - (width / data.length) / maxNumberOfItems]).domain(XScale.domain());
        yScaleBottom.range([bottomChartHeight, 0]).domain(YScale.domain());

        let xAxisBottom = d3.axisBottom(xScaleBottom);

        const barGroups = [];
        const barGroupsBottom = [];

        let groupIndex = 0;

        for (let barGroup of data) {
            if (!barGroup.label) {
                continue;
            }

            const bars = [];
            const barsBottom = [];

            let counter = 0;

            dataRef[groupIndex] = {
                values: []
            };

            dataRefBottom[groupIndex] = {
                values: []
            };

            for (let bar of barGroup.values) {
                if (!bar.label) {
                    continue;
                }

                dataRef[groupIndex].values[counter] = React.createRef();
                dataRefBottom[groupIndex].values[counter] = React.createRef();

                let barHeight = YScale(100 - bar.value) < 0 ? 0 : YScale(100 - bar.value);
                let barHeightBottom = yScaleBottom(100 - bar.value) < 0 ? 0 : yScaleBottom(100 - bar.value);

                const barWidth = (width / data.length) / maxNumberOfItems;
                let [x, y] = [
                    XScale(barGroup.label) - barWidth,
                    YScale.range()[0] - barHeight
                ];

                let [xBottom, yBottom] = [
                    xScaleBottom(barGroup.label) - barWidth,
                    yScaleBottom.range()[0] - barHeightBottom
                ];

                let classNames = `bar ${bar.label}`;

                xBottom += counter * barWidth;
                x += counter * barWidth;

                barsBottom.push(
                    <rect x={xBottom} y={yBottom} width={barWidth} height={barHeightBottom} fill={bar.color} key={counter} className={classNames}
                        ref={dataRefBottom[groupIndex].values[counter]}/>
                );
                bars.push(
                    <rect x={x} y={y} width={barWidth} height={barHeight} fill={bar.color} key={counter} className={classNames}
                        ref={dataRef[groupIndex].values[counter]}  onMouseOver={event => this.onMouseOverMarkDot(event)}/>
                );

                counter++;
            }

            barGroupsBottom.push(<g className='BarChart-itemGroup' key={groupIndex}>{barsBottom}</g>);
            barGroups.push(<g className='BarChart-itemGroup' key={groupIndex}>{bars}</g>);

            groupIndex++;
        }

        brush.extent([[0, 0], [width, bottomChartHeight]])
            .on('brush end', this.onBrush.bind(this));

        d3.select(brushRef.current)
            .call(brush as any)
            .call(brush.move as any, XScale.range());

        zoom.scaleExtent([
            this.state.minZoom ? this.state.minZoom : 1,
            this.state.maxZoom ? this.state.maxZoom : Infinity
        ])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on('zoom', this.onZoom.bind(this));

        d3.select(zoomRef.current)
            .call(zoom as any);

        return (
            <div className={cn('BarChart', className)} ref={barChartRef}>
                <svg viewBox={`-10 -10 ${width + 20} ${height + 20}`} width={width} height={height}>
                    <g className='BarChart-marginBox'>
                        <g className='chart' onMouseLeave={() => {
                            const overlay = document.getElementById('overlay');
                            overlay.setAttribute('class', 'overlay hide');
                        }}>
                            <defs>
                                <clipPath id='clip'>
                                    <rect width={width + 5} height={chartHeight + 5}/>
                                </clipPath>
                            </defs>
                            <BarChartGrid grid={grid}/>
                            <g transform={`translate(0,${chartHeight})`}>
                                <BarChartAxis axis={XAxis} axisRef={axisRef}/>
                            </g>
                            <rect className='zoom' width={width} height={chartHeight} ref={zoomRef} onMouseEnter={() => {
                                const overlay = document.getElementById('overlay');
                                overlay.setAttribute('class', 'overlay hide');
                            }}/>
                            <g className='paths' clipPath='url(#clip)'>
                                {...barGroups}
                            </g>
                            <g id='overlay' className='overlay hide'>
                                <g id='overlayContent' className='overlayContent transit'>
                                    <line id='hoverLine' className='hoverLine' y1={0} y2={0} x1={0} x2={0}/>
                                    <circle r={7.5} className='dot'/>
                                </g>
                                <text id='dotText' className='dotText' transform='translate(-70,-15)'></text>
                            </g>
                        </g>
                        <g className='bottomChart' transform={`translate(0,${chartHeight + xAxisBottomHeight + bottomChartTopMargin})`}>
                            {...barGroupsBottom}
                            <g className='brush' ref={brushRef}/>
                            <g transform={`translate(0,${bottomChartHeight})`}>
                                <BarChartAxis axis={xAxisBottom}/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default BarChart;
