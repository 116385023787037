import * as React from 'react';
import cn from 'classnames';
import {ChildComponentProps as MapComponentProps} from 'google-map-react';

import './MapMarker.scss';

interface Props extends MapComponentProps {
    numPoints: number;
    className?: string;
    hideNumPoints?: boolean;
    scale?: any;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function setStyle(numPoints, scale) {

    let size = 40;
    if (scale) {
        size = Math.round((numPoints / scale.max) * 40) + 20;
        size = Math.max(20, size);
        size = Math.min(60, size);
    }

    return {
        borderRadius: `${Math.round(size / 2)}px`,
        width: `${size}px`,
        lineHeight: `${size}px`
    };
}

const MapMarker: React.SFC<Props> = (props: Props) => {
    const { className, scale, onClick, numPoints, hideNumPoints } = props;
    return (
        <div className={cn('gmce MapMarker', className)} onClick={(e) => onClick ? onClick(e) : void 0}>
            <div className={cn(hideNumPoints ? 'hidden-text' : '')} style={setStyle(numPoints, scale)}>
                {numPoints}
            </div>
        </div>
    );
};

export default MapMarker;
