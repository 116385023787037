import history from '../components/React/history';
import self from './index';
import moment from 'moment';
import momenttz from 'moment-timezone';

import { IHiddenPageParams } from '../redux/stores/ListStoreWithPagination';
import { IPageParams } from '../redux/stores/ListStoreWithPagination';
import { IReportCSV } from '../typings/src/reports/IReport';

export const DateTimeFormat = 'MM/DD/YYYY  HH:mm';

export default {

    paramsToPath(path: string, params: IPageParams): string {
        let ret = path;
        let hasQuery = ret.indexOf('?') !== -1;

        for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
                const val = params[prop];
                if (val) {
                    if (!hasQuery) {
                        ret += '?';
                        hasQuery = true;
                    } else {
                        ret += '&';
                    }
                    ret += `${encodeURIComponent(prop)}=${encodeURIComponent(val)}`;
                }
            }
        }
        return ret;
    },

    paramsToQuery(params: IPageParams): string {
        let query: string = '';
        if (params.filter && params.filter.length) {
            query += `?${params.filter}`;
        }
        return query;
    },

    searchToParams(search: string, isUrl: boolean = true): {} {
        let params = {} as IPageParams;
        let hasErrorOccured: boolean = false;

        if (search) {
            const splited = search.substring(1).split('&');
            splited.forEach(s => {
                const keyVal = s.split('=');
                try {
                    const key = decodeURIComponent(keyVal[0]);
                    let val: string | number = decodeURIComponent(keyVal[1]);
                    if (!isNaN(val as unknown as number)) val = +val;
                    params[key] = val;
                } catch (e) {
                    const key = keyVal[0];
                    delete params[key.trim()];
                    hasErrorOccured = true;
                }
            });
        }

        if (isUrl) {
            const test = {
                page: 1,
                filter: 'test',
                limit: 1,
                orderby: 'test',
                desc: 'test',
                hiddenFilter: 'test'
            } as IPageParams;

            // Check if url parameters are valid
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    if ((!test.hasOwnProperty(key)) || (typeof params[key] !== typeof test[key])) {
                        if (params[key]) {
                            delete params[key];
                        }
                        if (key === 'page') {
                            params.page = 1;
                        }

                        hasErrorOccured = true;
                    }
                }
            }
        }

        if (hasErrorOccured) {
            let replacestring = self.paramsToPath(history.location.pathname, params);
            history.replace(replacestring);
        }

        return params;
    },

    filterParams<T>(arr: T[], filter: string): T[] {

        if (!filter || !arr.length) {
            return arr;
        }

        const filterObj = self.searchToParams(`?${filter}`, false);
        Object.keys(filterObj).map(key => {
            const attr = key;
            let value = filterObj[key];
            if (!attr || !value) {
                return [];
            }

            if (!arr.length) {
                return arr;
            }
            value = value.toLowerCase();
            if (typeof arr[0][attr] === 'string') {
                arr = arr.filter(a => (a[attr] as string).toLowerCase().includes(value));
                arr = arr.sort((a, b) => (a[attr] as string).toLowerCase().startsWith(value) && !(b[attr] as string).toLowerCase().startsWith(value) ? -1 : 0);
            } else if (typeof arr[0][attr] === 'number') {
                arr = arr.filter(a => (a[attr] as number) === parseInt(value, 10));
            }
        });

        return arr;
    },

    sortParams<T>(arr: T[], params: IHiddenPageParams): T[] {

        const attr = params.sort;
        const desc = params.desc;

        if (typeof arr[0][attr] === 'string') {
            if (desc === true) {
                return arr.slice().sort((a, b) => (b[attr] as string).localeCompare(a[attr] as string));
            } else {
                return arr.slice().sort((a, b) => (a[attr] as string).localeCompare(b[attr] as string));
            }
        } else if (typeof arr[0][attr] === 'number') {
            if (desc === true) {
                return arr.slice().sort((a, b) => b[attr] - a[attr]);
            } else {
                return arr.slice().sort((a, b) => a[attr] - b[attr]);
            }
        }

        return arr;
    },

    numberWithCommas(num: number, toFixed?: number): string {
        if (typeof num !== 'number') {
            return '';
        }
        let regex = /\B(?=(\d{3})+(?!\d))/g;
        if (typeof toFixed === 'number') {
            return num.toFixed(toFixed).replace(regex, ',');
        }
        return num.toString().replace(regex, ',');
    },

    isEmpty(obj: {}): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    },

    iterateObject(obj): any {
        if (!obj) {
            return [];
        }

        let iter = Reflect.ownKeys(obj)[Symbol.iterator]();
        return {
            [Symbol.iterator]() {
                return this;
            },
            next() {
                let { done, value: key } = iter.next();
                if (done) {
                    return { done: true };
                }
                return { value: [key, obj[key]] };
            }
        };
    },

    getNestedObjectByString(item: {}, path: string) {
        let pathArr = path.split('.');
        return pathArr.reduce(
            (obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined,
            item);
    },

    copyToClipboard(str: string): void {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    },

    formatDate(date: string | Date): string {

        if (!date) {
            return '';
        }
        return moment(date).format(DateTimeFormat);
    },

    capitalizeWords: function (text: string): string {
        if (text && text.length) {
            return text.charAt(0).toUpperCase() + text.slice(1).replace(/([A-Z])/g, ' $1').trim();
        }
        return '';
    },

    filteredArray<T>(arr: T[], attr: string): T[] {
        let tempSet = new Set();

        return arr.filter(el => {
            let duplicate = tempSet.has(el[attr]);
            tempSet.add(el[attr]);
            return !duplicate;
        });
    },

    ArrayUniqueValues<T, I>(arr: T[], arr2: I[], attr: string): T[] {
        let tempSet = new Set();
        let secondSet = new Set(arr2.map(val => val[attr]));

        return arr.filter(el => {
            let duplicate = secondSet.has(el[attr]);
            tempSet.add(el[attr]);
            return !duplicate;
        });
    },

    downloadFileAsBlob(blob: Blob, filename: string): void {
        const csvUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = csvUrl;
        downloadLink.setAttribute('download', filename);
        downloadLink.click();
    },

    downloadFileFromUrl(url: string) {
        window.location.assign(url);
    },

    async downloadAppendableBlob(report: IReportCSV): Promise<void> {
        await new Promise((resolve, reject) => {
            let { url, fileName } = report;
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.responseType = 'blob';

            xhr.onload = function() {
                let a = document.createElement('a');
                document.body.appendChild(a);
                url = window.URL.createObjectURL(this.response);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
                resolve(null);
            };

            xhr.onerror = function() {
                reject(new Error('Failed to download.'));
            };

            xhr.send();
        });
    },

    daysToMs(days: number): number {
        return days * 24 * 60 * 60 * 1000;
    },

    generateDateRange(lastNDays = 90) {
        const dateRanges = [];
        for (let i = 0; i < lastNDays; i++ ) {
            dateRanges.push({
                startDate: new Date((new Date().getTime() - this.daysToMs(i + 1))).toISOString(),
                endDate: new Date((new Date().getTime() - this.daysToMs(i))).toISOString()
            });
        }
        return dateRanges;
    },

    valueIsEmpty (value: any): boolean {
        return value === null || value === undefined;
    },

    convertLocalDateToUTC (dateTimeValueISO: string, timezone: string): string {
        const format = 'YYYY-MM-DDTHH:mm:ss';
        const formattedLocalDateTime = dateTimeValueISO.slice(0, dateTimeValueISO.length - 4);
        const localDateTime = momenttz.tz(formattedLocalDateTime, format, timezone);
        return localDateTime.toISOString();
    },

    async downloadCSV (fileName, data) {
        const element = document.createElement('a');
        const file = new Blob([data], {type: 'text/csv'});
        element.href = URL.createObjectURL(file);
        element.download = `${fileName}.csv`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
};
