import { request } from '../../../api/RequestAPI';
import { ListStore } from '../ListStore';
import { IReport, IReportCSV } from '../../../typings/src/reports/IReport';
import { IUser } from '../../../typings/src/users/ad/IUser';

import ModalStore from '../ModalStore';
import utils from '../../../utils';

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};

export default class ReportsStore extends ListStore<IReport> {
    users: IUser[] = [];

    constructor() {
        super('/reporting', '/report-generators', 'reports');
        this.preload();
    }

    async afterFinally(): Promise<void> {
        const unloadedUserIds = this.items.filter(report => {
            const user = this.users.find(u => u.id === report.starterId);
            const isUserLoaded = (user && user.userLoaded) || false;
            return report.starterId !== null && report.starterId !== 'CRON_TASK' && !isUserLoaded;
        }).reduce((unique, value) =>  unique.indexOf(value.starterId) === -1 ? [...unique, value.starterId] : unique, []);
        await this.getReportsUsers(unloadedUserIds);
    }

    async generateReport(reportName: string): Promise<void> {

        try {
            await request(`${this.path}/generate-report/${reportName}`, 'post', options);
            await this.loadSilently();
        } catch (error) {
            ModalStore.showError(error);
        } finally {
            this.reduxActions.update();
        }
    }

    getReportsUsers = (() => {
        return async (userIds: string[]) => {
            for (const id of userIds) {
                try {
                    const user = await request(`/users/ad/users/${id}`, 'get');
                    this.users.push({ ...user, userLoaded: true });
                } catch (error) {
                    this.users.push({ id, displayName: '', givenName: '', surname: '', userPrincipalName: '', roles: [], userLoaded: true });
                }
            }
            this.reduxActions.setCustom('users', this.users);
        };
    })();

    async downloadCsvFile(reportName: string): Promise<void> {

        try {
            const exportFileInfo: IReportCSV = await request(`/reporting/report/${reportName}`, 'get');
            await utils.downloadAppendableBlob(exportFileInfo);
        } catch (error) {
            ModalStore.showError(error);
        }
    }
}
