import { DropdownItem } from '../../components/Layout/Dropdown/Dropdown';
import ReduxActions from '../actions/index';

export default class TableFilterStore {
    tableColumns: string[] = [];
    displayedTableColumns: string[] = [];
    dropDown: DropdownItem[] = [];

    constructor(public reduxActions: ReduxActions) {}

    setTableColumns(tableColumns: string[]): void {
        this.tableColumns = tableColumns;
    }

    setDisplayedTableColumns(displayedTableColumns: string[]): void {
        this.displayedTableColumns = displayedTableColumns;
    }

    initDropdown(values: string[], prefferedValues?: string[]): void {

        if (!values || !values.length || this.dropDown.length === values.length) {
            return;
        }

        this.dropDown = [];

        for (const value of values) {
            this.dropDown.push({
                checked: !prefferedValues ? true : prefferedValues.some(pv => pv === value),
                id: value.replace(/\s/g, ''),
                value: value,
            });
        }

        this.reduxActions.setCustom('dropDown', this.dropDown);
    }

    handleFilterChange(id: string, value: string) {
        const index = this.dropDown.findIndex(dd => dd.id === id);
        this.dropDown[index].checked = !this.dropDown[index].checked;
        this.reduxActions.update();
    }

    handleDropdownSelectionChange(value: boolean) {
        for (let i = 0; i < this.dropDown.length; i++) {
            this.dropDown[i].checked = value;
        }
        this.reduxActions.update();
    }

    shouldDisplay(dropdownItem: DropdownItem | string): boolean {
        if (!this.dropDown.length) {
            return false;
        }

        if (typeof dropdownItem === 'string') {
            return this.dropDown.find(col => col.id === dropdownItem).checked;
        }

        if (typeof dropdownItem === 'object') {
            return this.dropDown.find(col => col.value === dropdownItem.value).checked;
        }

        return false;
    }
}
