import { ListStore } from '../ListStore';

import DataDictionaryAPI from '../../../api/DataDictionaryAPI';
import { IDataDictionary } from '../../../typings/src/dataDictionary/IDataDictionary';

import ModalStore from '../ModalStore';

export default class ServiceDictionaryStore extends ListStore<IDataDictionary> {
    constructor() {
        super('', '', 'dataDictionary');
        this.preload();
    }

    async loadDataDictionary () {
        if (this.items && this.items.length > 0) {
            this.isLoading = false;
            this.reduxActions.update();
            return;
        }

        this.isLoading = true;
        this.reduxActions.update();

        try {
            this.items = await DataDictionaryAPI.getDataDictionary('users');
            this.reduxActions.setItems(this.items);
        } catch (error) {
            ModalStore.showError(error);
        } finally {
            await this.afterFinally();
            this.isLoading = false;
            this.reduxActions.update();
        }
    }
}
