import * as React from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import './Logo.scss';


interface IProps {
    className?: string;

    onClick?(e);
}

const Logo: React.SFC<IProps> = (props) => {
    const {className, onClick} = props;

    return (
        <a href='#' className={cn('Logo', className)} onClick={onClick}>
            <SVG className='svgWrapper' src='/assets/logo.svg'/>
        </a>
    );
};

export default Logo;
