import Validators from './validators/index';
import { IValidationErrors } from './errors/ValidationError';
import { IMetaData } from '../../../typings/src/reports/IReport';

export function GoogleMapsUrlValidation(url: string): IValidationErrors {

    let errors: IValidationErrors = {};

    Validators.googleMapsUrlLocation(url, 'url', errors);

    return errors;
}

