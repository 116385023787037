import * as React from 'react';
import cn from 'classnames';

import './Paper.sass';


export interface Props {
    className?: string;
    children: React.ReactNode;
    wrapper?: React.ComponentType<{ className?: string; }> | string;
    type?: 'wide' | 'nested';
}

const Paper: React.SFC<Props> = (props) => {
    const {className, type} = props;
    const Wrapper = props.wrapper || 'div';

    return (
        <Wrapper className={cn('Paper', className, type)}>
            {props.children}
        </Wrapper>
    );
};

export default Paper;
