import * as React from 'react';
import * as H from 'history';

import { ODataStore } from '../../redux/stores/ODataStore';
import { ODataEntityType } from '../../typings/src/reports/IReport';

import Utils, { DateTimeFormat } from '../../utils';

const KeyboardEventCode = {
    Exclamation: 33,
    Equal: 61,
    Greater: 62,
    Less: 60,
    Enter: 13,
    Slash: 47,
    Colon: 58,
    Space: 32,
    Minus: 45,
    DecimalPoint: 46,
};

interface IProps {
    history: H.History;
    store: ODataStore<{}>;
    sort: string;
    filterQuery: string;
    inputType: ODataEntityType;
}

interface State {
    value: string;
    enableLoading: boolean;
}

class ODataFilter extends React.Component<IProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.filterQuery,
            enableLoading: false
        };
    }

    componentWillReceiveProps(newProps: IProps) {
        if (newProps.filterQuery !== this.state.value) {
            this.setState({ value: newProps.filterQuery });
        }
    }

    handleChange () {
        if (this.state.enableLoading) {
            const { value } = this.state;
            const { store, sort } = this.props;
            const filter = store.params.filter ? JSON.parse(store.params.filter) : {};
            if (value) {
                filter[sort] = value;
                store.params.filter = JSON.stringify(filter);
            } else {
                delete filter[sort];
                if (Utils.isEmpty(filter)) {
                    delete store.params.filter;
                } else {
                    store.params.filter = JSON.stringify(filter);
                }
            }
            store.params.page = 1;
            const path = Utils.paramsToPath(location.pathname, store.params);
            this.props.history.replace(path);
        }
    }

    render () {
        const numericalInputTypes = [ODataEntityType.ODInt32, ODataEntityType.ODInt64, ODataEntityType.ODDateTime, ODataEntityType.ODDecimal, ODataEntityType.ODDouble];
        return <input
                value={this.state.value}
                className={`sort-filter-input ${this.props.inputType === ODataEntityType.ODDateTime ? 'number' : ''}`}
                placeholder={this.props.inputType === ODataEntityType.ODDateTime ? DateTimeFormat.toLowerCase() : ''}
                onChange={event => this.setState({ value: event.target.value, enableLoading: true })}
                onBlur={() => this.handleChange()}
                onKeyUp={event => {
                    if (event.which === KeyboardEventCode.Enter) {
                        this.handleChange();
                    }
                }}
                onKeyPress={event => {
                    if (numericalInputTypes.includes(this.props.inputType)) {
                        if (!Object.values(KeyboardEventCode).includes(event.which) &&
                                isNaN(parseInt(String.fromCharCode(event.which), 10))) {
                                    // Prevent input from entering characters.
                                    event.preventDefault();
                    }}
                }}
                disabled={![...numericalInputTypes, ODataEntityType.ODString, ODataEntityType.ODGuid].includes(this.props.inputType)}
            />;
    }
}

export default ODataFilter;
