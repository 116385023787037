import * as React from 'react';
import cn from 'classnames';

import './Table.sass';


interface Props {
    className?: string;
    headers: (string | JSX.Element)[];
    data: (string | object)[][];
}

const Table: React.SFC<Props> = (props) => {
    const {className, headers, data} = props;

    const headersEl = headers.map((header, index) => <th key={index}>{header}</th>);

    let i = 0;
    const dataEl = data.map((row) =>
        <tr key={++i}>
            {row.map((col, index) =>
                <td key={`${i}-${index}`}>{col}</td>)}
        </tr>);

    return (
        <table className={cn('Table', className)}>
            <tbody>
            <tr className='headerRow'>{headersEl}</tr>

            {dataEl}
            </tbody>
        </table>
    );
};

export default Table;
