import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { TripsStatisticsStore } from '../../redux/stores/Trips/TripsStatisticsStore';
import { IPageParams } from '../../redux/stores/ListStoreWithPagination';

import Content from '../Content/Content';
import Paper from '../Layout/Paper';
import TripsStatisticsList from './TripsStatisticsList';
import TripsStatisticsHeatmap from './TripsStatisticsHeatmap';
import Header from '../Content/Header/Header';
import Space from '../Layout/Space';
import FilterWrapper from '../Layout/Filter/FilterWrapper';
import UpperText from '../Layout/UpperText/UpperText';
import Filter from '../List/Filter';
import Button from '../Layout/Button/Button';
import TimezonePicker from 'components/Layout/Date/TimezonePicker';

import utils from '../../utils';

class TripsStatisticsTab extends Component<RouteComponentProps<{}>> {
    store = new TripsStatisticsStore();

    componentDidMount(): void {
        let paramsQuery = utils.paramsToPath('', this.store.params);
        let shouldLoad = paramsQuery !== this.props.location.search;

        if (shouldLoad) {
            this.store.params = utils.searchToParams(this.props.location.search, false) as IPageParams;
            this.setTimezone();
            this.store.continuationToken = undefined;
            this.store.items = [];
            this.load(false);
            return;
        }
        this.load(true);
    }

    componentWillReceiveProps(nextProps: RouteComponentProps<{}>): void {
        if (this.props.location.search !== nextProps.location.search) {
            this.store.params = utils.searchToParams(nextProps.location.search, false) as IPageParams;
            this.setTimezone();
            this.store.continuationToken = undefined;
            this.store.items = [];
            this.load();
        }
    }

    async load(isInitialLoad: boolean = false): Promise<void> {
        await this.store.loadTrips(isInitialLoad);
    }

    getParamsQuery(): string {
        const { params } = this.store;
        return utils.paramsToPath('', params);
    }

    onTimezoneChanged (timezone) {
        this.store.changeTimezone(timezone);

        const filterParam = this.store.params ? this.store.params.filter : undefined;
        let filter;

        if (filterParam) {
            filter = utils.searchToParams(`?${filterParam}`, false);
        } else {
            filter = {};
        }

        if (timezone) {
            filter['timezone'] = timezone;
        } else {
            delete filter['timezone'];
        }

        if (utils.isEmpty(filter)) {
            delete this.store.params.filter;
        } else {
            this.store.params.filter = utils.paramsToPath('', filter).substring(1);
        }

        const path = utils.paramsToPath(location.pathname, this.store.params);
        this.props.history.replace(path);
    }

    setTimezone () {
        if (this.store.params.filter) {
            const filterObj = utils.searchToParams(`?${this.store.params.filter}`, false);
            if (filterObj['timezone']) {
                this.store.changeTimezone(filterObj['timezone']);
            }
        }
    }

    render() {

        const url = this.props.match.url;
        const header = <Header><h1>Trips Statistics</h1><Space /></Header>;

        const { isLoading, csvLoading } = this.store;

        return (
            <Content header={header} className={'AdvertisersTab'}>
                <FilterWrapper>

                    <UpperText className='Header-upperText' text='From Date'>
                        <Filter field='from' store={this.store} type='date picker' />
                    </UpperText>

                    <UpperText className='Header-upperText' text='To Date'>
                        <Filter field='to' store={this.store} type='date picker' />
                    </UpperText>

                    <UpperText className='Header-upperText' text='Timezone'>
                        <TimezonePicker timezone={this.store.timezone} onTimezoneSelected={(id) => this.onTimezoneChanged(id)} />
                    </UpperText>

                    <UpperText className='Header-upperText' text='Download csv file'>
                        <Button type='primary' className={csvLoading ? 'disabled' : ''} onClick={e => this.store.downloadCsvFile()}>{csvLoading ? 'Downloading...' : 'Download'}</Button>
                    </UpperText>

                </FilterWrapper>

                <div className='tab-control tab-radius tab-form'>
                    <div className='tab-center'>
                        <NavLink to={`${url}${this.getParamsQuery()}`} isActive={(_, { pathname }) => pathname === url} replace={true}>List</NavLink>
                        <NavLink to={`${url}/heatmap/@${this.getParamsQuery()}`} isActive={(_, { pathname }) => pathname.includes('heatmap')} replace={true}>Heatmap</NavLink>
                    </div>
                </div>

                <Paper className='Content-paper' type='wide'>
                    <Switch>
                        <Route path={`${url}`} exact={true} render={() => <TripsStatisticsList store={this.store} />} />
                        <Route path={`${url}/heatmap`} render={() => <TripsStatisticsHeatmap heatmap={this.store.heatmapData} />} />
                        <Redirect from={url} to={url.substring(0, url.lastIndexOf('/'))} />
                    </Switch>
                </Paper>

            </Content>
        );
    }
}

const mapStateToProps = state => ({
    tripsStatistics: state.tripsStatistics
});

export default connect(mapStateToProps)(TripsStatisticsTab);
