import * as React from 'react';
import { Props as GoogleMapProps, ChangeEventValue, ClickEventValue } from 'google-map-react';

import { IHeatmap } from '~/typings/src/trips/ITrips';

import './GoogleMap.scss';
import { RouteComponentProps, Route, withRouter, Switch } from 'react-router-dom';
import GoogleMaps from './GoogleMaps';

interface Props {
    className?: string;
    props?: GoogleMapProps;
    heatmap?: IHeatmap[];
    mounted?: (ref: React.RefObject<any>) => void;
    onChange: (event: ChangeEventValue) => void;
    onClick?: (event: ClickEventValue) => void;
}

class GoogleMapWithUrl extends React.Component<Props & RouteComponentProps<{}>> {
    clickedEvent: ClickEventValue = null;
    googleMap: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.googleMap = React.createRef();
        this.clickListener = this.clickListener.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('click', this.clickListener);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.clickListener);
    }

    isChildClicked(e: any, className = 'gmce'): boolean {
        if (!e || !e.parentElement) {
            return false;
        }
        const exists = e.classList.value ? e.classList.value.toLowerCase().includes(className) : false;
        return exists ? true : this.isChildClicked(e.parentElement);
    }

    clickListener(e: MouseEvent): void {
        if (!this.clickedEvent) {
            return;
        }
        if (this.isChildClicked(e['target'])) {
            return;
        }
        this.props.onClick(this.clickedEvent);
        this.clickedEvent = null;
    }

    componentWillReceiveProps(nextProps: RouteComponentProps<{}> & Props) {
        // This case enables updating heatmap
        if (nextProps.heatmap && this.googleMap && this.googleMap.current && this.googleMap.current.heatmap) {
            if (!nextProps.heatmap.length) {
                this.googleMap.current.heatmap.setData([]);
                return;
            }

            let heatmapData = nextProps.heatmap.map(loc => { return {
                location: new google.maps.LatLng(loc.lat, loc.lng), weight: loc && loc.weight ? loc.weight : 1 };
            });

            this.googleMap.current.heatmap.setData(heatmapData);
        }

        if (nextProps.location.pathname === nextProps.match.path) {
            nextProps.history.replace(`${nextProps.match.path}/@`);
        }
    }

    setRef(ref: React.RefObject<any>) {
        this.googleMap = ref;
        if (this.props.mounted) {
            this.props.mounted(ref);
        }
    }

    render() {
        const { props } = this.props;
        const url = this.props.match.url;

        return (
            <Switch>
                <Route
                    path={`${url}/:location`}
                    exact={true}
                    render={() =>
                        <GoogleMaps
                            props={{...props}}
                            onChange={(event: ChangeEventValue) => this.props.onChange ? this.props.onChange(event) : void 0}
                            mounted={(ref: React.RefObject<any>) => this.setRef(ref)}
                            onClick={(event: ClickEventValue) => this.clickedEvent = event}
                        >
                            {this.props.children}
                        </GoogleMaps>
                    }
                />
            </Switch>
        );
    }
}

export default withRouter<any>(GoogleMapWithUrl);
