import * as React from 'react';
import * as H from 'history';
import { ODataStore } from '../../redux/stores/ODataStore';

import Utils from '../../utils';

import './Sort.scss';

interface Props {
    history: H.History;
    store: ODataStore<{}>;
    sort: string;
}

export default class Sort extends React.Component<Props> {
    onClick(): void {
        let desc: string;
        let isSortDeleted: boolean = false;
        const { store, history, sort } = this.props;

        if (!store.params) store.params = {};

        // asc -> desc -> none
        if (store.params.orderby !== sort) {
            desc = 'false';
        } else if (store.params.desc === 'false') {
            desc = 'true';
        } else {
            delete store.params['desc'];
            delete store.params['orderby'];
            isSortDeleted = true;
        }

        if (!isSortDeleted) {
            store.params['desc'] = desc;
            store.params['orderby'] = sort;
        }

        store.params.page = 1;
        const path = Utils.paramsToPath(location.pathname, store.params);
        history.replace(path);
    }

    getSortStatusClass(): string {
        const { store, sort } = this.props;
        if (store.params.orderby === sort) {
            if (store.params.desc === 'true') return 'sort-desc';
            if (store.params.desc === 'false') return 'sort-asc';
        }
        return 'sort-none';
    }

    render() {
        const { children } = this.props;

        return (<div className='sort-wrapper' onClick={() => this.onClick()}>
                {children}
                <span className={`sort-icons ${this.getSortStatusClass()}`}>
                    <div className='arrow-up' />
                    <div className='arrow-down' />
                </span>
            </div>
        );
    }
}
