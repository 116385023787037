import { store } from '../../';
import { request } from '../../api/RequestAPI';
import { Validation } from '../../common/src';

import ReduxActions from '../actions/index';
import modalStore from './ModalStore';

import utils from '../../utils';

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};

export abstract class DetailsStore<T> {
    reduxActions: ReduxActions;

    isLoading: boolean = true;
    item?: T;
    error: Error;
    validationErrors: Validation.Errors.IValidationErrors = {};

    constructor(protected path: string, protected id: number | string, protected reduxStorePath: string) {
        this.reduxActions = new ReduxActions(this.reduxStorePath);
    }

    preload(): void {
        for (const [key, value] of utils.iterateObject(store.getState()[this.reduxStorePath])) {
            this[key] = value;
        }
        this.reduxActions.update();
    }

    startAction(): void {
        this.isLoading = true;
        this.error = null;
        this.reduxActions.update();
    }

    endAction(): void {
        this.isLoading = false;
        this.reduxActions.update();
    }

    async load(): Promise<void> {
        this.startAction();

        try {
            this.item = await request(`${this.path}/${this.id}`, 'get');
        } catch (error) {
            this.error = error;
            await this.afterError(this.item);
        } finally {
            this.endAction();
        }
    }

    async handleSubmit(e: React.FormEvent<HTMLFormElement>, method?: string): Promise<void> {
        e.preventDefault();

        if (!this.validate()) {
            this.endAction();
            return;
        }

        this.error = null;
        this.reduxActions.update();

        if (!method) {
            method = this.item['id'] ? 'put' : 'post';
        }

        try {
            await modalStore.execFullScreen(request(`${this.path}/${this.id}`, method, options, JSON.stringify(this.item)));
        } catch (error) {
            modalStore.showError(error);
        } finally {
            this.endAction();
        }
    }

    validate(): boolean {
        this.validationErrors = this.execValidate();
        return Object.keys(this.validationErrors).length === 0;
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void {
        if (!this.item) {
            return;
        }

        if (e.target.type === 'checkbox') {
            this.item[e.target.name] = (e.target as HTMLInputElement).checked;
        } else {
            this.item[e.target.name] = e.target.value;
        }
        this.reduxActions.update();
        this.validate();
    }

    handleDropdownChange(value: string, field: string): void {
        this.item[field] = value;
        this.reduxActions.update();
        this.validate();
    }

    protected afterError(item: T): Promise<void> {
        return Promise.resolve();
    }

    protected abstract execValidate(): Validation.Errors.IValidationErrors;
}
