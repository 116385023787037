/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React, { Component } from 'react';
import cn from 'classnames';
import Content from 'components/Content/Content';
import Table from 'components/Layout/Table/Table';
import PageLoader from 'components/Layout/PageLoader/PageLoader';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import Space from 'components/Layout/Space';
import RewardsApi, { RewardStatstic, PartnerStatstic } from '../../api/RewardsApi';
import UpperText from 'components/Layout/UpperText/UpperText';
import ExpandableDropdown from 'components/Layout/Dropdown/ExpandableDropdown';
import { DropdownItem } from 'components/Layout/Dropdown/Dropdown';

import './RewardsStatistics.scss';

const tableHeaders2 = [
    'Brand', 'Redeemed'
];

export interface Props {
    className?: string;
}

export interface State {
    statisticsData?: any;
    statisticsTable: {
        rows: string[][];
        headers: string[];
    };
    partners: string[][];
    account: string;
    accounts: DropdownItem[];
    date: string;
    dates: DropdownItem[];
    isUpdating: boolean;
    totalRedeemed: number;
}

class RewardStatstics extends Component<Props, State> {
    constructor(props) {
        super(props);

        let dates: DropdownItem[] = [{id: '0', value: 'Overall'}];
        let date = new Date();
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        date.setDate(date.getDate() - date.getDay() + 1);
        for (let i = 1; i <= 10; i++) {
            let d = `${date.getDate()}.- ${date.getDate() + 6}.${date.getMonth() + 1}.${date.getFullYear()}`;
            dates.push({id: i.toString(), value: d, data: new Date(date)});
            date.setDate(date.getDate() - 7);
        }

        this.state = {
            statisticsTable: {
                rows: [],
                headers: []
            },
            partners: [],
            isUpdating: false,
            account: 'all',
            accounts: [{id: 'all', value: 'all'}],
            date: dates[0].value,
            dates: dates,
            totalRedeemed: 0
        };
    }

    async updateAccounts() {
        try {
            let accountsList: string[] = await RewardsApi.getAccounts();
            let accounts: DropdownItem[] = [
                {id: 'all', value: 'all'},
                ...accountsList.map(item => {
                    return {
                        id: item,
                        value: item
                    };
                })
            ];

            await this.setState({ accounts });
        } catch (e) {
            console.error(e);
        }
    }

    prepareStatisticsTableRows(data: RewardStatstic[]): { rows: string[][], redeemed: number } {
        let totalRedeemed: number = 0;
        let values = {};
        let total = [];
        for (let i in data) {
            if (data[i]) {
                let day = data[i];
                total[i] = {
                    created: day.totalCreated,
                    redeemed: day.totalRedeemed
                };
                totalRedeemed += day.totalRedeemed;
                for (let val of day.perValue) {
                    if (!values[val.value]) {
                        values[val.value] = {};
                    }
                    values[val.value][i] = val;
                }
            }
        }

        let firstRow = ['All'];
        for (let item of total) {
            firstRow.push(item.created, item.redeemed);
        }
        let rows: string[][] = [['Value', 'Created', 'Redeemed', 'Created', 'Redeemed', 'Created', 'Redeemed', 'Created', 'Redeemed', 'Created', 'Redeemed', 'Created', 'Redeemed', 'Created', 'Redeemed'], firstRow];

        for (let i in values) {
            if (values[i]) {
                let list = values[i];
                let val: number = Math.round(Number(i) / 100);
                let row = [`$ ${val < 1 ? (Number(i) / 100).toFixed(2) : val}`];

                for (let j = 0; j < 7; j++) {
                    let created = list[j] && list[j].created ? list[j].created : 0;
                    let createdP = total[j].created ? Math.round((100 / total[j].created) * created) : 0;
                    row.push(`${created} (${createdP}%)`);

                    let redeemed = list[j] && list[j].redeemed ? list[j].redeemed : 0;
                    let redeemedP = total[j].redeemed ? Math.round((100 / total[j].redeemed) * redeemed) : 0;
                    row.push(`${redeemed} (${redeemedP}%)`);
                }
                rows.push(row);
            }
        }

        return { rows, redeemed: totalRedeemed };
    }

    async loadStatistics(date?: Date) {
        try {
            let { statisticsTable, totalRedeemed } = this.state;

            if (date) {
                date = new Date(date);
                let promises = [];
                statisticsTable.headers = [''];
                for (let i = 1; i <= 7; i++) {
                    statisticsTable.headers.push(`${date.getDate()}.${date.getMonth()}.`, '');
                    let from = date.toISOString();
                    date.setDate(date.getDate() + 1);
                    let to = date.toISOString();
                    promises.push(RewardsApi.getStatistics(this.state.account, from , to));
                }

                let rewardsData: RewardStatstic[];
                rewardsData = await Promise.all(promises);

                let { rows, redeemed } = this.prepareStatisticsTableRows(rewardsData);
                statisticsTable.rows = rows;
                totalRedeemed = redeemed;
            } else {
                statisticsTable.headers = ['Value', 'Created', 'Redeemed'];
                let result: RewardStatstic = await RewardsApi.getStatistics(this.state.account);
                statisticsTable.rows = [[
                    'All',
                    result.totalCreated.toString(),
                    result.totalRedeemed.toString()
                ]];
                totalRedeemed = result.totalRedeemed;
                result.perValue.map(item => {
                    let value: number = Math.round(item.value / 100);
                    let created = Math.round((100 / result.totalCreated) * item.created);
                    let redeemed = Math.round((100 / result.totalRedeemed) * item.redeemed);
                    statisticsTable.rows.push([
                        `$ ${value < 1 ? (item.value / 100).toFixed(2) : value}`,
                        `${item.created} (${created}%)`,
                        `${item.redeemed} (${redeemed}%)`
                    ]);
                });
            }

            this.setState({ statisticsTable, totalRedeemed });
        } catch (e) {
            console.error(e);
        }
    }

    async loadPartnerStatistics(date?: Date) {
        try {
            let from: string, to: string;
            if (date) {
                date = new Date(date);
                from = date.toISOString();
                date.setDate(date.getDate() + 7);
                to = date.toISOString();
            }

            let partners: string[][] = [];
            let results: PartnerStatstic[] = await RewardsApi.getPartnersStatistics(this.state.account, from, to);
            results.sort((a, b) => b.count - a.count);
            results.map(item => {
                let count = Math.round((100 / this.state.totalRedeemed) * item.count);
                partners.push([
                    item.brand,
                    `${item.count} (${count}%)`
                ]);
            });
            this.setState({ partners });
        } catch (e) {
            console.error(e);
        }
    }

    async getData() {
        this.setState({ isUpdating: true });
        if (this.state.accounts.length === 1) {
            await this.updateAccounts();
        }

        let date;
        let selected = this.state.dates.find(item => item.value === this.state.date);
        if (selected && selected.data) {
            date = new Date(selected.data);
        }

        // Get overall statistics
        await this.loadStatistics(date);

        // Get partners statistics
        await this.loadPartnerStatistics(date);

        this.setState({ isUpdating: false });
    }

    componentDidMount() {
        this.getData();
    }

    async changeAccount(account) {
        await this.setState({ account });
        this.getData();
    }

    async changeDate(id) {
        let date = this.state.dates.find(item => item.id === id);
        await this.setState({ date: date.value });
        this.getData();
    }

    render() {
        const { partners, isUpdating, account, accounts, date, dates, statisticsTable } = this.state;

        if (isUpdating === true) {
            return (
                <PageLoader></PageLoader>
            );
        }

        const header = <Header>
            <h1>Statistics</h1>
            <Space/>
            <UpperText className='Header-upperText' text='Date'>
                <ExpandableDropdown text={ date } data={ dates } onDataChangeClick={this.changeDate.bind(this)}/>
            </UpperText>
            <UpperText className='Header-upperText' text='Account'>
                <ExpandableDropdown text={ account } data={ accounts } onDataChangeClick={this.changeAccount.bind(this)}/>
            </UpperText>
        </Header>;

        return (
            <Content header={header} className={cn('RewardsStatistics', this.props.className)}>
                <Paper className='Content-paper' type='wide'>
                    <h1>Rewards statistics</h1>
                    <Table className='table' headers={statisticsTable.headers} data={statisticsTable.rows}/>
                </Paper>
                <Paper className='Content-paper' type='wide'>
                    <h1>Brand statistics</h1>
                    <Table className='table' headers={tableHeaders2} data={partners}/>
                </Paper>
            </Content>
        );
    }
}

export default RewardStatstics;
