import React, { Component } from 'react';

import './Icons.scss';

interface Props {
    icon: 'header-back-arrow';
    onClick?: () => void;
}

const Icons: React.SFC<Props> = (props: Props) => {

    const { icon } = props;

    function handleClick(): void {
        const { onClick } = props;
        if (onClick) {
            onClick();
        }
    }

    if (icon === 'header-back-arrow') {
        return (
            <div onClick={() => handleClick()} className='header-arrow-back' />
        );
    }

    return null;
};

export default Icons;
