import * as React from 'react';
import cn from 'classnames';

import GoogleMapReact, { ChangeEventValue, Props as GoogleMapProps, Coords, ClickEventValue } from 'google-map-react';
import OverlayLoader from '../../components/Layout/OverlayLoader/OverlayLoader';
import { IHeatmap } from '../../typings/src/trips/ITrips';

import { RouteComponentProps, withRouter, Route } from 'react-router-dom';

import { GoogleMapStore } from '../../redux/stores/GoogleMap/GoogleMapStore';

import './GoogleMap.scss';
import { connect } from 'react-redux';

interface GoogleMapsProps {
    props: GoogleMapProps;
    mounted: (ref: React.RefObject<any>) => void;
    onChange?: (event: ChangeEventValue) => void;
    onClick?: (event: ClickEventValue) => void;
    heatmap?: IHeatmap[];
}

class GoogleMaps extends React.Component<RouteComponentProps<{ location: string }> & GoogleMapsProps> {
    store = new GoogleMapStore('googleMaps', this.props.history, this.props.location, this.props.match);
    googleMap: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.googleMap = React.createRef();
    }

    componentWillMount(): void {
        this.store.load();
    }

    componentWillReceiveProps(nextProps: RouteComponentProps<{ location: string }> & GoogleMapsProps) {
        if (this.props.match.params.location !== nextProps.match.params.location) {
            this.store.parseLocation(nextProps.match.params.location);
        }
    }

    onChange = async (value: ChangeEventValue) => {
        this.store.onMapChange(value);
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    callback(): void {
        if (this.props.mounted) {
            this.props.mounted(this.googleMap);
        }
    }

    render() {

        return (
            <div className={cn('GoogleMap')}>
                <GoogleMapReact
                    ref={this.googleMap}
                    bootstrapURLKeys={{ key: 'AIzaSyATqXlZ7pFyKqsSuMyFecSmNiL70UCX5W8' }}
                    onGoogleApiLoaded={() => this.callback()}
                    onChange={this.onChange}
                    center={this.store.center}
                    zoom={this.store.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onClick={(e: ClickEventValue) => this.props.onClick ? this.props.onClick(e) : void 0}
                    {...this.props.props}
                >
                    {this.props.children}
                </GoogleMapReact>

                <OverlayLoader isLoading={this.store.isMapLoading} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    googleMaps: state.googleMaps
});

export default withRouter(connect(mapStateToProps)(GoogleMaps));
