import { store } from '../../';
import { request } from '../../api/RequestAPI';
import { IPageParams } from './ListStoreWithPagination';

import ReduxActions from '../actions/index';
import modalStore from './ModalStore';

import utils from '../../utils';

export class ListStore<T> {
    reduxActions: ReduxActions;

    isLoading: boolean = true;
    items: T[] = [];
    error: Error;
    itemsCount: number = 0;

    params: IPageParams = {};

    constructor(protected path: string, protected listPath: string = '', protected reduxStorePath: string) {
        this.reduxActions = new ReduxActions(this.reduxStorePath);
    }

    preload(): void {
        for (const [key, value] of utils.iterateObject(store.getState()[this.reduxStorePath])) {
            this[key] = value;
        }
        this.reduxActions.update();
    }

    async load(params?: IPageParams, showLoader: boolean = true): Promise<void> {
        if (showLoader) {
            this.isLoading = true;
        }

        this.error = null;
        this.reduxActions.update();

        if (params) {
            this.params = params;
        }
        await this.afterInitParams();

        const query = utils.paramsToQuery(this.params);

        try {
            this.items = await request(`${this.path}${this.listPath}${query}`, 'get');
            this.itemsCount = this.items.length;
            this.reduxActions.setItems<T[]>(this.items);
        } catch (error) {
            this.items = [];
            this.error = error;
        } finally {
            await this.afterFinally();
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    async loadFromSearch(search: string) {
        this.items = [];
        await this.load(utils.searchToParams(search));
    }

    async loadSilently(): Promise<void> {
        this.load(undefined, false);
    }

    async delete(id: number | string) {

        modalStore.delete(`Are you sure you want to delete this item?`, async (buttonIndex) => {
            if (buttonIndex === 1) {
                try {
                    await modalStore.execFullScreen(request(`${this.path}/${id}`, 'delete'));
                    await this.load();
                } catch (error) {
                    modalStore.showError(error);
                }
            }
        });
    }

    protected afterFinally(): Promise<void> {
        return Promise.resolve();
    }

    protected afterInitParams(): Promise<void> {
        return Promise.resolve();
    }
}
