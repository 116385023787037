import { combineReducers } from 'redux';
import { IReducerAction } from '../types/ITypes';

import commonReducer from './commonReducer';

function createNamedWrapperReducer(reducerFunction, reducerName) {
    return (state: any, action: IReducerAction) => {
        const { name } = action;
        const isInitializationCall = state === undefined;
        if (name !== reducerName && !isInitializationCall) {
            return state;
        }
        return reducerFunction(state, action);
    };
}

export default combineReducers({
    modal: createNamedWrapperReducer(commonReducer, 'modal'),
    advertisers: createNamedWrapperReducer(commonReducer, 'advertisers'),
    reports: createNamedWrapperReducer(commonReducer, 'reports'),
    reportsOData: createNamedWrapperReducer(commonReducer, 'reportsOData'),
    programLogs: createNamedWrapperReducer(commonReducer, 'programLogs'),
    programLogsMetaData: createNamedWrapperReducer(commonReducer, 'programLogsMetaData'),
    merchants: createNamedWrapperReducer(commonReducer, 'merchants'),
    driverAnalyitcs: createNamedWrapperReducer(commonReducer, 'driverAnalyitcs'),
    tripsStatistics: createNamedWrapperReducer(commonReducer, 'tripsStatistics'),
    googleMaps: createNamedWrapperReducer(commonReducer, 'googleMaps'),
    ODataReducer: commonReducer,
    pointsInsight: createNamedWrapperReducer(commonReducer, 'pointsInsight'),
    dataDictionary: createNamedWrapperReducer(commonReducer, 'dataDictionary')
});
