import React, { Component } from 'react';
import ValidationErrors from '../common/ValidationErrors';

import './Form.sass';
import { Validation } from '../../common/src';

export interface IClassName {
    name: 'label';
    className: string;
}

interface ITextFormGroupProps {
    name: string;
    label: string;

    placeholder?: string;
    defaultValue?: string;
    errors?: Validation.Errors.IValidationErrors;
    required?: boolean;
    onChange?: (e) => void;

    className?: IClassName[];
}

class TextFormGroup extends Component<ITextFormGroupProps> {

    constructor(props) {
        super(props);
    }

    findClassName(classNames: IClassName[], match: string): string {
        for (const className of classNames) {
            if (className.name === match)
                return className.className;
        }
        return '';
    }

    render() {
        const { label, name, placeholder, defaultValue, errors, required, onChange, className } = this.props;

        return (
            <div className={`form-group row`}>
                <label className={`col-sm-3 ${className && className.length ? this.findClassName(className, 'label') : ''}`}>{label}{required ? '*' : ''}</label>
                <div className='col-sm-9'>
                    {!this.props.children ?
                        <input
                            className='form-control'
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            onChange={(e) => onChange(e)}
                            onBlur={(e) => {
                                e.preventDefault();
                                e.currentTarget.classList.add('validate-field');
                            }}
                        />
                        :
                        this.props.children
                    }
                    {errors && errors[name] && <ValidationErrors errors={errors[name]} fieldName={`${label}`} />}
                </div>
            </div>
        );
    }
}

export default TextFormGroup;
