import React, { Component } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import ReportsList from '../ReportsList/ReportsList';
import ReportSingle from './ReportSingle';

export interface IReportsRouteProps {
    reportId: string;
}

export default class ReportsTab extends Component<RouteComponentProps<{}>> {
    render(): JSX.Element {
        const url = this.props.match.url;

        return (
            <Switch>
                <Route path={`${url}`} exact={true} component={ReportsList} />
                <Route path={`${url}/:reportId`} exact={true} render={(props: any) => <ReportSingle {...props}/>} />)
                <Redirect from={url} to={url.substring(0, url.lastIndexOf('/'))} />
            </Switch>
        );
    }
}
