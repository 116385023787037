import * as React from 'react';
import cn from 'classnames';
import * as d3 from 'd3';
import LineChartGrid from 'components/Layout/LineChart/LineChartGrid';
import './LineChart.sass';
import LineChartAxis from 'components/Layout/LineChart/LineChartAxis';

const xAxisHeight = 25;
const xAxisBottomHeight = 25;
const bottomChartTopMargin = 30;
const bottomChartHeight = 75;


interface Props {
    className?: string;
    lines: any;
    onZoom?: (zoomLevel: number) => void;
    minZoom?: number;
    maxZoom?: number;
}

interface State {
    xScale: d3.ScaleTime<any, any>;
    yScale: d3.ScaleLinear<any, any>;
    xScaleBottom: d3.ScaleTime<any, any>;
    yScaleBottom: d3.ScaleLinear<any, any>;
    zoom: d3.ZoomBehavior<any, any>;
    brush: d3.BrushBehavior<any>;
    width: number;
    height: number;
    lineChartRef: React.RefObject<HTMLDivElement>;
    brushRef: React.RefObject<SVGGElement>;
    zoomRef: React.RefObject<SVGRectElement>;
    linesRef: any;
    line: d3.Line<any>;
    lineData: any;
    axisRef: React.RefObject<SVGGElement>;
    lines?: any;
    onZoomCallback?: any;
    minZoom?: number;
    maxZoom?: number;
    chartHeight: number;
}

export default class LineChart extends React.Component<Props, State> {
    state: State = {
        xScale: d3.scaleTime(),
        yScale: d3.scaleLinear(),
        xScaleBottom: d3.scaleTime(),
        yScaleBottom: d3.scaleLinear(),
        brushRef: React.createRef(),
        zoomRef: React.createRef(),
        zoom: d3.zoom(),
        brush: d3.brushX(),
        line: d3.line(),
        axisRef: React.createRef(),
        lineChartRef: React.createRef(),
        width: 0,
        height: 0,
        linesRef: {},
        lineData: {},
        chartHeight: 0
    };

    constructor(props) {
        super(props);
        this.state.lines = props.lines;
        this.state.onZoomCallback = props.onZoom;
        this.state.minZoom = props.minZoom;
        this.state.maxZoom = props.maxZoom;
    }

    updateRange() {
        if (this.state.lineChartRef.current) {
            const {xScale, yScale} = this.state;
            const {width, height} = this.state.lineChartRef.current.getBoundingClientRect();
            const chartHeight = height - (xAxisHeight + bottomChartHeight + xAxisBottomHeight) - 15;

            xScale.range([0, width]);
            yScale.range([chartHeight, 0]);

            this.setState({xScale, yScale, width, height, chartHeight});
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => { this.updateRange(); });
        this.updateRange();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateRange);
    }

    onBrush() {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') {
            return;
        }
        const {xScale, xScaleBottom, zoomRef, zoom, width, linesRef, line, axisRef, lines} = this.state;
        const s = d3.event.selection || xScaleBottom.range();
        xScale.domain(s.map(xScaleBottom.invert, xScaleBottom));
        d3.select(zoomRef.current).call(zoom.transform as any, d3.zoomIdentity
            .scale(width / (s[1] - s[0]))
            .translate(-s[0], 0));

        for (const prop in lines) {
            if (linesRef[prop]) {
                d3.select(linesRef[prop].current).attr('d', line(lines[prop]));
            }
        }

        d3.select(axisRef.current).call(d3.axisBottom(xScale) as any);
    }

    onZoom() {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') {
            return;
        }

        const {xScale, xScaleBottom, brushRef, brush, linesRef, axisRef, line} = this.state;
        const t = d3.event.transform;
        if (this.state.onZoomCallback && d3.event.sourceEvent && (d3.event.sourceEvent.type === 'wheel' || d3.event.sourceEvent.sourceEvent)) {
            let lines = this.state.onZoomCallback(t.k);
            this.setState({ lines });
        }
        xScale.domain(t.rescaleX(xScaleBottom).domain());
        d3.select(brushRef.current).call(brush.move as any, xScale.range().map(t.invertX, t));

        for (const prop in this.state.lines) {
            if (linesRef[prop]) {
                d3.select(linesRef[prop].current).attr('d', line(this.state.lines[prop]));
            }
        }

        d3.select(axisRef.current).call(d3.axisBottom(xScale) as any);
    }

    render() {
        const {className} = this.props;
        const {xScale, yScale, xScaleBottom, yScaleBottom, width, height, lineChartRef, brushRef, zoomRef, brush, zoom, linesRef, line, axisRef, lines, chartHeight} = this.state;

        const paths = [];
        const pathsBottom = [];
        const bisector = d3.bisector((d: any) => {return d.date; }).left;

        const grid = d3.axisRight(yScale)
            .ticks(5)
            .tickSize(width);

        const allPoints = Object.values(lines).reduce((pA: Array<any>, cA) => pA.concat(cA), []);
        const firstData: any = Object.values(lines)[0];

        xScale.domain(d3.extent(firstData, (d: any) => d.date as Date));
        yScale.domain([0, d3.max((allPoints as Array<any>), (d) => d.value as number)]);

        const xAxis = d3.axisBottom(xScale);

        xScaleBottom.range([0, width]).domain(xScale.domain());
        yScaleBottom.range([bottomChartHeight, 0]).domain(yScale.domain());

        let xAxisBottom = d3.axisBottom(xScaleBottom);

        line.x((d) => xScale(d.date))
            .y((d) => yScale(d.value));

        const lineBottom = d3.line<any>()
            .x((d) => xScaleBottom(d.date))
            .y((d) => yScaleBottom(d.value));

        for (const id in lines) {
            if (lines[id]) {
                linesRef[id] = React.createRef();
                paths.push(<path d={line(lines[id])} ref={linesRef[id]} className={`line ${id}`} key={id} onMouseOver={(event) => {
                    const tmpClassName = event.currentTarget.classList[1];
                    const svg = d3.select(event.currentTarget).node();

                    const date = xScale.invert(d3.clientPoint(svg, event)[0]);

                    let index = bisector(lines[tmpClassName], date);

                    if (!lines[tmpClassName][index - 1]) {
                        index = 0;
                    } else if (!lines[tmpClassName][index]) {
                        index = lines[tmpClassName].length - 1;
                    } else {
                        const prev = lines[tmpClassName][index - 1].date.getTime();
                        const curr = lines[tmpClassName][index].date.getTime();
                        const time = date.getTime();

                        const half = ((curr - prev) / 2) + prev;

                        index = time > half ? index : index - 1;
                    }

                    const overlayContent = document.getElementById('overlayContent');
                    overlayContent.setAttribute('class', `overlayContent ${tmpClassName}`);

                    const hoverLine = document.getElementById('hoverLine');
                    hoverLine.setAttribute('y2', `${chartHeight - yScale(lines[tmpClassName][index].value)}`);

                    const overlay = document.getElementById('overlay');
                    overlay.setAttribute('class', 'overlay');
                    overlay.setAttribute('transform', `translate(${xScale(lines[tmpClassName][index].date)}, ${yScale(lines[tmpClassName][index].value)})`);

                    const tooltipText = document.getElementById('tooltip-text');
                    if (tooltipText) {
                        if (index === 0) {
                            tooltipText.style.left = '0px';
                            tooltipText.style.right = null;
                        } else if (index === lines[tmpClassName].length - 1) {
                            tooltipText.style.left = null;
                            tooltipText.style.right = '0px';
                        } else {
                            tooltipText.style.left = `${xScale(lines[tmpClassName][index].date) - 40}px`;
                        }
                        tooltipText.style.top = `${yScale(lines[tmpClassName][index].value) - 30}px`;
                        tooltipText.style.display = 'block';
                        tooltipText.innerHTML = `<span>${lines[tmpClassName][index].tooltipText}</span>`;
                    }
                }}/>);

                pathsBottom.push(<path d={lineBottom(lines[id])} className={`lineBottom ${id}`} key={id}/>);
            }
        }

        brush.extent([[0, 0], [width, bottomChartHeight]])
            .on('brush end', this.onBrush.bind(this));

        d3.select(brushRef.current)
            .call(brush as any)
            .call(brush.move as any, xScale.range());

        zoom.scaleExtent([
                this.state.minZoom ? this.state.minZoom : 1,
                this.state.maxZoom ? this.state.maxZoom : Infinity
            ])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on('zoom', this.onZoom.bind(this));

        d3.select(zoomRef.current)
            .call(zoom as any);

        return (
            <div className={cn('LineChart', className)} ref={lineChartRef}>
                <div id='tooltip-text'></div>
                <svg viewBox={`-10 -10 ${width + 20} ${height + 20}`} width={width} height={height}>
                    <g className='LineChart-marginBox'>
                        <g className='chart' onMouseLeave={() => {
                            const overlay = document.getElementById('overlay');
                            overlay.setAttribute('class', 'overlay hide');

                            const tooltipText = document.getElementById('tooltip-text');
                            if (tooltipText) {
                                tooltipText.style.display = 'none';
                            }
                        }}>
                            <defs>
                                <clipPath id='clip'>
                                    <rect width={width + 5} height={chartHeight + 5}/>
                                </clipPath>
                            </defs>
                            <LineChartGrid grid={grid}/>
                            <g transform={`translate(0,${chartHeight})`}>
                                <LineChartAxis axis={xAxis} axisRef={axisRef}/>
                            </g>
                            <rect className='zoom' width={width} height={chartHeight} ref={zoomRef} onMouseEnter={() => {
                                const overlay = document.getElementById('overlay');
                                overlay.setAttribute('class', 'overlay hide');

                                const tooltipText = document.getElementById('tooltip-text');
                                if (tooltipText) {
                                    tooltipText.style.display = 'none';
                                }
                            }}/>
                            <g className='paths' clipPath='url(#clip)'>
                                {...paths}
                            </g>
                            <g id='overlay' className='overlay hide'>
                                <g id='overlayContent' className='overlayContent transit'>
                                    <line id='hoverLine' className='hoverLine' y1={0} y2={0} x1={0} x2={0}/>
                                    <circle r={7.5} className='dot'/>
                                </g>
                            </g>
                        </g>
                        <g className='bottomChart' transform={`translate(0,${chartHeight + xAxisBottomHeight + bottomChartTopMargin})`}>
                            {...pathsBottom}
                            <g className='brush' ref={brushRef}/>
                            <g transform={`translate(0,${bottomChartHeight})`}>
                                <LineChartAxis axis={xAxisBottom}/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
