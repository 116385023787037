import * as React from 'react';
import cn from 'classnames';

import './BulletLegend.sass';


interface DataItem {
    label: string;
    color: string;
    id: string;
}

interface Props {
    className?: string;

    data: DataItem[];
    chartTypeId: string;
}

const BulletLegend: React.SFC<Props> = (props) => {
    const {className, data, chartTypeId} = props;
    let list;

    const onClick = (event) => {
        const tmpClassName = event.currentTarget.className.split(' ')[1];
        if (chartTypeId === 'bar') {
            const bars = document.getElementsByClassName(`bar ${tmpClassName}`);
            for (let c in bars) {
                if (bars[c].classList && bars[c].classList.contains('hide')) {
                    bars[c].classList.remove('hide');
                } else if (bars[c].classList) {
                    bars[c].classList.add('hide');
                }
            }
        } else {
            const path = document.getElementsByClassName(`line ${tmpClassName}`)[0];
            const pathBottom = document.getElementsByClassName(`lineBottom ${tmpClassName}`)[0];
            if (path.classList.contains('hide')) {
                path.classList.remove('hide');
            } else {
                path.classList.add('hide');
            }
            if (pathBottom.classList.contains('hide')) {
                pathBottom.classList.remove('hide');
            } else {
                pathBottom.classList.add('hide');
            }
        }
    };

    let i = 0;
    list = data.map(({label, color, id}) =>
        <li key={++i} className={`BulletLegend-item ${id}`} onClick={onClick}>
            <i className='BulletLegend-bullet' style={{background: color}}/>
            {label}
        </li>
    );

    return (
        <div className={cn('BulletLegend', className)}>
            <ul className='BulletLegend-list'>{list}</ul>
        </div>
    );
};

export default BulletLegend;
