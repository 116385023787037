import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ExpandableDropdown } from '../../Layout/Dropdown/ExpandableDropdown';
import { DropdownItem } from '../../../components/Layout/Dropdown/Dropdown';
import { MetaDataStore } from '../../../redux/stores/ProgramLogs/MetaDataStore';

import Form from '../../../components/Form/Form';
import TextFormGroup from '../../Form/TextFormGroup';
import DetailsStatus from '../../../components/Details/DetailsStatus';

import '../../Form/Form.sass';

interface IEditCartConfigurationProps {
    cartId: string;
}

class MetaDataConfigurationPage extends Component<IEditCartConfigurationProps> {
    store = new MetaDataStore(this.props.cartId);

    campaignGoalDropdownItems: DropdownItem[] = [
        { id: '1', value: 'inform', tooltip: 'audience about new products, services or features' },
        { id: '2', value: 'persuade', tooltip: 'audience to choose advertiser and their product / service over competition' },
        { id: '3', value: 'remind', tooltip: 'audience about advertiser offering to stay top-of-mind' }
    ];

    adFormatDropdownItems: DropdownItem[] = [
        { id: '1', value: 'live read' },
        { id: '2', value: 'live read (endorsement)' },
        { id: '3', value: 'produced (straight read)' },
        { id: '4', value: 'produced (dialogoue)' },
        { id: '5', value: 'produced (monologue)' },
        { id: '6', value: 'produced (jingle)' },
        { id: '7', value: 'produced (combination)' }
    ];

    async componentDidMount() {
        await this.store.load();
        await this.store.initDropdowns();
    }

    render() {

        const { item, isLoading, error } = this.store;

        return (
            <div>
                {(this.store.item && !!this.store.nameDropdownItems.length && !!this.store.offersCategoriesItems.length) &&
                    <Form onSubmit={(e) => this.store.handleSubmit(e, 'post')}>
                        <div className='form-default form-light'>
                            <div>
                                <label className='form-title form-default-ml'>
                                    <h3>Meta Data</h3>
                                </label>
                                <TextFormGroup
                                    label='Advertiser Name'
                                    name='advertiserName'
                                    placeholder='Advertiser Name'
                                    defaultValue={item.advertiserName}
                                    errors={this.store.validationErrors}
                                >
                                    <ExpandableDropdown
                                        filter={true}
                                        className={`form-select ${this.store.nameDropdownItems.length > 0 ? '' : 'disabled'}`}
                                        text={item.advertiserName}
                                        data={this.store.nameDropdownItems}
                                        onDataChangeClick={(id, value) => this.store.handleDropdownChange(value, 'advertiserName')}
                                    />
                                </TextFormGroup>

                                <TextFormGroup
                                    label='Campaign Name'
                                    name='campaignName'
                                    placeholder='Campaign Name'
                                    defaultValue={item.campaignName}
                                    errors={this.store.validationErrors}
                                    onChange={(e) => this.store.handleChange(e)}
                                />

                                <TextFormGroup
                                    label='Campaign Goal'
                                    name='campaignGoal'
                                    placeholder='Campaign Goal'
                                    defaultValue={item.campaignGoal}
                                    errors={this.store.validationErrors}
                                >
                                    <ExpandableDropdown
                                        placeholder='Select'
                                        className={`form-select ${this.campaignGoalDropdownItems.length > 0 ? '' : 'disabled'}`}
                                        text={item.campaignGoal}
                                        data={this.campaignGoalDropdownItems}
                                        onDataChangeClick={(id, value) => this.store.handleDropdownChange(value, 'campaignGoal')}
                                    />
                                </TextFormGroup>

                                <TextFormGroup
                                    label='Product Name'
                                    name='productName'
                                    placeholder='Product Name'
                                    defaultValue={item.productName}
                                    errors={this.store.validationErrors}
                                    onChange={(e) => this.store.handleChange(e)}
                                />

                                <TextFormGroup
                                    label='Product Category'
                                    name='productCategory'
                                    placeholder='Product Category'
                                    defaultValue={item.productCategory}
                                    errors={this.store.validationErrors}
                                >
                                    <ExpandableDropdown
                                        filter={true}
                                        className={`form-select ${this.store.offersCategoriesItems.length > 0 ? '' : 'disabled'}`}
                                        text={item.productCategory}
                                        data={this.store.offersCategoriesItems}
                                        onDataChangeClick={(id, value) => this.store.handleDropdownChange(value, 'productCategory')}
                                    />
                                </TextFormGroup>

                                <TextFormGroup
                                    label='Ad Format'
                                    name='adFormat'
                                    placeholder='Ad Format'
                                    defaultValue={item.adFormat}
                                    errors={this.store.validationErrors}
                                >
                                    <ExpandableDropdown
                                        className={`form-select ${this.adFormatDropdownItems.length > 0 ? '' : 'disabled'}`}
                                        text={item.adFormat}
                                        data={this.adFormatDropdownItems}
                                        onDataChangeClick={(id, value) => this.store.handleDropdownChange(value, 'adFormat')}
                                    />
                                </TextFormGroup>
                            </div>
                            <div className='form-ml-3'>
                                <button className='btn-action' type='submit'>SAVE</button>
                            </div>
                        </div>
                    </Form>
                }
                <DetailsStatus store={item} isUpdating={isLoading} error={error} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    programLogsMetaData: state.programLogsMetaData
});

export default connect(mapStateToProps)(MetaDataConfigurationPage);

