import { IReducerAction } from '../types/ITypes';

const CommonReducer = (state = {}, action: IReducerAction) => {

    const obj = {
        [action.attr]: action.payload
    };

    switch (action.type) {

        case 'PUT':
            return { ...state, ...obj };

        default:
            return state;
    }
};

export default CommonReducer;
