import * as React from 'react';
import history from '../../components/React/history';
import { ListStoreWithPagination } from '../../redux/stores/ListStoreWithPagination';
import { ODataStore } from '../../redux/stores/ODataStore';
import { ListStore } from '~/redux/stores/ListStore';
import { ExpandableDropdown } from '../Layout/Dropdown/ExpandableDropdown';
import { DropdownItem } from '../Layout/Dropdown/Dropdown';
import { CampaignAttributionMapStore } from '../../redux/stores/CampaignAttributionMap/CampaignAttributionMapStore';

import DateTimePicker from '../Layout/Date/DateTimePicker';

import utils from '../../utils';

interface FilterProps {
    store: ListStoreWithPagination<{}> | ODataStore<{}> | ListStore<{}> | CampaignAttributionMapStore;
    field: string;

    memorize?: boolean;
    type?: 'string' | 'date picker' | 'dropdown';
    dropdownData?: DropdownItem[];
    placeholder?: string;
}

export default class Filter extends React.Component<FilterProps> {
    ref: React.RefObject<any>;
    timeout;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    componentWillReceiveProps(props: FilterProps) {
        const { filter } = props.store.params;
        if (!filter && this.ref && this.ref.current) {
            this.ref.current.value = '';
            return;
        }

        const filterObj = utils.searchToParams(`?${filter}`, false);

        if (!filterObj[this.props.field] && this.ref.current) {
            this.ref.current.value = '';
        }
    }

    handleTyping() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.handleFilterChange(this.ref.current.value), 300);
    }

    handleFilterChange(value: string): void {
        const filterParam = this.props.store.params ? this.props.store.params.filter : undefined;
        let filter;

        if (filterParam) {
            filter = utils.searchToParams(`?${filterParam}`, false);
        } else {
            filter = {};
        }

        if (value) {
            filter[this.props.field] = value;
        } else {
            delete filter[this.props.field];
        }

        if (utils.isEmpty(filter)) {
            delete this.props.store.params.filter;
        } else {
            this.props.store.params.filter = utils.paramsToPath('', filter).substring(1);
        }

        if (this.props.memorize) {
            this.props.store.reduxActions.setCustom('params', { filter: this.props.store.params.filter });
        }

        const path = utils.paramsToPath(location.pathname, this.props.store.params);
        history.replace(path);
    }

    getDefaultValue(): string {
        const filter = this.props.store.params ? this.props.store.params.filter : null;
        const params = utils.searchToParams(`?${filter}`, false);

        for (const param of Object.keys(params)) {
            if (param !== this.props.field) {
                continue;
            }

            let value = params[param];

            if (!value) {
                break;
            }

            return value;
        }
        return '';
    }

    getDropdownText(): string {
        const filterObj = utils.searchToParams('?' + this.props.store.params.filter, false);

        if (!utils.isEmpty(filterObj)) {
            return filterObj[this.props.field] ? filterObj[this.props.field] : '';
        }
        return '';
    }

    render() {
        const { placeholder, type } = this.props;
        const defaultValue = this.getDefaultValue();

        return (
            <div className='input-group input-group-sm mb-3'>
                {(!type || type === 'string') &&
                    <input
                        ref={this.ref}
                        type='text'
                        defaultValue={defaultValue}
                        onKeyDown={() => this.handleTyping()}
                        className='form-control'
                        aria-label='Small'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder={placeholder ? placeholder : 'Search'}
                    />
                }

                {type && type === 'date picker' &&
                    <DateTimePicker
                        ref={this.ref}
                        defaultValue={defaultValue}
                        enableTime
                        mustSelect
                        disabled={this.props.store.isLoading}
                        onChange={val => this.handleFilterChange(val)}
                    />
                }

                {type && type === 'dropdown' &&
                    <ExpandableDropdown
                        text={this.getDropdownText()}
                        textAsKey={true}
                        data={this.props.dropdownData}
                        onDataChangeClick={(id, value) => this.handleFilterChange(id)}
                    />
                }
            </div>
        );
    }
}
