import * as React from 'react';
import cn from 'classnames';

import './CollapsableTableRow.scss';

interface Props {
    className?: string;
    shouldRenderNextElement?: (should: boolean) => void;
}
export default class CollapsableTableRow extends React.Component<Props> {
    tableRowRef: React.RefObject<HTMLTableRowElement>;
    timeout: NodeJS.Timeout;

    constructor(props) {
        super(props);

        this.tableRowRef = React.createRef();
        this.expand = this.expand.bind(this);
    }

    componentDidMount() {
        this.tableRowRef.current.addEventListener('click', this.expand);
    }

    componentWillUnmount() {
        if (this.tableRowRef.current) {
            this.tableRowRef.current.removeEventListener('click', this.expand);
        }
    }

    expand(e: MouseEvent) {
        e.preventDefault();

        if (e.target['nodeName'] === 'BUTTON') {
            return;
        }

        const currentTarget = this.tableRowRef.current;

        if (currentTarget.classList.contains('collapsed')) {
            currentTarget.classList.toggle('collapsed');
            const el = currentTarget.nextElementSibling.firstElementChild.firstElementChild;
            el.classList.toggle('collapsing');
            el.setAttribute('style', `height: auto`);
            this.props.shouldRenderNextElement(true);
        } else {
            const el = currentTarget.nextElementSibling.firstElementChild.firstElementChild;
            currentTarget.classList.toggle('collapsed');
            el.classList.toggle('collapsing');
            this.props.shouldRenderNextElement(false);
        }
    }

    render() {
        const { children, className } = this.props;

        return (
            <tr
                ref={this.tableRowRef}
                className={cn('cursor-pointer collapse-row collapsed', className)}
            >
                {children}
            </tr>
        );
    }
}

