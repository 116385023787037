import AdvertisersServiceAPI from '../../../api/AdvertisersServiceAPI';
import OffersApi from '../../../api/OffersApi';

import { DetailsStore } from '../DetailsStore';
import { DropdownItem } from '../../../components/Layout/Dropdown/Dropdown';
import { IMetaData } from '../../../typings/src/reports/IReport';
import { Validation } from '../../../common/src';

export class MetaDataStore extends DetailsStore<IMetaData> {
    nameDropdownItems: DropdownItem[] = [];
    offersCategoriesItems: DropdownItem[] = [];

    constructor(private cartId: number | string) {
        super('/radio/meta-data', cartId, 'programLogsMetaData');
        this.preload();
    }

    async initDropdowns(): Promise<void> {

        if (!this.nameDropdownItems.length || !this.offersCategoriesItems.length) {
            this.isLoading = true;
            this.error = null;
            this.reduxActions.update();
        }

        try {
            const advertisers = await AdvertisersServiceAPI.getAdvertisers();
            const offersCategories = await OffersApi.getOffersCategories();
            this.nameDropdownItems = advertisers.map(val => { return { id: val.id.toString(), value: val.name } as DropdownItem; });
            this.offersCategoriesItems = offersCategories.categories.map(val => { return { id: val.id.toString(), value: val.name } as DropdownItem; });
            this.reduxActions.setCustom('nameDropdownItems', this.nameDropdownItems);
            this.reduxActions.setCustom('offersCategoriesItems', this.offersCategoriesItems);
        } catch (error) {
            this.error = error;
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    execValidate(): Validation.Errors.IValidationErrors {
        return Validation.MetaDataValidation(this.item);
    }

    async afterError(): Promise<void> {
        // NOTE: If meta data does not exists, create a new one
        if (this.error['statusCode'] === 404) {
            this.item = {};
            this.error = null;
        }
    }
}
