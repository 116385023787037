import * as React from 'react';
import cn from 'classnames';

import './Content.sass';


interface IProps {
    className?: string;
    children: React.ReactNode;
    header?: React.ReactNode;
}

const Content: React.SFC<IProps> = (props) => {
    const {className} = props;

    return (
        <main id='main-wrapper' className={cn('Content', className)}>
            <div>
                {props.header}
                <div className='Content-inside'>
                    {props.children}
                </div>
            </div>
        </main>
    );
};

export default Content;
