import React, { Component } from 'react';
import './HorizontalScroll.scss';


export default class HorizontalScroll extends Component {
    clientWidth: number;
    scrollWidth: number;

    mainEl: HTMLElement;
    ref: React.RefObject<HTMLDivElement>;
    scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.scrollRef = React.createRef();

        this.update = this.update.bind(this);
        this.resize = this.resize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount(): void {
        this.mainEl = document.getElementById('main-wrapper');
        window.addEventListener('resize', this.resize);
        this.mainEl.addEventListener('scroll', this.update);
    }

    componentDidUpdate(): void {
        this.resize();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.resize);
        this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
        this.mainEl.removeEventListener('scroll', this.handleScroll);

    }

    create(): void {
        this.scrollRef.current.style.width = `${this.clientWidth}px`;
        this.scrollRef.current.childNodes[0]['style'].width = `${this.scrollWidth}px`;
        this.scrollRef.current.addEventListener('scroll', this.handleScroll);
        this.update();
    }

    update(): void {
        if (this.ref.current && this.scrollRef.current) {
            const divRect = this.ref.current.getBoundingClientRect();

            const containerTop = window.innerHeight - divRect.top;
            const scrollHeight = this.scrollRef.current.getBoundingClientRect().height;
            this.scrollRef.current.style.top = `${containerTop - scrollHeight}px`;

            if (divRect.bottom - window.innerHeight < 0 || (divRect.top - window.innerHeight > 0)) {
                this.scrollRef.current.classList.add('hidden');
            } else {
                this.scrollRef.current.classList.remove('hidden');
            }
        }
    }

    resize(): void {
        if (this.ref.current) {
            this.scrollWidth = this.ref.current.scrollWidth;
            this.clientWidth = this.ref.current.clientWidth;
            this.create();
        }
    }

    handleScroll(e: Event): void {
        this.ref.current.scrollLeft = e.target['scrollLeft'];
        this.scrollRef.current.style.left = `${e.target['scrollLeft']}px`;
    }

    render() {
        const { children } = this.props;

        return (
            <div
                ref={this.ref}
                className='list-container'
            >
                {children}
                <div ref={this.scrollRef} className='floating-scroll' >
                    <div></div>
                </div>
            </div>
        );
    }
}
