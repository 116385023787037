import { DropdownItem } from '../../../components/Layout/Dropdown/Dropdown';
import ReduxActions from '../../actions';
import googleMapUtils from '../../../components/GoogleMap/GoogleMapUtils';
import MerchantsAPI, { MAX_MERCHANTS_TILE_ZOOM } from '../../../api/MerchantsAPI';

import { IMerchant, IStoreVisits, ILocation } from '../../../typings/src/campaignAttribution/ICampaignAttribution';
import { Coords, Bounds } from 'google-map-react';
import { IPageParams } from '../ListStoreWithPagination';
import { request } from '../../../api/RequestAPI';
import { IDictionary } from '../../../typings/src/common';
import utils from '../../../utils';

import { store } from '../../../index';
import modalStore from '../ModalStore';

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};

export class CampaignAttributionMapStore {
    reduxActions: ReduxActions;

    isLoading: boolean = false;
    allLocationVisits: ILocation[] = [];

    merchantsWithVisits: IMerchant[] = [];
    merchantsWithVisitsFiltered: IMerchant[] = [];

    params: IPageParams = {};

    categoryDropdown: DropdownItem[] = [];

    center: Coords = {
        lat: 37.24347646243385,
        lng: -121.88998455877652
    };
    zoom: number = 10;

    locationVisitSignal: AbortController;

    constructor(protected reduxPath = 'merchants') {
        this.reduxActions = new ReduxActions(this.reduxPath);
        this.preload();
    }

    preload(): void {
        for (const [key, value] of utils.iterateObject(store.getState()[this.reduxPath])) {
            this[key] = value;
        }
        this.reduxActions.update();
    }

    setupParams(): any {
        let obj: any = {};

        if (this.params.filter) {
            obj = utils.searchToParams(`?${this.params.filter}`, false);
        }

        obj = Object.assign(
            {
                from: new Date(googleMapUtils.timeOptions[0].date.getFullYear(), googleMapUtils.timeOptions[0].date.getMonth()).toISOString(),
                to: new Date(googleMapUtils.timeOptions[0].date.getFullYear(), googleMapUtils.timeOptions[0].date.getMonth() + 1).toISOString()
            },
            obj
        );
        this.params.filter = utils.paramsToPath('', obj).substring(1);
        this.reduxActions.update();
        return obj;
    }

    setCenter(center: Coords) {
        this.center = center;
    }

    setZoom(zoom: number) {
        this.zoom = zoom;
    }

    async getMechants(center: Coords, zoom: number): Promise<IMerchant[]> {

        this.isLoading = true;
        this.reduxActions.update();

        try {
            return await MerchantsAPI.getMerchants(center, zoom) as IMerchant[];
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    async getAllLocationVisits(bounds: Bounds): Promise<void> {

        if (!bounds) {
            return;
        }

        if (!!this.locationVisitSignal) {
            this.locationVisitSignal.abort();
        }

        this.setupParams();
        const minLng = Math.min(bounds.ne.lng, bounds.nw.lng, bounds.se.lng, bounds.sw.lng);
        const minLat = Math.min(bounds.ne.lat, bounds.nw.lat, bounds.se.lat, bounds.sw.lat);
        const maxLng = Math.max(bounds.ne.lng, bounds.nw.lng, bounds.se.lng, bounds.sw.lng);
        const maxLat = Math.max(bounds.ne.lat, bounds.nw.lat, bounds.se.lat, bounds.sw.lat);
        const bboxQuery = `${encodeURIComponent('bbox')}=${encodeURIComponent(`${minLng},${minLat},${maxLng},${maxLat}`)}`;

        try {
            this.locationVisitSignal = new AbortController();
            this.allLocationVisits = await request(`/analytics/insights/location-visits?${this.params.filter}&${bboxQuery}`, 'get', { signal: this.locationVisitSignal.signal });
        } catch (error) {
            //
        } finally {
            this.reduxActions.update();
        }
    }

    async getMerchantLocationVisits(allMerchants: IMerchant[]): Promise<void> {

        if (!allMerchants || !allMerchants.length) {
            return;
        }

        let obj: any = this.setupParams();

        const uniqueMerchants = utils.ArrayUniqueValues<IMerchant, IStoreVisits>(allMerchants, this.merchantsWithVisits, 'ID');
        const storeIds = uniqueMerchants.map(merchant => merchant.ID);

        try {
            let storeVisits: IStoreVisits[] = [];
            let requests = [];

            while (!!storeIds.length) {
                const data = {
                    from: obj.from,
                    to: obj.to,
                    storeIds: storeIds.splice(0, 500)
                };
                requests.push(request('/analytics/insights/location-visits/by-id', 'post', options, JSON.stringify(data)));
            }

            const response = await Promise.all(requests);
            storeVisits = response.flat();
            storeVisits = utils.ArrayUniqueValues<IStoreVisits, IMerchant>(storeVisits, this.merchantsWithVisits, 'ID');
            storeVisits = storeVisits.filter(sv => sv.numberOfVisits > 0);

            for (const visit of storeVisits) {
                const findMerchant = allMerchants.find(merchant => merchant.ID === visit.ID);
                const merchantWithVisit = Object.assign(findMerchant, visit);
                this.merchantsWithVisits.push(merchantWithVisit);
            }

            this.filterItems();
        } catch (error) {
            // modalStore.showError(error);
        } finally {
            this.reduxActions.update();
        }
    }

    async loadCategories(): Promise<void> {

        try {
            const query = `${encodeURIComponent('type')}=${encodeURIComponent('merchants')}`;
            const categories: IDictionary[] = await request(`/aggdata/categories?${query}`, 'get');

            Object.keys(categories).map(key => {
                this.categoryDropdown.push({
                    id: key,
                    value: categories[key],
                    checked: true
                });
            });
            this.categoryDropdown.sort((a, b) => a.value.localeCompare(b.value));
        } catch (error) {
            //
        } finally {
            this.reduxActions.update();
        }
    }

    allSelectionChanged(type: 'all' | 'none'): void {
        for (const category of this.categoryDropdown) {
            category.checked = type === 'all';
        }
        this.filterItems();
        this.reduxActions.update();
    }

    handleDropdownChange(type: 'category', value: string) {

        if (type === 'category') {
            const foundIndex = this.categoryDropdown.findIndex(mcd => mcd.value === value);
            this.categoryDropdown[foundIndex].checked = !this.categoryDropdown[foundIndex].checked;
        }

        this.filterItems();
        this.reduxActions.update();
    }

    async filterItems(): Promise<void> {
        let tempMerchants = [].concat(this.merchantsWithVisits) as IMerchant[];

        if (!!this.categoryDropdown.length) {
            const filters: string[] = this.categoryDropdown.map(mcd => mcd.checked ? mcd.value : undefined);
            tempMerchants = tempMerchants.filter(m => m.category ? filters.some(t => m.category.includes(t)) : false);
        }

        this.merchantsWithVisitsFiltered = tempMerchants;
        this.reduxActions.update();
    }

}
