import * as React from 'react';

import './OverlayLoader.scss';

export interface Props {
    className?: string;
    isLoading?: boolean;
}

const OverlayLoader: React.SFC<Props> = (props) => {
    if (props.isLoading) {
        return (
            <div className='overlay-loader-center'>
                <div className='overlay-loader-ring'><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
    return (<span></span>);
};

export default OverlayLoader;
