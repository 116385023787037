import * as React from 'react';
import cn from 'classnames';


interface Props {
    className?: string;
    children: React.ReactNode;

    onHandleClickOutside?(): void;
}

export class OutsideClickHandler extends React.Component<Props, {}> {
    childRef: React.RefObject<HTMLDivElement> = null;

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.childRef && !this.childRef.current.contains(event.target)) {
            if (this.props.onHandleClickOutside)
                this.props.onHandleClickOutside();
        }
    }

    render() {
        const {className, children} = this.props;

        return <div className={cn('OutsideClickHandler', className)} ref={this.childRef}>
            {children}
        </div>;
    }

}

export default OutsideClickHandler;
