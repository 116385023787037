import React, { Component } from 'react';
import { ClickEventValue, Coords, ChangeEventValue, Bounds } from 'google-map-react';

import AdvertisersServiceAPI, { IAdvertiser, ICoords, IAddLocation } from '../../api/AdvertisersServiceAPI';

import { GoogleMapShowEnum } from '../GoogleMap/GoogleMap';
import Button from '../Layout/Button/Button';
import MapPin from '../Layout/Map/MapPin';
import Marker from '../Layout/Map/Marker';

import modalStore from '../../redux/stores/ModalStore';
import { IButton } from '../../typings/src/modal/IModal';

import '../Form/Form.sass';
import GoogleMapWithUrl from '../GoogleMap/GoogleMapWithUrl';
import GoogleMapTooltip from '../GoogleMap/GoogleMapTooltip';
import googleMapUtils, { ICluster, IScale } from '../GoogleMap/GoogleMapUtils';
import MapMarker from '../Layout/Map/MapMarker';

import ReactRouterNavigationPrompt from 'react-router-navigation-prompt';

export interface State {
    isUpdating: boolean;
    advertiser: IAdvertiser;
    error: Error;
    mapMarker: Coords;
    clusters: ICluster<ICoords>[];
    tooltip: ICluster<ICoords>;
    scale: IScale;
    bounds: Bounds;
    zoom: number;
}

interface Props {
    id: number;
    advertiser: IAdvertiser;
}

export default class AdvertisersLocations extends Component<Props, State> {
    googleMapRef: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.state = {
            isUpdating: false,
            advertiser: null,
            error: null,
            mapMarker: null,
            clusters: [],
            scale: null,
            zoom: null,
            bounds: null,
            tooltip: null
        };
    }

    async componentDidMount() {
        this.setState({ advertiser: this.props.advertiser });
    }

    async loadAdvertiser(): Promise<void> {
        try {
            const advertiser = await AdvertisersServiceAPI.getAdvertiser(this.props.id);
            this.setState({ advertiser });
        } catch (error) {
            modalStore.showError(error);
        }
    }

    async addAdvertiserLocationSubmit(marker: Coords) {
        this.setState({ isUpdating: true, error: null });

        const location = {
            advertiserId: this.state.advertiser.id,
            latitude: marker.lat,
            longitude: marker.lng
        } as IAddLocation;

        try {
            await AdvertisersServiceAPI.addAdvertiserLocation(location);
            await this.loadAdvertiser();
            this.generateCluster();
        } catch (error) {
            modalStore.showError(error);
        } finally {
            this.setState({ isUpdating: false, mapMarker: null });
        }
    }

    async removeLocation(advertiserId: number) {
        this.setState({ isUpdating: true, error: null, mapMarker: null });

        try {
            await AdvertisersServiceAPI.removeLocation(advertiserId);
            const advertiser = await AdvertisersServiceAPI.getAdvertiser(this.props.id);
            this.setState({ advertiser, tooltip: null });
            this.generateCluster();
        } catch (error) {
            modalStore.showError(error);
        } finally {
            this.setState({ isUpdating: false });
        }
    }

    generateCluster(): void {
        const { advertiser } = this.state;
        const { zoom, bounds } = this.state;
        const locations: Coords[] = advertiser.locations.map((item) => { return { lat: Number(item.latitude), lng: Number(item.longitude), id: item.id }; });
        let { clusters, scale } = googleMapUtils.getClusterPoints<ICoords>(locations, { bounds, zoom });
        this.setState({ clusters, scale });
    }

    onMapClicked(e: ClickEventValue): void {
        const mapMarker = { lat: e.lat, lng: e.lng };
        this.setState({ mapMarker });
    }

    onMapChange(e: ChangeEventValue) {
        if (this.state.zoom !== e.zoom) {
            this.setState({ tooltip: null });
        }
        const { zoom, bounds } = e;
        this.setState({ zoom, bounds });
        this.generateCluster();
    }

    zoomToClusterBreakingPoint(item: ICluster<ICoords>, index: number): void {
        let { zoom, bounds } = this.state;
        const data = googleMapUtils.zoomToClusterBreakingPoint(item, index, zoom, bounds);
        this.googleMapRef.current.map_.panTo(data.coords);
        this.googleMapRef.current.map_.setZoom(data.zoom);
    }

    openTooltip(mapMarker: ICluster<ICoords> ): void {
        this.googleMapRef.current.map_.panTo({ lat: mapMarker.y, lng: mapMarker.x });
        this.setState({ tooltip: mapMarker });
    }

    areChangesMade() {
        return this.state.mapMarker !== null;
    }

    render() {
        const { tooltip, scale, clusters, isUpdating, advertiser, error, mapMarker } = this.state;

        const modalButtons: IButton[] = [
            { type: 'hollow', text: 'CANCEL' },
            { type: 'primary', text: 'LEAVE' }];

        return (
            <div>
                <ReactRouterNavigationPrompt when={this.areChangesMade()}>
                    {({ isActive, onConfirm, onCancel }) => {
                        if (isActive) {
                            modalStore.customDialog(
                                'You have unsaved changes',
                                'Are you sure you want to leave? All your changes will be lost.',
                                (btnIndex) => {
                                    if (btnIndex === 1) {
                                        onConfirm();
                                    } else {
                                        onCancel();
                                    }
                                },
                                modalButtons
                            );
                        }
                        return <></>;
                    }}
                </ReactRouterNavigationPrompt>
                {advertiser &&
                    <div className={`form-group row form-default`}>
                        <label className={`col-sm-2 col-mt-0`}>Locations</label>
                        <div className='col-sm-10'>
                            <GoogleMapWithUrl
                                onClick={(event: ClickEventValue) => this.onMapClicked(event)}
                                onChange={(event: ChangeEventValue) => this.onMapChange(event)}
                                mounted={(ref) => this.googleMapRef = ref}
                            >
                                {clusters && !!clusters.length && clusters.map((location, index) =>
                                    location.numPoints > 1 ?
                                        <MapMarker
                                            key={`${location.y}-${location.x}-${location.numPoints}`}
                                            className='cursor-pointer'
                                            lat={location.y}
                                            lng={location.x}
                                            scale={scale}
                                            numPoints={location.numPoints}
                                            onClick={() => this.zoomToClusterBreakingPoint(location, index)}
                                        />
                                    :
                                        <Marker
                                            mapOptions={{ show: GoogleMapShowEnum.ZOOM }}
                                            key={index}
                                            lat={location.y}
                                            lng={location.x}
                                            imageUrl={advertiser.imageUrl}
                                            toogleChildren='toogle'
                                            onTooltipOpen={() => this.openTooltip(location)}
                                        />
                                )}

                                {mapMarker &&
                                    <MapPin
                                        key='map_marker_pin'
                                        isUpdating={isUpdating}
                                        lat={mapMarker.lat}
                                        lng={mapMarker.lng}
                                        onPress={() => this.addAdvertiserLocationSubmit(mapMarker)}
                                    />
                                }

                                {tooltip &&
                                    <GoogleMapTooltip
                                        lat={tooltip.y}
                                        lng={tooltip.x}
                                        onClose={() => this.setState({ tooltip: null })}
                                    >
                                        <table className='Table table table-list table-map'>
                                            <thead>
                                                <tr className='headerRow'>
                                                    <th>Coordinates</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Latitude: {tooltip.y}<br />
                                                        Longitude: {tooltip.x}
                                                    </td>
                                                    <td>
                                                        <Button type='danger' onFetchDisabled onClick={async () => await this.removeLocation(tooltip.points[0].item.id)}>DELETE</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </GoogleMapTooltip>
                                }

                            </GoogleMapWithUrl>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
