import React, { Component } from 'react';
import { IErrorDialog } from '../../../../typings/src/modal/IModal';
import Button from '../../Button/Button';
import ErrorMessage from '../../../../components/common/ErrorMessage';

interface Props {
    dialog: IErrorDialog;
    onClick: () => void;
}

export default class ErrorDialog extends Component<Props> {
    render() {
        const { dialog } = this.props;

        return (
            <>
                <div className='modal-header'>
                    <h3>{dialog.title}</h3>
                </div>

                <div className='modal-body'>
                    <ErrorMessage error={dialog.error} />
                </div>

                <div className='modal-footer'>
                    {dialog.button &&
                        <Button
                            type={dialog.button.type ? dialog.button.type : 'primary'}
                            onClick={() => this.props.onClick()}
                        >
                            {dialog.button.text}
                        </Button>
                    }
                </div>
            </>
        );
    }
}
