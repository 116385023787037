import * as React from 'react';
import SidebarItem from './SidebarItem';
import cn from 'classnames';

import './ClickableSidebarItem.sass';


interface IProps {
    className?: string;

    onClick?(event);
}

export class ClickableSidebarItem extends React.Component<IProps> {
    handleMouseOver = (event) => {
        event.preventDefault();
        if (this.props.onClick)
            this.props.onClick(event);
    }

    render() {
        const {className} = this.props;

        return <SidebarItem className={cn('ClickableSidebarItem', className)}>
            <a href='#' className='anchor'
               onClick={this.handleMouseOver}>
                {this.props.children}
            </a>
        </SidebarItem>;
    }
}

export default ClickableSidebarItem;
