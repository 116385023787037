/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';

export interface Reward {
    link: string;
    redeemed: boolean;
    redeemedOn: string | null;
    uniqueIdentifier: string;
    rewardValue: number;
    account: string;
}

export interface RewardStatstic {
    totalCreated: number;
    totalRedeemed: number;
    perValue: {
        value: number;
        created: number;
        redeemed: number;
    }[];
}

export interface PartnerStatstic {
    brand: string;
    count: number;
}

export default class RewardsAPI {
    static async getAccounts(): Promise<string[]> {
        let url = `/giftcard/rewards/accounts`;
        return await request(url, 'get');
    }

    static async getRewards(account: string, uniqueIdentifier?: string): Promise<Reward[]> {
        let parameters = [];
        account && account !== 'all' ? parameters.push(`accountId=${account}`) : account = undefined;
        uniqueIdentifier ? parameters.push(`uniqueIdentifier=${uniqueIdentifier}`) : uniqueIdentifier = undefined;

        let url = `/giftcard/rewards/list`;
        url += parameters.length ? '?' + parameters.join('&') : '';
        return await request(url, 'get');
    }

    static async getStatistics(account: string, from?: string, to?: string): Promise<RewardStatstic> {
        let parameters = [];
        account && account !== 'all' ? parameters.push(`accountId=${account}`) : account = undefined;
        from ? parameters.push(`from=${from}`) : from = undefined;
        to ? parameters.push(`to=${to}`) : to = undefined;

        let url = `/giftcard/rewards/statistics`;
        url += parameters.length ? '?' + parameters.join('&') : '';
        return await request(url, 'get');
    }

    static async getPartnersStatistics(account: string, from?: string, to?: string): Promise<PartnerStatstic[]> {
        let parameters = [];
        account && account !== 'all' ? parameters.push(`accountId=${account}`) : account = undefined;
        from ? parameters.push(`from=${from}`) : from = undefined;
        to ? parameters.push(`to=${to}`) : to = undefined;

        let url = `/giftcard/rewards/statistics/by-partner`;
        url += parameters.length ? '?' + parameters.join('&') : '';
        return await request(url, 'get');
    }
}
