import React, { Component } from 'react';
import * as H from 'history';
import cn from 'classnames';

import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import Content from '../Content/Content';
import Header from '../Content/Header/Header';
import Paper from '../Layout/Paper';
import Button from '../Layout/Button/Button';
import SidebarItemIcon from '../Sidebar/SidebarItem/SidebarItemIcon';
import Space from '../Layout/Space';
import Divider from '../Layout/Divider';
import AdvertisersStore from '../../redux/stores/Advertisers/AdvertisersStore';
import ListWithPagination from '../List/ListStatusWithPagination/ListStatusWithPagination';
import ListStatus from '../List/ListStatus';
import Filter from '../List/Filter';

import '../Form/Form.sass';
import './AdvertisersList.sass';
import '../Layout/Table/Table.sass';
import 'components/Layout/NavLink/NavLink.sass';

import Utils from '../../utils';
import { IPageParams } from '../../redux/stores/ListStoreWithPagination';

export interface Props {
    history: H.History;
}

class AdvertisersList extends Component<Props & RouteComponentProps<{}>> {
    store = new AdvertisersStore();

    async componentDidMount(): Promise<void> {
        const params = Utils.searchToParams(this.props.location.search) as IPageParams;
        await this.store.load(params);
    }

    componentWillReceiveProps(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.store.loadFromSearch();
        }
    }

    async load(): Promise<void> {
        await this.store.load();
    }

    render() {
        const { items } = this.store;

        const header = <Header>
            <h1>Advertisers</h1>

            <Space />

            <Button className='Header-button' type='primary' onClick={() => this.load()}>Update</Button>
            <Divider />
            <NavLink className='Navlink primary' to={`advertisers/0/edit`}>+ New</NavLink>
        </Header>;

        return (
            <Content header={header} className={cn('AdvertisersList')}>

                <Paper className='Content-paper' type='wide'>
                    <ListWithPagination store={this.store} history={this.props.history} />

                    <table className='Table table table-list table-fixed'>
                        <thead>
                            <tr className='headerRow'>
                                <th>Name</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th><Filter field='name' store={this.store} /></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items && !!items.length && items.map(advertiser =>
                                <tr key={advertiser.id}>
                                    <td>{advertiser.name}</td>
                                    <td>{advertiser.description}</td>
                                    <td className='td-buttons'>
                                        <NavLink className='Navlink primary' to={`advertisers/${advertiser.id}/edit`}>Edit</NavLink>
                                        <Button type='icon' onClick={() => this.store.delete(advertiser.id)}>
                                            <SidebarItemIcon src='../../../assets/icons/close.svg' />
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <ListStatus store={this.store} emptyText='advertisers' />

                </Paper>
            </Content>
        );
    }
}

const mapStateToProps = state => ({
    advertisers: state.advertisers
});

export default connect(mapStateToProps)(AdvertisersList);
