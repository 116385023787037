import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import cn from 'classnames';

import Sidebar from './Sidebar';
import ClickableSidebarItem from 'components/Sidebar/SidebarItem/ClickableSidebarItem';
// import UserBadgeSidebarItem from 'components/Sidebar/SidebarItem/UserBadgeSidebarItem';
import Space from 'components/Layout/Space';
import SidebarItemIcon from 'components/Sidebar/SidebarItem/SidebarItemIcon';
import { ConfigManager } from '../../api/GetConfigAPI';
import { Environment, PageComponent } from 'components/Environment/Environments';

import authService from '../../services/authService';

import './GeneralSidebar.sass';


export interface IProps extends RouteComponentProps<any> {
    className?: string;

    onItemClick?(): void;
}

export class GeneralSidebar extends React.PureComponent<IProps> {
    handleItemClick(e, item: PageComponent) {
        e.preventDefault();
        this.props.history.push(item.path);
    }

    render() {
        const { className } = this.props;
        let env: Environment = ConfigManager.getEnvironment();

        let pages: PageComponent[] = [...env.pages];

        let items = pages.map((item) => {
            if (item.name === 'space') {
                return <Space/>;
            }
            return <ClickableSidebarItem key={item.type} onClick={(e) => this.handleItemClick(e, item)}>
                <SidebarItemIcon src={`/assets/icons/${item.type}.svg`}/>
                <span className='itemText'>{item.name}</span>
            </ClickableSidebarItem>;
        });

        items.push(
            <ClickableSidebarItem key='logout' onClick={(e) => authService.logout()}>
                <SidebarItemIcon src={`/assets/icons/logout.svg`} />
                <span className='itemText'>Logout</span>
            </ClickableSidebarItem>
        );

        // const userBadgeItemIcon = <SidebarItemIcon src='/assets/icons/account.svg'/>;
        // const userBadgeItem = <UserBadgeSidebarItem key='userBadge' icon={userBadgeItemIcon}/>;
        // items.splice(items.length - 1, 0, userBadgeItem);


        return <Sidebar className={cn('GeneralSidebar', className)}
                        onLogoClick={(e) => this.handleItemClick(e, {path: '/', type: 'logo', name: 'Logo'})}>
            {items}
        </Sidebar>;
    }
}

export default GeneralSidebar;
