/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import * as React from 'react';
import { hydrate, render } from 'react-dom';
import AppRouter from 'components/React/AppRouter';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

// Redux Middleware
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// Root reducer
import rootReducer from './redux/reducers';

const loggerMiddleware = createLogger();
const initialState = {};
const middleware = [thunk];

const renderApp = (app) => {
    const root = document.getElementById('root');

    // render or hydrate depending on SSR being enabled or not
    const method = root.children.length === 0 ? render : hydrate;

    method.call(this, app, root);
};

const store = createStore(
    rootReducer,
    initialState,
    // applyMiddleware(...middleware, loggerMiddleware)
);

export { store };

window.onload = () => {
    renderApp(
        <Provider store={store as any}>
            <AppRouter />
        </Provider>
    );
};

// hot swapping with WDS
if (module.hot) {
    module.hot.accept('components/React/AppRouter', () => {
        const NewAppRouter = require('components/React/AppRouter').default;
        renderApp(
            <Provider store={store as any}>
                <NewAppRouter/>
            </Provider>

        );
    });
}
