/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';

export interface IAddAdvertiser {
    name: string;
    description: string;
}

export interface ICoords {
    id: number;
    latitude: number;
    longitude: number;
}

export interface IAddLocation {
    advertiserId: number;
    latitude: number;
    longitude: number;
}

export interface IAdvertiser {
    id: number;
    name: string;
    description: string;
    imageUrl: string;
    locations?: ICoords[];
    // advertiser?: string;
    // owner: string;
}

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};

export default class AdvertisersServiceAPI {
    static async getAdvertisers(): Promise<IAdvertiser[]> {
        let url = '/radio/advertiser/list';
        return await request(url, 'get');
    }

    static async getAdvertiser(id: number): Promise<IAdvertiser> {
        let url = `/radio/advertiser/${id}`;
        return await request(url, 'get');
    }

    static async updateOrAddAdvertiser(advertiser: IAdvertiser): Promise<any> {
        if (advertiser.id) {
            return await this.updateAdvertiser(advertiser);
        }
        return await this.addAdvertiser(advertiser);
    }

    static async updateAdvertiser(advertiser: IAdvertiser): Promise<any> {
        let url = `/radio/advertiser/${advertiser.id}`;
        return await request(url, 'put', options, JSON.stringify(advertiser));
    }

    static async addAdvertiser(advertiser: IAdvertiser): Promise<any> {
        let url = `/radio/advertiser`;
        return await request(url, 'post', options, JSON.stringify(advertiser));
    }

    static async deleteAdvertiser(id: number): Promise<any> {
        let url = `/radio/advertiser/${id}`;
        return await request(url, 'delete');
    }

    static async addAdvertiserLocation(data: IAddLocation): Promise<any> {
        let url = '/radio/advertiser/location';
        return await request(url, 'post', options, JSON.stringify(data));
    }

    static async removeLocation(id: number): Promise<any> {
        let url = `/radio/advertiser/location/${id}`;
        return await request(url, 'delete');
    }
}
