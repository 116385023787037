import { request } from './RequestAPI';

import { IDataDictionary } from '../typings/src/dataDictionary/IDataDictionary';

export default class DataDictionaryAPI {
    static async getDataDictionary (serviceId: string): Promise<IDataDictionary[]> {
        const url = `/${serviceId}/schemas`;
        return await request(url, 'get');
    }
}
