import * as React from 'react';
import cn from 'classnames';

import './CollapsableTableRow.scss';
import Paper from '../../Paper';
import ReactDOM from 'react-dom';

interface CollapsableRowProps {
    colspan: number;
    shouldRender?: boolean;

    className?: string;
}

export default class CollapsableTableRow extends React.Component<CollapsableRowProps> {

    render() {
        const { children, shouldRender, className } = this.props;


        return (
            <tr
                className={cn(className)}
            >
                <td colSpan={this.props.colspan} style={{ padding: 0, border: 0 }}>
                    <Paper type='wide' >
                        {shouldRender === true &&
                            children
                        }
                    </Paper>
                </td>

            </tr>
        );
    }
}

