import * as React from 'react';
import { IDataDictionary } from '../../typings/src/dataDictionary/IDataDictionary';
import './data-dictionary-styles.scss';

interface IProps {
    item: IDataDictionary;
}

const DataDictionaryItem: React.FC<IProps> = (props) => {
    const { item } = props;
    const { title, properties } = item;

    function expand(prop, prefix, depth, id, parent) {
        const parentPrivacy = parent ? parent['x-privacy'] : null;
        const ret = Object.keys(prop).map(key => {
            const name = prefix +  key;
            const keyName = id + '.' + key;
            const entry = prop[key];
            const privacy = entry['x-privacy'] || parentPrivacy;
            if (entry.type === 'object') {
                const newParent = { name, entry, depth, key: keyName, privacy};
                const children = expand(entry.properties, ' - ', depth + 1, keyName, entry);
                return [newParent].concat(children);
            }
            return  { name, privacy, entry, depth, key: keyName, parent };
        });
        return ret.flat();
    }

    function privacyToText(entry) {
        return entry.privacy ? entry.privacy : 'default';
    }

    const itemProps = expand(properties.properties, '', 0, title, null);
    if (!itemProps.length) {
        return null;
    }

    const first = itemProps.shift();
    const firstRow = (<tr key={first.key} className={'dd-named-attribute-row'}>
        <td className='table-name'>{title}</td>
        <td>{first.name}</td>
        <td>{ first.entry.type} </td>
        <td>{ privacyToText(first)} </td>
        <td>{ first.entry.description} </td>
    </tr>);

    return <>
        {[firstRow].concat(
            itemProps.map(prop => {
                let styles = {
                    'paddingLeft': 0.3 + prop.depth + 'rem'
                };
                const ret =  <tr key={prop.key} className={'dd-attribute-row'}>
                    <td/>
                    <td style={styles}>{ prop.name }</td>
                    <td>{ prop.entry.type} </td>
                    <td>{ privacyToText(prop)} </td>
                    <td>{ prop.entry.description} </td>
                </tr>;
                return ret;
            })
        )}
    </>;
};

export default DataDictionaryItem;
