import { ListStore } from '../ListStore';
import RadioServiceAPI, { LogStation, LogStreamInfo } from '../../../api/RadioServiceAPI';
import utils from '../../../utils';

export class ProgramLogsStore extends ListStore<LogStreamInfo> {
    stations: LogStation[] = [];
    selectedStation: LogStation;

    constructor() {
        super('/radio', '/logs', 'programLogs');
        this.preload();
    }

    async getStations(): Promise<void> {
        this.isLoading = true;
        this.error = null;
        this.reduxActions.update();

        try {
            this.stations = await RadioServiceAPI.getLogStations();
            this.reduxActions.setCustom('stations', this.stations);
        } catch (error) {
            this.error = error;
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    async afterInitParams(): Promise<void> {
        let filterObj = utils.searchToParams(this.params.filter, false);

        if (!!this.stations.length) {
            filterObj = Object.assign(
                {
                    station: this.stations[0].id
                },
                filterObj);
        }

        this.params = Object.assign(
            {
                filter: utils.paramsToPath('', filterObj).substring(1)
            },
            this.params);
    }

}
