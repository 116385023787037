import * as React from 'react';
import cn from 'classnames';

import Logo from 'components/Sidebar/Logo';
import './Sidebar.scss';


interface IProps {
    className: string;

    onLogoClick?(e);
    onOutsideClick?(e);
}

const Sidebar: React.SFC<IProps> = (props) => {
    const {className, onLogoClick} = props;

    return (
        <aside className={cn('Sidebar', className)}>
            <Logo onClick={onLogoClick}/>
            {props.children}
        </aside>
    );
};

export default Sidebar;
