import * as React from 'react';
import cn from 'classnames';
import * as d3 from 'd3';
import './LineChartGrid.sass';

interface Props {
    className?: string;

    grid: any;
}

interface State {
    gridRef: React.RefObject<SVGGElement>;
}

export default class LineChartGrid extends React.Component<Props, State> {
    state: State = {
        gridRef: null,
    };

    constructor(props) {
        super(props);

        this.state.gridRef = React.createRef();
    }

    componentDidUpdate() {
        this.renderGrid();
    }

    componentDidMount() {
        this.renderGrid();
    }

    renderGrid() {
        const {grid} = this.props;
        const {gridRef} = this.state;

        d3.select(gridRef.current).call(grid);
    }


    render() {
        const {className} = this.props;
        const {gridRef} = this.state;

        return (
            <g className={cn('LineChartGrid', className)} ref={gridRef}>
            </g>
        );
    }
}
