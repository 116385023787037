import * as React from 'react';
import cn from 'classnames';

import Content from 'components/Content/Content';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import Button from 'components/Layout/Button/Button';
import UpperText from 'components/Layout/UpperText/UpperText';
import Headline from 'components/Content/Headline';
import DonutChart from 'components/Layout/DonutChart/DonutChart';
import ExpandableDropdown from 'components/Layout/Dropdown/ExpandableDropdown';
import CampaignAttributionStatsBox from 'components/CampaignAttributionPage/CampaignAttributionStatsBox';
import BulletListLegend from 'components/Layout/Legend/BulletListLegend';
import Space from 'components/Layout/Space';
import './CampaignAttributionPage.sass';
import BarChart from 'components/Layout/BarChart/BarChart';
import CampaignAttributionMap from 'components/CampaignAttributionPage/CampaignAttributionMap/CampaignAttributionMap';

const CAMPGAIN_ATTRIBUTION_DATE_RANGE = 'CAMPGAIN_ATTRIBUTION_DATE_RANGE';

const donutDummyData = [
    {value: 50, color: '#b2cae5', label: 'Home'},
    {value: 30, color: '#0a245f', label: 'Work'},
    {value: 30, color: '#2659cd', label: 'Shopping'},
    {value: 10, color: '#4a90e2', label: 'Restaurant'},
    {value: 60, color: '#6682c1', label: 'Gas Station some very very long text'},
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let timeOptions = [];
let date = new Date();
date.setUTCDate(1);
for (let i = 1; i <= 12; i++) {
    timeOptions.push({
        id: date.getUTCMonth() + 1,
        value: `${months[date.getUTCMonth()]} ${date.getFullYear()}`,
        date: new Date(date)
    });
    date.setUTCMonth(date.getUTCMonth() - 1);
}

interface Props {
    className?: string;
}

interface State {
    view: any;
}

export default class LocationInsightsPage extends React.Component<Props, State> {
    state: State = {
        view: timeOptions[0]
    };

    constructor(props) {
        super(props);
        let viewId = localStorage.getItem(CAMPGAIN_ATTRIBUTION_DATE_RANGE);
        if (viewId) {
            let view = timeOptions.find(c => c.id === Number(viewId));
            this.state.view = view;
        }
    }

    async updateView(id) {
        if (id) {
            localStorage.setItem(CAMPGAIN_ATTRIBUTION_DATE_RANGE, id);
            await this.setState({ view: timeOptions.find(c => c.id === id) });
        }
    }

    render() {
        const header = <Header>
            <h1>Campaign Attribution</h1>

            <Space/>

            {/*<UpperText className='Header-upperText' text='Station'>
                <ExpandableDropdown text='KKLV'/>
            </UpperText>

            <UpperText className='Header-upperText' text='Advertiser'>
                <ExpandableDropdown text='The Home Depot'/>
            </UpperText>

            <UpperText className='Header-upperText' text='Advertisement'>
                <ExpandableDropdown text='Give your partition'/>
            </UpperText>*/}

            {/* <UpperText className='Header-upperText' text='Date Range'>
                <ExpandableDropdown text={this.state.view.value} data={timeOptions} onDataChangeClick={this.updateView.bind(this)}/>
            </UpperText> */}

            {/* <Button className='Header-button' type='primary' onClick={async() => await this.updateView(this.state.view.id)}>Update</Button> */}
        </Header>;

        const statsBoxData = [
            [
                {label: 'Total Actions', value: '33,422,859'},
                {label: 'CPA', value: '$2.81'},
                {label: 'CPV', value: '$0.79'},
            ],
            [
                {label: 'Total Clicks', value: '80,812,532'},
                {label: 'CPC', value: '$3.13'},
            ],
            [
                {label: 'Total Impressions', value: '100,812,532'},
                {label: 'CPM', value: '$2.94'},
            ],
        ];

        const progressBarData = [
            {
                total: 100,
                values: [
                    {id: 1, value: 10, colors: ['#18ab6b', '#0a7239']},
                    {id: 2, value: 20, colors: ['#41aa55', '#4dc464']},
                    {id: 3, value: 35, colors: ['#90f1aa', '#57df71']},
                ]
            },
            {
                total: 100,
                values: [
                    {id: 1, value: 80, colors: ['#3ce5f1', '#1cc7de']},
                ]
            },
            {
                total: 100,
                values: [
                    {id: 1, value: 100, colors: ['#964fe8', '#5d27cc']},
                ]
            },
        ];

        return (
            <Content className={cn('CampaignAttributionPage')} header={header}>
                {/*<UpperText text='Behavioural Insight'>
                    <ExpandableDropdown text='Drives to work +3'/>
                </UpperText>

                <div className='CampaignAttributionPage-statsBoxContainer'>
                    <CampaignAttributionStatsBox className='CampaignAttributionPage-statsBox Content-paper'
                                                headline='Actions'
                                                textData={statsBoxData[0]}
                                                progressBarData={progressBarData[0]}/>
                    <CampaignAttributionStatsBox className='CampaignAttributionPage-statsBox Content-paper'
                                                headline='Clicks'
                                                textData={statsBoxData[1]}
                                                progressBarData={progressBarData[1]}/>
                    <CampaignAttributionStatsBox className='CampaignAttributionPage-statsBox Content-paper'
                                                headline='Impressions'
                                                textData={statsBoxData[2]}
                                                progressBarData={progressBarData[2]}/>
                </div>

                <Paper className='Content-paper' type='wide'>
                    <Headline>Daily: March 2018</Headline>

                    <BarChart/>
                </Paper>*/}

                <Paper className='Content-paper' type='wide'>
                    <Headline>Location visits</Headline>

                    <CampaignAttributionMap />
                </Paper>

                {/*<Paper className='Content-paper' type='wide'>
                    <Headline>Conversions: Impressions to Store Visits</Headline>

                    <div className='CampaignAttributionPage-donutContainer'>
                        <article className='CampaignAttributionPage-donutItem'>
                            <h1>Food & Beverage</h1>
                            <DonutChart data={donutDummyData}/>
                            <BulletListLegend data={donutDummyData}/>
                            <Button type='hollow'>Details</Button>
                        </article>
                        <article className='CampaignAttributionPage-donutItem'>
                            <h1>Food & Beverage</h1>
                            <DonutChart data={donutDummyData}/>
                            <BulletListLegend data={donutDummyData}/>
                            <Button type='hollow'>Details</Button>
                        </article>
                    </div>

                </Paper>

                <ExpandableDropdown text='Download' direction='up'/>*/}
            </Content>
        );
    }
}
