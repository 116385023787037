import * as React from 'react';
import cn from 'classnames';

import './SidebarItem.sass';


export interface IProps {
    className?: string;
}

const SidebarItem: React.SFC<IProps> = (props) => {
    const {className} = props;

    return (
        <div className={cn('SidebarItem', className)}>
            {props.children}
        </div>
    );
};

export default SidebarItem;
