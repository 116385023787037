import React, { Component } from 'react';
import PageLoader from '../Layout/PageLoader/PageLoader';
import ErrorMessage from '../common/ErrorMessage';
import ModalLoader from '../Layout/PageLoader/ModalLoader';

import './DetailsStatus.sass';

interface ListStatusProps {
    store: any;
    isUpdating: boolean;
    error: Error;
}

export default class DetailsStatus extends Component<ListStatusProps> {

    doesItemExists(): boolean {
        return !!(this.props.store);
    }

    render() {
        return (
            <div className={`details-status ${this.props.isUpdating ? 'list-loader ' : ''}`}>
                {this.props.isUpdating && <PageLoader />}
                {this.props.error && this.props.error.message && <ErrorMessage error={this.props.error} />}
            </div>
        );
    }
}
