import * as React from 'react';
import * as momenttz from 'moment-timezone';

import { DropdownItem } from '../Dropdown/Dropdown';
import ExpandableDropdown from '../Dropdown/ExpandableDropdown';

interface Props {
    timezone: string;
    onTimezoneSelected(id: string): void;
}

export default class TimezonePicker extends React.Component<Props> {
    timezones: DropdownItem[] = [];

    componentWillMount () {
        const currentTime = new Date();
        this.timezones = momenttz.tz.names().map(tz => {
            const offset = -momenttz.tz.zone(tz).utcOffset(currentTime);
            return {
                id: tz,
                value: `${this.getTimezoneOffsetFormatted(offset)} ${tz}`
            };
        }).sort((tz1, tz2) => tz1.offset - tz2.offset);
    }

    getTimezoneOffsetFormatted (minutes) {
        const prefix = minutes < 0 ? '-' : '+';
        minutes = Math.abs(minutes);
        const duration = momenttz.duration(minutes, 'minutes');
        const hoursFormat = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();
        const minutesFormat = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
        return `(${prefix}${hoursFormat}:${minutesFormat})`;
    }

    render () {
        const selectedTimezone = this.timezones.find(tz => tz.id === this.props.timezone);
        return (
            <ExpandableDropdown
                text={selectedTimezone.value}
                data={this.timezones}
                filter={true}
                onDataChangeClick={(id) => this.props.onTimezoneSelected(id)}
            />
        );
    }
}
