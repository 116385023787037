/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import * as React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import { withRouter } from 'react-router-dom';
import { ConfigManager } from '../../api/GetConfigAPI';
import { Environment } from 'components/Environment/Environments';

import App from 'components/App';
import ProtectedRoute from 'components/Auth/ProtectedRoute';
import Login from 'components/Auth/Login';
import EnvPage from 'components/Environment/EnvPage';
import NotFound from 'components/Layout/NotFound/NotFound';

// import AdManagementPage from 'components/AdManagementPage/AdManagementPage';
// import MarketReportPage from 'components/MarketReportPage/MarketReportPage';
// import RealTimePerformancePage from 'components/realTimePerformancePage/RealTimePerformancePage';
// import EditAdAudiencePage from 'components/AdManagementPage/EditAd/EditAdAudiencePage';
import ProgramLogsPage from 'components/ProgramLogsPage/ProgramLogsPage';
// import EditAdConfigurePage from 'components/AdManagementPage/EditAd/EditAdConfigurePage';
import CampaignAttributionPage from 'components/CampaignAttributionPage/CampaignAttributionPage';
import LocationInsightsPage from 'components/LocationInsightsPage/LocationInsightsPage';
import RewardsList from 'components/RewardsPage/RewardsList';
import RewardsStatistics from 'components/RewardsPage/RewardsStatistics';
import MobileSidebar from 'components/Sidebar/MobileSidebar';

import AdvertisersList from 'components/AdvertisersPage/AdvertisersList';
import AdvertiseTab from 'components/AdvertisersPage/AdvertisersTab';
import TripsStatisticsTab from 'components/TripsStatisticsPage/TripsStatisticsTab';
import ReportsTab from 'components/ReportsPage/ReportsTab/ReportsTab';
import PointsInsights from 'components/PointsInsightsPage/PointsInsights';
import DataDictionary from 'components/DataDictionary/DataDictionary';

import Modal from './Modal/Modal';

import './MainPage.scss';

const pageTypes = {
    locationInsight: LocationInsightsPage,
    campaignAttribution: CampaignAttributionPage,
    programLogs: ProgramLogsPage,
    rewards: RewardsList,
    rewardsStatistics: RewardsStatistics,
    advertisers: AdvertisersList,
    tripsStatistics: TripsStatisticsTab,
    reports: ReportsTab,
    pointsInsight: PointsInsights,
    dataDictionary: DataDictionary
};

interface Props {
    location: {
        pathname: string;
    };
}

const MainPage = withRouter(props => <MainPage1 {...props}/>);
const MainPage1: React.SFC<Props> = (p) => {
    let env: Environment;
    try {
        env = ConfigManager.getEnvironment();
    } catch (e) {
        let newEnv = localStorage.getItem('newEnv');
        if (newEnv) {
            localStorage.removeItem('newEnv');
            localStorage.setItem('env', newEnv);
        } else if (p.location.pathname.includes('environment')
            && p.location.pathname.includes('login')) {
            return <Redirect to='/environment'/>;
        }
    }

    function isTabRouteExact(pageType: string): boolean {
        const notExactRoutePages = ['tripsStatistics', 'reports', 'campaignAttribution'];
        const found = notExactRoutePages.findIndex(page => page === pageType.trim());
        return found === -1;
    }

    return <>
        <Modal />
        <Switch>
            <Route path='/login' exact component={Login}/>
            <Route path='/environment' exact component={EnvPage}/>
            <Route render={props =>
                <ProtectedRoute {...props}>
                    <Switch>
                        <Route path='/' render={(props1) =>
                            <App className='App'>
                                <MobileSidebar {...props1} />
                                <Switch>
                                    {/* <Route path='/ad-management' render={(props2) =>
                                        <Switch>
                                            <Route path={props2.match.url} exact render={() =>
                                                <AdManagementPage/>
                                            }/>
                                            <Route path={`${props2.match.url}/edit-ad`} render={(props3) =>
                                                <Switch>
                                                    <Route path={`${props3.match.url}/audience`} render={() =>
                                                        <EditAdAudiencePage/>
                                                    }/>
                                                    <Route path={`${props3.match.url}/configure`} render={() =>
                                                        <EditAdConfigurePage/>
                                                    }/>
                                                    <Route component={NotFound}/>
                                                </Switch>
                                            }/>
                                            <Route component={NotFound}/>
                                        </Switch>
                                    }/>
                                    <Route path='/market-report' exact render={() =>
                                        <MarketReportPage/>
                                    }/>
                                    <Route path='/real-time-performance' exact render={() =>
                                        <RealTimePerformancePage/>
                                    }/> */}
                                    { env.pages.map((page, i) => (
                                        <Route key={i} exact={isTabRouteExact(page.type)} path={page.path} component={pageTypes[page.type]}/>
                                    )) }

                                    <Route path='/advertisers/:id' component={AdvertiseTab} />

                                    <Route component={NotFound}/>
                                </Switch>
                            </App>
                        }/>
                    </Switch>
                </ProtectedRoute>
            }/>
        </Switch>
    </>;
};


export default MainPage;
