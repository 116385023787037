import * as React from 'react';
import cn from 'classnames';

import './Headline.sass';


interface Props {
    className?: string;
    children: string;
}

const Headline: React.SFC<Props> = (props) => {
    const {className} = props;
    return (
        <div className={cn('Headline', className)}>
            <h2>{props.children}</h2>
        </div>
    );
};

export default Headline;
