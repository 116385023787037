/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';

export interface LogStation {
    id: string;
    name: string;
    frequency: string;
}

export interface LogStreamInfo {
    artist: string;
    cart: string;
    category: string;
    duration: number;
    id: string;
    mediaType: string;
    scheduled: number;
    stack: number;
    timestamp: string;
    title: string;
    trivia: string;
}

export default class RadioServiceAPI {
    static async getStreamInfo(stationId): Promise<LogStreamInfo[]> {
        let url = `/radio/logs?station=${stationId}`;
        return await request(url, 'get');
    }

    static async getLogStations(): Promise<LogStation[]> {
        let url = `/radio/logs/stations`;
        return await request(url, 'get');
    }
}
