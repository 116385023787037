import * as React from 'react';
import { connect } from 'react-redux';

import DataDictionaryStore from '../../redux/stores/DataDictionary/DataDictionaryStore';

import Content from 'components/Content/Content';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import OverlayLoader from 'components/Layout/OverlayLoader/OverlayLoader';
import DataDictionaryItem from './DataDictionaryItem';

class DataDictionary extends React.Component {
    store = new DataDictionaryStore();

    async componentDidMount() {
        await this.store.loadDataDictionary();
     }

    render () {
        const header = <Header>
            <h1>Data Dictionary</h1>
        </Header>;

        return <Content className='DataDictionary' header={header}>
            <Paper className='Content-paper' type='wide'>
                <OverlayLoader isLoading={ this.store.isLoading } />

                <table className='table-data-dictionary'>
                    <thead>
                        <tr>
                            <th>Table</th>
                            <th>Attribute Name</th>
                            <th>Data type</th>
                            <th>Privacy</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.store.items.map((item, index) => <DataDictionaryItem key={index} item={item} />) }
                    </tbody>
                </table>
            </Paper>
        </Content>;
    }
}

const mapStateToProps = (state) => ({
    dataDictionary: state.dataDictionary
});

export default connect(mapStateToProps)(DataDictionary);
