import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import ModalLoader from '../PageLoader/ModalLoader';
import WideDialog from './Dialogs/WideDialog';
import Dialog from './Dialogs/Dialog';
import DeleteDialog from './Dialogs/DeleteDialog';
import ErrorDialog from './Dialogs/ErrorDialog';

import ModalStore from '../../../redux/stores/ModalStore';

import './Modal.scss';

export interface IModalButton {
    type?: 'primary' | 'hollow' | 'icon' | 'danger';
    text: string;
}

class Modal extends Component {

    getCustomModalClassName(): string {
        if (ModalStore.fullScreenLoadersCount) {
            return 'no-border';
        }
        if (ModalStore.wideDialog) {
            return 'wide';
        }
        return '';
    }

    render() {

        if (!ModalStore.wideDialog && ModalStore.fullScreenLoadersCount <= 0 && !ModalStore.dialog && !ModalStore.deleteDialog && !ModalStore.errorDialog) {
            return null;
        }

        return (
            <div className='modal modal-test' id='page-modal'>
                <div className={cn('modal-container', this.getCustomModalClassName())}>

                    {ModalStore.wideDialog &&
                        <WideDialog
                            title={ModalStore.wideDialog.title}
                            body={ModalStore.wideDialog.body}
                            onClick={() => {
                                ModalStore.wideDialog = null;
                                ModalStore.reduxActions.update();
                            }}
                        />
                    }

                    {ModalStore.fullScreenLoadersCount > 0 &&
                        <ModalLoader />
                    }

                    {ModalStore.dialog &&
                        <Dialog
                            dialog={ModalStore.dialog}
                            onClick={(btnIndex) => {
                                ModalStore.dialog.clickFn(btnIndex);
                                ModalStore.dialog = null;
                                ModalStore.reduxActions.update();
                            }}
                        />
                    }

                    {ModalStore.deleteDialog &&
                        <DeleteDialog
                            dialog={ModalStore.deleteDialog}
                            onClick={(btnIndex) => {
                                ModalStore.deleteDialog.clickFn(btnIndex);
                                ModalStore.deleteDialog = null;
                                ModalStore.reduxActions.update();
                            }}
                        />
                    }

                    {ModalStore.errorDialog &&
                        <ErrorDialog
                            dialog={ModalStore.errorDialog}
                            onClick={() => {
                                ModalStore.errorDialog = null;
                                ModalStore.reduxActions.update();
                            }}
                        />
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    modal: state.modal,
});

export default connect(mapStateToProps)(Modal);
