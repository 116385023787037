/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import {Redirect} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';

import ProtectedRoute from 'components/Auth/ProtectedRoute';


interface IState {
    redirectTo: string;
}

class Login extends React.Component<RouteComponentProps<any>, IState> {
    state: IState = {
        redirectTo: '/'
    };

    constructor(props) {
        super(props);

        let env = localStorage.getItem('tmpEnv');
        localStorage.setItem('env', env);
        localStorage.removeItem('tmpEnv');

        const queryParams = queryString.parse(this.props.location.search);

        try {
            const accessToken = queryParams.access_token;
            const accessTokenExpiresAt = new Date(queryParams.access_token_expires_at);

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt.toString());
        } catch (e) {
            return;
        }

        let routingState;
        if (queryParams.state)
            routingState = JSON.parse(queryParams.state);

        // redirect back if requested
        if (routingState && routingState.from && !routingState.from.match(/(\/login)|(\/logout)\/?/))
            this.state.redirectTo = routingState.from;
    }

    render() {
        const routeComponentProps = this.props;

        return <ProtectedRoute {...routeComponentProps}>
            <Redirect to={this.state.redirectTo}/>
        </ProtectedRoute>;
    }
}

export default Login;
