import React, { Component } from 'react';
import { IDeleteDialog } from '../../../../typings/src/modal/IModal';
import Button from '../../Button/Button';

interface Props {
    dialog: IDeleteDialog;
    onClick: (btnIndex: number) => void;
}

export default class DeleteDialog extends Component<Props> {
    render() {
        const { dialog } = this.props;

        return (
            <>
                <div className='modal-header'>
                    <h3>{dialog.title}</h3>
                </div>

                <div className='modal-body'>{dialog.body}</div>

                <div className='modal-footer'>
                    {dialog.buttons && dialog.buttons.length && dialog.buttons.map((btn, index) =>
                        <Button
                            key={index}
                            type={btn.type ? btn.type : 'primary'}
                            onClick={() => this.props.onClick(index)}
                        >
                            {btn.text}
                        </Button>
                    )}
                </div>
            </>
        );
    }
}
