import React, { Component } from 'react';
import './ErrorMessage.sass';
import '../Form/Form.sass';

interface IErrorMessage {
    error: Error;
    className?: string;
}

export default class ErrorMessage extends Component<IErrorMessage> {
    render() {
        const { error, className } = this.props;
        return (
            <div className={`error ${className ? className : ''}`}>
                {error && error.message ?
                    error.message
                    :
                    'Oops, something went wrong.'
                }
            </div>
        );
    }
}
