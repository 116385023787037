import * as React from 'react';
import * as H from 'history';
import cn from 'classnames';

import { ListStoreWithPagination } from '../../../redux/stores/ListStoreWithPagination';
import Utils from '../../../utils';

import './ListStatusWithPagination.scss';

interface Props {
    store: ListStoreWithPagination<{}>;
    history: H.History;
}

export default class ListStatusWithPagination extends React.Component<Props> {

    handlePageChange(value: number): void {
        const { store } = this.props;
        let page = value;

        if (store.params.page === page) {
            return;
        }

        const params = Object.assign(
            store.params, {
                page: page
            });

        const path = Utils.paramsToPath(location.pathname, params);
        this.props.history.replace(path);
    }

    render() {
        const { store } = this.props;

        return (
            <div>

                {store && !!store.itemsFetched.length && !!store.paginationValues.length &&
                    <ul className='pagination'>

                        {store && store.params && store.paginationValues && store.paginationValues.map((value, index) =>
                            <li
                                key={index}
                                className={cn('page-item', index + 1 === store.params.page ? 'active' : '')}
                                onClick={() => this.handlePageChange(index + 1)}
                            >
                                <a className='page-link'>{value}</a>
                            </li>
                        )}

                    </ul>
                }
            </div>
        );
    }
}
