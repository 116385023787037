import * as React from 'react';

import './PageLoader.scss';
import './ModalLoader.scss';

export interface Props {
    className?: string;
}

const ModalLoader: React.SFC<Props> = (props) => {

    return (
        <div className='lds-modal-loader'>
            <div className='lds-modal-opacity'></div>
            <div className='lds-ring'><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default ModalLoader;
