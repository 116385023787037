import { request } from './RequestAPI';

import { IPointsInsightsPayload, IPointsInsights } from 'typings/src/points/IPoints';

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};

export default class PointsServiceAPI {
    static async getPointsInsights(payload: IPointsInsightsPayload): Promise<IPointsInsights[]> {
        const url = `/points/insights`;
        return await request(url, 'post', options, JSON.stringify(payload));
    }
}
