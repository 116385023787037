import * as React from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';

import SidebarItemIcon from '../../Sidebar/SidebarItem/SidebarItemIcon';

import './Tooltip.scss';

interface Props {
    tooltipText?: string | JSX.Element;
    icon?: string;

    onClick?: () => void;
    onClickText?: string;

    iconClassname?: string;
}

interface State {
    showTooltip: boolean;
    isClicked: boolean;
}

class Tooltip extends React.Component<Props, State> {
    ref: React.RefObject<HTMLDivElement>;
    tooltip: HTMLElement;

    constructor(props) {
        super(props);

        this.state = {
            showTooltip: false,
            isClicked: false
        };

        this.ref = React.createRef();
        this.displayTooltip = this.displayTooltip.bind(this);
        this.removeTooltip = this.removeTooltip.bind(this);
    }

    componentDidMount(): void {
        this.ref.current.addEventListener('mouseover', this.displayTooltip);
        this.ref.current.addEventListener('mouseout', this.removeTooltip);
    }

    removeTooltip(): void {
        this.setState({ isClicked: false, showTooltip: false });
    }

    displayTooltip(): void {
        this.setState({ showTooltip: true }, () => {
            this.tooltip = document.getElementById('m-tooltip');
            const rect = this.ref.current.getBoundingClientRect();
            this.tooltip.style.left = `${rect.left + (rect.width / 2)}px`;
            this.tooltip.style.top = `${rect.top - 10}px`;
        });

    }

    handleClick(): void {
        const { onClick, tooltipText } = this.props;

        if (onClick) {
            onClick();
        }

        if (tooltipText) {
            this.setState({ isClicked: true });
        }
    }

    render() {
        const { children, tooltipText, onClickText, icon, iconClassname } = this.props;
        const { showTooltip, isClicked } = this.state;

        return (
            <>
                <div ref={this.ref} className='tooltip' onClick={() => this.handleClick()}>
                    <SidebarItemIcon className={cn(iconClassname)} src={`../../../assets/icons/${icon ? icon : 'information.svg'}`} />
                </div>
                {children}

                {showTooltip &&
                    ReactDOM.createPortal(
                        <div className='m-tooltip' id='m-tooltip'>
                            <div className='tooltip-wrapper'>
                                {!onClickText ?
                                    tooltipText
                                    :
                                    isClicked ?
                                        onClickText
                                        :
                                        tooltipText
                                }
                            </div>
                        </div>,
                        document.getElementById('root'),
                    )
                }
            </>
        );
    }
}

export default Tooltip;
