import * as React from 'react';

import './CollapsableTableRow.scss';

interface CollapsableRowProps {
    className?: string;
}

interface State {
    shouldRender: boolean;
}

export default class CollapsableTable extends React.Component<CollapsableRowProps, State> {
    isValid: boolean;

    constructor(props) {
        super(props);

        this.state = {
            shouldRender: false
        };
    }

    componentDidMount(): void {
        if (React.Children.count(this.props.children) === 2) {
            this.isValid = false;
        }
    }

    canRenderCollapsableRow(should: boolean): void {
        this.setState({ shouldRender: should });
    }

    shouldRender(index: number): boolean {
        return index === 0;
    }

    render() {
        const { children } = this.props;
        const { shouldRender } = this.state;

        return (
            <>
                {React.Children.map(children, (child, index) => (
                    index === 0 ?
                        React.cloneElement(
                            child as React.ReactElement<any>,
                            {
                                shouldRenderNextElement: (canRender: boolean) => this.canRenderCollapsableRow(canRender)
                            }
                        )
                    :
                        React.cloneElement(
                            child as any,
                            {
                                shouldRender
                            }
                        )
                ))}
            </>
        );
    }
}

