import ReduxActions from '../actions';
import { IWideDialog, IDeleteDialog, IErrorDialog, IDialog, IButton } from '~/typings/src/modal/IModal';

class ModalStore {
    fullScreenLoadersCount: number = 0;
    dialog: IDialog | null = null;
    deleteDialog: IDeleteDialog | null = null;
    wideDialog: IWideDialog | null = null;
    errorDialog: IErrorDialog | null = null;

    reduxActions = new ReduxActions('modal');

    async execFullScreen<T>(promise: Promise<T>): Promise<T> {
        this.fullScreenLoadersCount++;
        this.reduxActions.update();

        try {
            return await promise;
        } finally {
            this.fullScreenLoadersCount--;
            this.reduxActions.update();
        }
    }

    delete(body: string | JSX.Element, clickFn: (buttonIndex: number) => void): void {
        this.deleteDialog = {
            clickFn,
            title: 'Delete',
            body,
            buttons: [
                { text: 'Cancel', type: 'primary' },
                { text: 'Delete', type: 'danger' },
            ]
        };
        this.reduxActions.update();
    }

    showError(error: Error): void {
        this.errorDialog = {
            title: 'Error',
            error,
            button: { text: 'Close', type: 'primary' },
        };
        this.reduxActions.update();
    }

    customDialog(title: string | JSX.Element, body: string | JSX.Element, clickFn: (buttonIndex: number) => void, buttons?: IButton[]) {
        this.dialog = {
            title,
            body,
            buttons,
            clickFn
        };
        this.reduxActions.update();
    }

    wide(title: string | JSX.Element, body: JSX.Element) {
        this.wideDialog = {
            title,
            body,
        };
        this.reduxActions.update();
    }
}

export default new ModalStore();
