import { ListStore } from '../ListStore';
import { IPointsInsights } from 'typings/src/points/IPoints';

import PointsServiceAPI from 'api/PointsServiceAPI';

import ModalStore from '../ModalStore';
import utils from '../../../utils';

export default class PointsInsightsStore extends ListStore<IPointsInsights> {
    constructor() {
        super('', '', 'pointsInsight');
        this.preload();
    }

    async loadPointsInsights() {
        if (this.items && this.items.length > 0) {
            this.isLoading = false;
            this.reduxActions.update();
            return;
        }

        this.isLoading = true;
        this.reduxActions.update();

        try {
            const dateRange = utils.generateDateRange();
            const payload = { dateRange };
            this.items = await PointsServiceAPI.getPointsInsights(payload);
            this.reduxActions.setItems(this.items);
        } catch (error) {
            ModalStore.showError(error);
        } finally {
            await this.afterFinally();
            this.isLoading = false;
            this.reduxActions.update();
        }
    }
}
