import React, { Component } from 'react';
import history from '../../components/React/history';

import AdvertisersServiceAPI, { IAdvertiser } from '../../api/AdvertisersServiceAPI';
import { Validation } from '../../common/src';

import Form from '../Form/Form';
import TextFormGroup from '../Form/TextFormGroup';
import DetailsStatus from '../../components/Details/DetailsStatus';

import modalStore from '../../redux/stores/ModalStore';
import { IButton } from '../../typings/src/modal/IModal';

import ReactRouterNavigationPrompt from 'react-router-navigation-prompt';

import '../Form/Form.sass';

export interface State {
    isUpdating: boolean;
    advertiser: IAdvertiser;
    initialAdvertiser: IAdvertiser;
    error: Error;
    validationErrors: Validation.Errors.IValidationErrors;
    view: any;
}

interface Props {
    id: number;
    advertiser: IAdvertiser;
    updateParentComponent: () => void;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let timeOptions = [];
let date = new Date();
date.setUTCDate(1);
for (let i = 1; i <= 12; i++) {
    timeOptions.push({
        id: date.getUTCMonth() + 1,
        value: `${months[date.getUTCMonth()]} ${date.getFullYear()}`,
        date: new Date(date)
    });
    date.setUTCMonth(date.getUTCMonth() - 1);
}

function validate(errors: Validation.Errors.IValidationErrors): boolean {
    return Object.keys(errors).length === 0;
}

export default class AdvertisersConfiguration extends Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            isUpdating: false,
            advertiser: null,
            initialAdvertiser: null,
            error: null,
            validationErrors: null,
            view: timeOptions[0]
        };
    }

    componentDidMount() {
        this.setState({ advertiser: this.props.advertiser, initialAdvertiser: { ...this.props.advertiser } });
    }

    areChangesMade() {
        if (this.state.advertiser && this.state.initialAdvertiser) {
            const { advertiser, initialAdvertiser } = this.state;
            return (advertiser.name !== initialAdvertiser.name) ||
                (advertiser.description !== initialAdvertiser.description) ||
                (advertiser.imageUrl !== initialAdvertiser.imageUrl);
        }

        return false;
    }

    handleChange(e) {
        const { advertiser } = this.state;
        advertiser[e.target.name] = e.target.value;
        const errors = Validation.AdvertiserValidation(advertiser);
        this.setState({ advertiser, validationErrors: errors });
    }

    async onSubmit(e) {
        e.preventDefault();
        const { advertiser } = this.state;

        const validation = Validation.AdvertiserValidation(advertiser);

        if (!validate(validation)) {
            this.setState({ validationErrors: validation });
            return;
        }

        this.setState({ error: null });

        try {

            const response = await modalStore.execFullScreen(AdvertisersServiceAPI.updateOrAddAdvertiser(advertiser));

            if (response && response.id) {
                this.setState({ advertiser: null, initialAdvertiser: null });
                history.replace(`/advertisers/${response.id}/locations`);
                return;
            }
            this.props.updateParentComponent();
        } catch (error) {
            this.setState({ error });
        }
    }

    render() {
        const { isUpdating, advertiser, error, validationErrors } = this.state;

        const modalButtons: IButton[] = [
            { type: 'hollow', text: 'CANCEL' },
            { type: 'primary', text: 'LEAVE' }];

        return (
            <div>
                <ReactRouterNavigationPrompt when={this.areChangesMade()}>
                    {({ isActive, onConfirm, onCancel }) => {
                        if (isActive) {
                            modalStore.customDialog(
                                'You have unsaved changes',
                                'Are you sure you want to leave? All your changes will be lost.',
                                (btnIndex) => {
                                    if (btnIndex === 1) {
                                        onConfirm();
                                    } else {
                                        onCancel();
                                    }
                                },
                                modalButtons
                            );
                        }
                        return <></>;
                    }}
                </ReactRouterNavigationPrompt>
                {advertiser &&
                    <Form onSubmit={e => this.onSubmit(e)}>
                        <TextFormGroup
                            required
                            label='Name'
                            name='name'
                            placeholder='Name'
                            defaultValue={advertiser.name}
                            errors={validationErrors}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <TextFormGroup
                            required
                            label='Description'
                            name='description'
                            placeholder='Description'
                            defaultValue={advertiser.description}
                            errors={validationErrors}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <TextFormGroup
                            required
                            label='Image Url'
                            name='imageUrl'
                            placeholder='Image Url'
                            defaultValue={advertiser.imageUrl}
                            errors={validationErrors}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <div className='col-sm-12 form-default-ml'>
                            <button className='btn-action' type='submit'>SAVE</button>
                        </div>
                    </Form>
                }
                <DetailsStatus store={advertiser} isUpdating={isUpdating} error={error} />
            </div>
        );
    }
}
