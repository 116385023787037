import * as React from 'react';
import cn from 'classnames';
import EnvSelector from '../../Environment/EnvSelector';

import './Header.sass';


interface IProps {
    className?: string;
}

class Header extends React.Component<IProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { className } = this.props;

        return (
            <header>
                <EnvSelector />
                <div className={cn('Header', className)}>
                    {this.props.children}
                </div>
            </header>
        );
    }
}

export default Header;
