import React, { Component } from 'react';
import './Form.sass';

interface IFormProps {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export default class Form extends Component<IFormProps> {

    render() {
        return (
            <form
                className='form-default'
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.currentTarget.classList.add('validate-form');
                    this.props.onSubmit(e);
                }}
            >
                {this.props.children}
            </form>
        );
    }
}
