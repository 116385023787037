import * as React from 'react';
import * as H from 'history';
import { ODataStore } from '../../../redux/stores/ODataStore';
import { ExpandableDropdown } from '../../../components/Layout/Dropdown/ExpandableDropdown';
import { DropdownItem } from '~/components/Layout/Dropdown/Dropdown';

import utils from '../../../utils';

import './ListStatusWithPagePagination.scss';

interface Props {
    store: ODataStore<{}>;
    history: H.History;
}

export default class ListStatusWithPagePagination extends React.Component<Props> {
    inputRef: React.RefObject<HTMLInputElement>;

    limitPerPageDropdown: DropdownItem[] = [
        { id: '1', value: '10' },
        { id: '2', value: '25' },
        { id: '3', value: '50' }
    ];

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.value = nextProps.store.params.page.toString();
        }
    }

    handlePageChange(value: number | string, keyCode?: number): void {
        if (keyCode) {
            if (value.toString().match(/^[0-9]*$/g) === null) {
                value = value.toString().substr(0, value.toString().length - 1);
                this.inputRef.current.value = value;
            }
            const inputSize = value.toString().length;
            this.inputRef.current.size = inputSize < 1 ? 1 : inputSize;
            if (keyCode !== 13) {
                return;
            }
        }

        const { store } = this.props;
        let page = value;

        if (store.params.page === page) {
            return;
        }

        const maxPage = Math.ceil(store.itemsCount / store.params.limit);

        if (page < 1) {
            page = 1;
        } else if (page > maxPage) {
            page = maxPage;
        }

        const params = Object.assign(
            store.params, {
                page: page
            });

        const path = utils.paramsToPath(location.pathname, params);
        this.props.history.replace(path);
    }

    changePaginationLimit(value: string): void {
        const val = parseInt(value, 10);
        if (val === this.props.store.params.limit) {
            return;
        }
        this.props.store.params.limit = val;
        this.props.store.params.page = 1;
        const path = utils.paramsToPath(location.pathname, this.props.store.params);
        this.props.store.reduxActions.setCustom('params', { limit: val });
        this.props.history.replace(path);
    }

    areColumnsHidden (): boolean {
        const { store } = this.props;
        if (store.tableAllHeaders && !!store.tableAllHeaders.length) {
            const columnVisibility = store.tableAllHeaders.map(h => store.shouldDisplay(h.name));
            return columnVisibility.every(h => h === false);
        }
        return false;
    }

    render() {
        const { store } = this.props;
        const maxPage = Math.ceil(store.itemsCount / store.params.limit);

        return (
            <div className='page-pagination-wrapper'>

                {store && !store.isLoading && !!store.itemsCount && !utils.isEmpty(store.params) && !this.areColumnsHidden() &&
                    <>
                        <ul className='pagination-menu' />

                        <ul className='pagination page-pagination'>

                            <li className={`page-p-icon left ${store.params.page === 1 ? 'disabled' : ''}`} onClick={() => this.handlePageChange(store.params.page - 1)}>
                                <div className='arrow-left' />
                            </li>

                            <li className='p-text'>
                                Page <b>
                                    <input
                                        size={store.params.page.toString().length}
                                        className='page-p-input'
                                        ref={this.inputRef}
                                        defaultValue={store.params.page.toString()}
                                        onBlur={(e) => this.handlePageChange(parseInt(e.target.value, 10), 13)}
                                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.handlePageChange(e.target['value'], e.keyCode)}
                                    />
                                </b>
                                of {maxPage}
                            </li>

                            <li className={`page-p-icon right ${store.params.page === maxPage ? 'disabled' : ''}`} onClick={() => this.handlePageChange(store.params.page + 1)}>
                                <div className='arrow-right' />
                            </li>


                        </ul>
                        <ul className='pagination-menu right'>
                            <span>Per page</span>
                            <ExpandableDropdown
                                data={this.limitPerPageDropdown}
                                text={store.params.limit.toString()}
                                onDataChangeClick={(id, value) => this.changePaginationLimit(value)}
                            />
                        </ul>
                    </>
                }
            </div>
        );
    }
}
