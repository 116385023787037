import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';

import { ODataEntityType } from 'typings/src/reports/IReport';
import SingleReportStore from '../../../redux/stores/Reports/SingleReportStore';

import { IReportsRouteProps } from './ReportsTab';

import Header from 'components/Content/Header/Header';
import Content from 'components/Content/Content';
import Paper from 'components/Layout/Paper';
import HorizontalScroll from 'components/Layout/HorizontalScroll';
import { ExpandableDropdown } from 'components/Layout/Dropdown/ExpandableDropdown';
import ListStatus from 'components/List/ListStatus';
import FilterWrapper from 'components/Layout/Filter/FilterWrapper';
import UpperText from 'components/Layout/UpperText/UpperText';
import Sort from 'components/List/Sort';
import ODataFilter from 'components/List/ODataFilter';
import ListStatusWithPagePagination from 'components/List/ListStatusWithPagePagination/ListStatusWithPagePagination';
import Icons from 'components/Layout/Icons/Icons';
import Button from 'components/Layout/Button/Button';

import utils from '../../../utils';
import odataUtils from 'utils/odataUtils';

import './ReportSingle.sass';

class ReportSingle extends Component<RouteComponentProps<IReportsRouteProps>> {
    store: SingleReportStore = null;

    constructor (props) {
        super(props);

        const reportName = this.getReportName(false);
        const hiddenFilter = this.getQueryHiddenFilter();
        this.store = new SingleReportStore(reportName, hiddenFilter);
        this.store.params.hiddenFilter = hiddenFilter.join(',');
    }

    getReportName (addSpace: boolean): string {
        const { reportId } = this.props.match.params;
        const splitName = reportId.split(/[- ]/);
        return splitName.reduce((pN, cN) => `${pN}${utils.capitalizeWords(cN)}${addSpace ? ' ' : ''}`, '');
    }

    getQueryHiddenFilter (): string[] {
        const queryHiddenFilter = utils.searchToParams(this.props.location.search);
        return queryHiddenFilter['hiddenFilter'] ? queryHiddenFilter['hiddenFilter'].split(',') : [];
    }

    async componentWillMount(): Promise<void> {
        await this.store.getReportsHeaders();
        const params = utils.searchToParams(this.props.history.location.search);

        this.store.params = Object.assign(
            this.store.params,
            params);

        this.load();
    }

    async componentWillReceiveProps(nextProps: RouteComponentProps<{}>) {
        if (this.props.location.search !== nextProps.location.search) {
            await this.store.loadFromSearch(nextProps.location.search);
        }
    }

    componentWillUnmount () {
        this.store.resetSelectedHeaders();
    }

    async load(): Promise<void> {
        await this.store.load();
    }

    getFilterQuery (name) {
        if (this.store && this.store.params.filter) {
            return JSON.parse(this.store.params.filter)[name] || '';
        }
        return '';
    }

    getParameters () {
        return this.store.params;
    }

    handleFilterChange (e, value) {
        this.store.handleFilterChange(e, value);

        const index = this.store.dropDown.findIndex(dd => dd.id === e);
        if (index !== -1) {
            const hiddenFilter = this.getQueryHiddenFilter();
            const ind = hiddenFilter.findIndex(r => r === e);
            if (this.store.dropDown[index].checked) {
                if (ind !== -1) {
                    hiddenFilter.splice(ind, 1);
                } else {
                    hiddenFilter.push(e);
                }
            } else {
                if (ind === -1) {
                    hiddenFilter.push(e);
                } else {
                    hiddenFilter.splice(ind, 1);
                }
            }
            this.store.params.hiddenFilter = hiddenFilter.join(',');
            this.props.history.replace(utils.paramsToPath(this.props.location.pathname, this.store.params));
        }
    }

    handleDropdownSelection (type) {
        this.store.handleDropdownSelectionChange(type === 'all');
        if (type === 'all') {
            this.store.params.hiddenFilter = '';
        } else {
            const hiddenFilter = this.store.dropDown.map(dd => dd.id);
            this.store.params.hiddenFilter = hiddenFilter.join(',');
        }
        this.props.history.replace(utils.paramsToPath(this.props.location.pathname, this.store.params));
    }

    areColumnsHidden (): boolean {
        if (this.store.tableAllHeaders && !!this.store.tableAllHeaders.length) {
            const columnVisibility = this.store.tableAllHeaders.map(h => this.store.shouldDisplay(h.name));
            return columnVisibility.every(h => h === false);
        }
        return false;
    }

    render() {
        if (!this.store) {
            return null;
        }

        const header = <Header>
            <Icons icon='header-back-arrow' onClick={() => this.props.history.goBack()} />
            <h1>{this.getReportName(true)}</h1>
        </Header>;
        const { csvLoading, isFilteredCsvLoading } = this.store;
        const { reportId } = this.props.match.params;
        const newReportId = reportId.split('-').join(' ');
        const numericalInputTypes = [ODataEntityType.ODInt32, ODataEntityType.ODInt64, ODataEntityType.ODDecimal, ODataEntityType.ODDouble];

        return (
            <Content header={header} className={cn('ReportSingle')}>
                <Paper className='Content-paper' type='wide'>
                    <FilterWrapper className='mt-0'>
                        {!!this.store.dropDown.length &&
                            <UpperText className='Header-upperText' text='Display Columns'>
                                <ExpandableDropdown
                                    checkbox={true}
                                    text='Filter'
                                    data={this.store.dropDown}
                                    onDataChangeClick={(e, value) => this.handleFilterChange(e, value)}
                                    onSelectAll={(type) => this.handleDropdownSelection(type)}
                                    filter={true}
                                />
                            </UpperText>
                        }
                        <UpperText className='Header-upperText pullRight' text='Download csv file'>
                            <Button type='primary' className={isFilteredCsvLoading ? 'disabled' : ''} onClick={() => this.store.downloadFilteredCsvFile(this.getReportName(false), this.getParameters())}>{isFilteredCsvLoading ? 'Downloading...' : 'Download filtered records'}</Button>&nbsp;&nbsp;
                            <Button type='primary' className={csvLoading ? 'disabled' : ''} onClick={() => this.store.downloadCsvFile(reportId)}>{csvLoading ? 'Downloading...' : 'Download all records'}</Button>
                        </UpperText>
                    </FilterWrapper>

                    {
                        this.areColumnsHidden() && <div>No items.</div>
                    }

                    <HorizontalScroll>
                        <table className='Table table table-list'>
                            <thead>
                                <tr className='headerRow' style={{ borderBottom: '0' }}>
                                    {this.store.tableAllHeaders && !!this.store.tableAllHeaders.length && this.store.tableAllHeaders.map(h =>
                                        this.store.shouldDisplay(h.name) ? <th key={`thead_${h.name}`} style={{ paddingBottom: '0' }}><Sort store={this.store} history={this.props.history} sort={h.name}>{utils.capitalizeWords(h.name)}</Sort></th> : null
                                    )}
                                </tr>
                                <tr className='headerRow filter'>
                                    {this.store.tableAllHeaders && !!this.store.tableAllHeaders.length && this.store.tableAllHeaders.map(h =>
                                        this.store.shouldDisplay(h.name) ? <th key={`thead_${h.name}`} ><ODataFilter store={this.store} history={this.props.history} sort={h.name} filterQuery={this.getFilterQuery(h.name)} inputType={h.type} /></th> : null
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.store.items && !!this.store.items.length && this.store.items.map((reportItem, index) =>
                                    <tr key={index}>
                                        {this.store.tableAllHeaders && !!this.store.tableAllHeaders.length && this.store.tableAllHeaders.map(h =>
                                            this.store.shouldDisplay(h.name) ? <td key={`tbody_${h.name}`} className={numericalInputTypes.includes(h.type) ? 'number' : ''}>{odataUtils.findCorrectFormat(reportItem[h.name], h)}</td> : null
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </HorizontalScroll>

                    <ListStatus store={this.store} emptyText={`${this.props.match.params.reportId} report`} />
                    <ListStatusWithPagePagination store={this.store} history={this.props.history} />
                </Paper>
            </Content>
        );
    }
}

const mapStateToProps = state => ({
    oDataReducer: state.ODataReducer
});

export default withRouter(connect(mapStateToProps)(ReportSingle));
