import { store } from '../../index';

class ReduxActions {

    constructor(protected name: string, protected lastUpdateIndex: number = 0) { }

    update(): any {
        store.dispatch({
            type: 'PUT',
            attr: 'loadIndex',
            payload: ++this.lastUpdateIndex,
            name: this.name
        });
    }

    setItems<T>(payload: T): any {

        store.dispatch({
            type: 'PUT',
            attr: 'items',
            payload,
            name: this.name
        });
    }

    setItem<T>(payload: T): any {

        store.dispatch({
            type: 'PUT',
            attr: 'item',
            payload,
            name: this.name
        });
    }

    setItemsFetched<T>(payload: T): any {

        store.dispatch({
            type: 'PUT',
            attr: 'itemsFetched',
            payload,
            name: this.name
        });
    }

    setPagination(payload: string[]): any {

        store.dispatch({
            type: 'PUT',
            attr: 'paginationValues',
            payload,
            name: this.name
        });
    }

    setCustom<T>(attr: string, payload: T): any {

        store.dispatch({
            type: 'PUT',
            attr,
            payload,
            name: this.name
        });
    }
}

export default ReduxActions;


