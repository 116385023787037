export interface IValidationErrors {
    [field: string]: IValidationErrorData[];
}

// Validation Error Types
export interface IValidationRequireErrorData {
    code: ValidationErrorCode.RequireError;
}

export interface IValidationUrlErrorData {
    code: ValidationErrorCode.UrlError;
}

export interface IValidationStringOrNullErrorData {
    code: ValidationErrorCode.stringOrNull;
}

export type IValidationErrorData =
    IValidationRequireErrorData |
    IValidationUrlErrorData |
    IValidationStringOrNullErrorData;


// Validation Error Codes
export enum ValidationErrorCode {
    RequireError = 1,
    UrlError = 2,
    stringOrNull = 3
}
