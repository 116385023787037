import * as React from 'react';

import cn from 'classnames';

import AnalyticsServiceAPI from 'api/AnalyticsServiceAPI';
import BulletLegend from 'components/Layout/Legend/BulletLegend';
import Button from 'components/Layout/Button/Button';
import Content from 'components/Content/Content';
import Error from 'components/Layout/Error/Error';
import ExpandableDropdown from 'components/Layout/Dropdown/ExpandableDropdown';
import Header from 'components/Content/Header/Header';
import Headline from 'components/Content/Headline';
import LineChart from 'components/Layout/LineChart/LineChart';
import PageLoader from 'components/Layout/PageLoader/PageLoader';
import './LocationInsightsPage.sass';
import Paper from 'components/Layout/Paper';
import Space from 'components/Layout/Space';
import UpperText from 'components/Layout/UpperText/UpperText';
import BarChart from 'components/Layout/BarChart/BarChartZoomBrush';



interface Props {
    className?: string;
}

interface State {
    isUpdating: boolean;
    data: any;
    view: any;
    lines?: any;
    currentZoom: null | number;
    chartType: any;
    barChartData: any;
}

enum View {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month'
}

const viewOptions = [
    {
        id: View.DAY,
        value: '1 Day',
        maxZoom: 8
    },
    {
        id: View.WEEK,
        value: '1 Week',
        maxZoom: 28
    },
    {
        id: View.MONTH,
        value: '1 Month',
        maxZoom: 150
    }
];

enum Chart {
    LINE = 'line',
    BAR = 'bar'
}

const chartOptions = [
    {
        id: Chart.LINE,
        value: 'Line Chart'
    },
    {
        id: Chart.BAR,
        value: 'Bar Chart'
    }
];

const legendData = [
    {color: '#57df71', label: 'Traveling', id: 'transit'},
    {color: '#787c7d', label: 'At Home', id: 'home'},
    {color: '#5d27cc', label: 'At Work', id: 'work'},
    {color: '#ff4158', label: 'At Retail', id: 'retail'},
    {color: '#f5a523', label: 'Other', id: 'other'}
];

export default class LocationInsightsPage extends React.Component<Props, State> {
    state: State = {
        isUpdating: true,
        data: null,
        view: viewOptions[0],
        currentZoom: null,
        chartType: chartOptions[0],
        barChartData: null
    };

    constructor(props) {
        super(props);
    }

    async getData() {
        const to = new Date();
        to.setHours(to.getHours(), 0, 0, 0);
        const from = new Date();
        from.setHours(from.getHours(), 0, 0, 0);

        if (this.state.view.id === View.DAY) {
            from.setDate(from.getDate() - 1);
        } else if (this.state.view.id === View.WEEK) {
            from.setDate(from.getDate() - 7);
        } else {
            from.setMonth(from.getMonth() - 1);
        }

        const data = await AnalyticsServiceAPI.getLocationInsights(from.toISOString(), to.toISOString());

        this.setState({
            data
        });
        this.processData();
    }

    processData(zoomLevel = 1) {
        const total = this.state.data.usersPerTimeRange.length;
        const perGroup = Math.max(1, Math.round((total / 50) / zoomLevel));
        const totalUsers = this.groupItems(this.state.data.usersPerTimeRange, perGroup);

        if (zoomLevel !== this.state.currentZoom && zoomLevel >= 1 || this.state.isUpdating === true) {
            if (this.state.chartType.id === Chart.BAR) {
                const barChartData = [];

                let bars = {};
                for (const activity of this.state.data.activities) {
                    bars[activity.id] = this.groupItems(activity.users, perGroup, true);
                }

                for (let i = 0; i < totalUsers.length; i++) {
                    let item = {
                        label: '',
                        values: []
                    };

                    for (let activity in bars) {
                        if (bars[activity]) {
                            item.label = bars[activity][i].date;
                            let count = bars[activity][i].value;
                            let t = totalUsers[i];
                            item.values.push({
                                label: activity,
                                total: t,
                                count,
                                value: count > 0 ? Math.round((count / t) * 100) : 0,
                                color: legendData.find(d => d.id === activity).color
                            });
                        }
                    }

                    barChartData.push(item);
                }

                this.setState({
                    currentZoom: zoomLevel,
                    isUpdating: false,
                    barChartData
                });

                return this.state.barChartData;
            } else {
                const lines = {};

                for (const activity of this.state.data.activities) {
                    lines[activity.id] = this.groupItems(activity.users, perGroup, true);
                    for (let i in lines[activity.id]) {
                        if (lines[activity.id][i]) {
                            let val = lines[activity.id][i].value;
                            let t = totalUsers[i];
                            lines[activity.id][i].count = val;
                            lines[activity.id][i].total = t;
                            lines[activity.id][i].value = val > 0 ? Math.round((val / t) * 100) : 0;

                            let tooltipText = `${t} user${t !== 1 ? 's' : ''}`;
                            if (val > 0) {
                                tooltipText = `${val} of ${t} user${t !== 1 ? 's' : ''}(${lines[activity.id][i].value}%)`;
                            }
                            lines[activity.id][i].tooltipText = tooltipText;
                        }
                    }
                }

                this.setState({
                    lines,
                    currentZoom: zoomLevel,
                    isUpdating: false
                });

                return this.state.lines;
            }
        } else {
            if (this.state.chartType.id === Chart.BAR) {
                return this.state.barChartData;
            }
            return this.state.lines;
        }
    }

    groupItems(data, perGroup = 1, addDate = false) {
        const startTime = new Date(this.state.data.startTime).getTime();
        const timeRange = this.state.data.timeRangeInMinutes;

        let result = [];
        let j = 1;
        let tmp = [];

        for (let i = 0; i < data.length; i++, j++) {
            tmp.push(Number(data[i]));
            if (j === perGroup) {
                result.push(addDate ? {
                    value: Math.max(...tmp),
                    date: new Date(startTime + (timeRange * i * 60000))
                } : Math.max(...tmp));
                tmp = [];
                j = 0;
            }
        }

        if (j !== 1) {
            result.push(addDate ? {
                value: Math.max(...tmp),
                date: new Date(startTime + (timeRange * (data.length - 1) * 60000))
            } : Math.max(...tmp));
        }

        return result;
    }

    async componentDidMount() {
        await this.getData();
    }

    render() {
        const { isUpdating, view, lines, chartType, barChartData } = this.state;

        if (isUpdating) {
            return <PageLoader/>;
        }

        const header = <Header>
            <h1>Location Insights</h1>
            <Space/>
            <UpperText className='Header-upperText' text='View'>
                <ExpandableDropdown text={view.value} data={viewOptions} onDataChangeClick={this.updateView.bind(this)}/>
            </UpperText>
            <UpperText className='Header-upperText' text='Chart Type'>
                <ExpandableDropdown text={chartType.value} data={chartOptions} onDataChangeClick={this.changeChartType.bind(this)}/>
            </UpperText>
        </Header>;

        let chart;
        if (chartType.id === Chart.BAR) {
            chart = <BarChart data={barChartData} maxZoom={this.state.view.maxZoom} onZoom={this.processData.bind(this)}/>;
        } else {
            chart = <LineChart lines={lines} maxZoom={this.state.view.maxZoom} onZoom={this.processData.bind(this)}/>;
        }
        return <Content header={header} className={cn('LocationInsightsPage')}>
            <Paper className='Content-paper' type='wide'>
                <Headline>Insights</Headline>
                <BulletLegend data={legendData} chartTypeId={chartType.id}/>
                {chart}
            </Paper>
        </Content>;
    }

    async updateView(id) {
        if (id) {
            await this.setState({view: viewOptions.find(c => c.id === id), isUpdating: true});
        }
        await this.getData();
    }

    async changeChartType(id) {
        if (id) {
            await this.setState({chartType: chartOptions.find(item => item.id === id), isUpdating: true});
        }
        await this.getData();
    }
}
