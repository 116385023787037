/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import { ConfigManager } from '../../api/GetConfigAPI';
import { Environment, Environments } from '../Environment/Environments';
import ExpandableDropdown from './../Layout/Dropdown/ExpandableDropdown';

import authService from '../../services/authService';

import './EnvSelector.scss';

interface Props {
    className?: string;
}

interface State {
    env?: Environment;
    redirect?: boolean;
}

class EnvSelector extends React.Component<Props, State> {
    state: State = {
        redirect: false
    };

    constructor(props) {
        super(props);
        try {
            let env: Environment = ConfigManager.getEnvironment();
            this.state.env = env;
        } catch (e) {
            if (e !== 'noEnvironmentSelected') {
                console.error(e);
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/'/>;
        }

        let text = 'Select environment';
        if (this.state.env) {
            text = this.state.env.value;
        }

        return (
            <div className='EnvironmentDropdown'>
                <ExpandableDropdown text={text} data={Environments[process.env.NODE_ENV]} onDataChangeClick={this.selectEnv.bind(this)} />
            </div>
        );
    }

    async selectEnv(envId): Promise<void> {
        await authService.logout(envId);
        localStorage.setItem('tmpEnv', envId);
        this.setState({ redirect: true });
    }
}

export default EnvSelector;
