/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';

export interface ICategory {
    id: string;
    name: string;
    alias: string;
}

export default class OffersApi {

    static async getOffersCategories(): Promise<{ categories: ICategory[] }> {
        let url = '/offers/category/?country=US';
        return await request(url, 'get');
    }
}
