import React from 'react';
import cn from 'classnames';
import { TripsStatisticsStore } from '../../redux/stores/Trips/TripsStatisticsStore';
import { DropdownItem } from '../Layout/Dropdown/Dropdown';

import Paper from '../Layout/Paper';
import ListStatus from '../List/ListStatus';
import moment from 'moment';
import * as momenttz from 'moment-timezone';
import UpperText from '../Layout/UpperText/UpperText';
import FilterWrapper from '../Layout/Filter/FilterWrapper';
import ExpandableDropdown from '../Layout/Dropdown/ExpandableDropdown';
import HorizontalScroll from '../Layout/HorizontalScroll';
import Tooltip from '../Layout/Tooltip/Tooltip';

import Utils from '../../utils';

import '../Form/Form.sass';
import './TripsStatisticsList.scss';
import '../Layout/Table/Table.sass';
import 'components/Layout/NavLink/NavLink.sass';

const tableDummyHeaders = ['User ID', 'Trip ID', 'Start Date', 'End Date', 'Local Start Date', 'Start Location', 'End Location', 'Duration (seconds)', 'Distance (meters)', 'Hard Braking Count', 'Phone Handling Count', 'Speeding Count', 'Hard Acceleration Count',
'Hard Acceleration Duration', 'Safety Score', 'Phone Handling Score', 'Mindful Score', 'Conditions Score', 'Total Score', 'Points Earned', 'Device Information'];

export interface State {
    tableFilter: DropdownItem[];
}

interface ITripsStatisticsListProps {
    store: TripsStatisticsStore;
}

class TripsStatisticsList extends React.Component<ITripsStatisticsListProps, State> {
    store: TripsStatisticsStore;
    tripsList: Element;
    scrollTimeout: NodeJS.Timeout;

    constructor(props) {
        super(props);

        this.state = {
            tableFilter: null
        };

        this.continuationListOnScroll = this.continuationListOnScroll.bind(this);
        this.addScrollListener = this.addScrollListener.bind(this);
        this.removeScrollListener = this.removeScrollListener.bind(this);
    }

    componentWillMount(): void {
        this.store = this.props.store;
        this.setupFilters();
    }

    componentDidMount(): void {
        this.addScrollListener();
    }

    componentDidUpdate(nextProps: ITripsStatisticsListProps): void {
        this.store = nextProps.store;

        if (!!this.store.items.length && !this.state.tableFilter) {
            this.setupFilters();
        }
    }

    componentWillUnmount() {
        this.removeScrollListener();
    }

    render(): JSX.Element {
        const { tableFilter } = this.state;
        const { items } = this.store;

        return (
            <div className={cn('TripsList')}>
                <FilterWrapper className='mt-0'>
                    {tableFilter &&
                        <UpperText className='Header-upperText' text='Display Columns'>
                            <ExpandableDropdown
                                checkbox={true}
                                minCheckedItems={1}
                                text='Filter'
                                data={tableFilter}
                                onDataChangeClick={(e, value) => this.handleFilterChange(e, value)}
                            />
                        </UpperText>
                    }
                </FilterWrapper>

                <Paper className='Content-paper' type='nested'>

                    <HorizontalScroll>

                        <table className='Table table table-list'>
                            <thead>
                                <tr className='headerRow'>
                                    {tableDummyHeaders.map((headerText, index) => this.shouldDisplay(headerText) &&
                                        <th key={index}>{headerText}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                            {tableFilter && items && items.length > 0 && items.map((trip, index, arr, colIndex = -1) =>
                                    <tr key={trip.tripId}>
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ?
                                            <td><Tooltip iconClassname='copy-icon' icon='copy.svg' tooltipText='Copy to clipboard' onClickText='Copied' onClick={() => Utils.copyToClipboard(trip.userId)}>{trip.userId}</Tooltip></td>
                                            :
                                            null
                                        }
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ?
                                            <td><Tooltip iconClassname='copy-icon' icon='copy.svg' tooltipText='Copy to clipboard' onClickText='Copied' onClick={() => Utils.copyToClipboard(trip.tripId)}>{trip.tripId}</Tooltip></td>
                                            :
                                            null
                                        }
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{momenttz(trip.startDate).utc().tz(this.store.timezone).format('YYYY/DD/MM HH:mm:ss')}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{momenttz(trip.endDate).utc().tz(this.store.timezone).format('YYYY/DD/MM HH:mm:ss')}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{moment.parseZone(trip.localStartDate).format('YYYY/DD/MM HH:mm:ss')}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{trip.startLocation.address}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{trip.endLocation.address}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.duration)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.distance, 0)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.infractions.braking.count)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.infractions.phoneHandling.count)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.infractions.speeding.count)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.infractions.accelerating.count)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.infractions.accelerating.duration)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.score.safety)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.score.phoneHandling)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.score.mindful)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.score.conditions)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.score.total)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ? <td>{Utils.numberWithCommas(trip.pointsEarned)}</td> : null}
                                        {this.shouldDisplay(tableDummyHeaders[++colIndex]) ?
                                            <td style={{ display: 'inherit' }}>
                                                <table className='Table table'>
                                                    <thead>
                                                        <tr className='headerRow'>
                                                            <th>Device</th>
                                                            <th>Model</th>
                                                            <th>Version</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trip.devices.map((device, i) =>
                                                            <tr key={i}>
                                                                <td>{device.device}</td>
                                                                <td>{device.model}</td>
                                                                <td>{device.version}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </HorizontalScroll>

                    <ListStatus store={this.store} emptyText='trips statistics' />

                </Paper>
            </div>
        );
    }

    setupFilters(): void {
        let tableFilter: DropdownItem[] = [];
        tableDummyHeaders.map((text, index) => {
            tableFilter.push({
                id: (++index).toString(),
                value: text,
                checked: this.store.preferredTableFilter.some(dctext => dctext === text)
            });
        });
        this.store.updatePreferredTableFilter();
        this.setState({ tableFilter });
    }

    shouldDisplay(column: string): boolean {
        const found = this.store.preferredTableFilter.findIndex(col => col === column);
        return found === -1 ? false : true;
    }

    addScrollListener(): void {
        this.removeScrollListener();
        this.tripsList = document.getElementById('main-wrapper');
        this.tripsList.addEventListener('scroll', this.continuationListOnScroll);
    }

    removeScrollListener(): void {
        if (this.tripsList) {
            this.tripsList.removeEventListener('scroll', this.continuationListOnScroll);
        }
    }

    continuationListOnScroll(): void {
        if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(
            async () => {
                if (this.store.continuationToken && (this.tripsList.scrollTop !== 0) &&
                    (this.tripsList.scrollTop) === (this.tripsList.scrollHeight - this.tripsList.clientHeight)) {
                    clearTimeout(this.scrollTimeout);
                    this.removeScrollListener();
                    await this.store.getTrips();
                    if (this.store.continuationToken) {
                        this.addScrollListener();
                    }
                }
        },  1);
    }

    handleFilterChange(id: string, value: string) {
        const { tableFilter } = this.state;
        const parsedId = parseInt(id, 10) - 1;
        tableFilter[parsedId].checked = !tableFilter[parsedId].checked;

        const item = tableFilter[parsedId];
        if (item.checked) {
            this.store.preferredTableFilter.push(item.value);
        } else {
            const index = this.store.preferredTableFilter.findIndex(val => val === item.value);
            this.store.preferredTableFilter.splice(index, 1);
        }

        this.setState({ tableFilter });
    }
}

export default TripsStatisticsList;
