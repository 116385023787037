import React, { Component } from 'react';
import cn from 'classnames';
import { ChildComponentProps as MapComponentProps } from 'google-map-react';
import { IMapChildren } from '../../../components/GoogleMap/GoogleMap';

import './Marker.scss';

interface Props extends MapComponentProps {
    imageUrl: string;
    className?: string;
    toogleChildren?: 'show' | 'toogle';
    mapOptions?: IMapChildren;
    numPoints?: number;
    onTooltipOpen?: () => void;
}

class Marker extends Component<Props> {
    buttonImgRef: React.RefObject<any>;
    markerRef: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.markerRef = React.createRef();
        this.buttonImgRef = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        this.closeTooltip = this.closeTooltip.bind(this);
        this.openTooltip = this.openTooltip.bind(this);
    }

    componentDidMount() {
        switch (this.props.toogleChildren) {
            case 'show':
                this.closeTooltip();
                break;

            case 'toogle':
                this.markerRef.current.addEventListener('click', this.handleClick);
                break;

            default:
                break;
        }
    }

    componentWillUnmount() {
        this.markerRef.current.removeEventListener('click', this.handleClick);

    }

    handleClick(event) {
        if (this.markerRef && !this.markerRef.current.contains(event.target) ||
            this.buttonImgRef && !this.buttonImgRef.current.contains(event.target)) {
            this.closeTooltip();
        } else {
            this.openTooltip();
        }
    }

    closeTooltip() {
        this.markerRef.current.classList.remove('mi-hover');
    }

    openTooltip() {
        if (this.props.onTooltipOpen) {
            this.props.onTooltipOpen();
        }
        this.markerRef.current.classList.toggle('mi-hover');
    }

    getBackgroundStyle(): {} {
        return { background: 'rgba(93, 39, 204, .8)', backgroundImage: `url(${this.props.imageUrl})` };
    }

    render() {
        const { className, children, toogleChildren, numPoints } = this.props;

        return (
            <div ref={this.markerRef} className={cn('gmce Marker', className)}>
                <div ref={this.buttonImgRef} className={cn('marker-img', toogleChildren ? 'cursor' : '')} style={this.getBackgroundStyle()}>
                    {numPoints ? numPoints : ''}
                </div>
                {children}
            </div>
        );
    }
}

export default Marker;
