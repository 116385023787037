import React, { Component } from 'react';
import './TabNextSvg.scss';

export default class TabNextSvg extends Component {
  render() {
    return (
        <svg className='svg-next' xmlns='http://www.w3.org/2000/svg' width='11' height='19' viewBox='0 0 11 19'>
            <path fill='none' fillRule='evenodd' stroke='#4527A0' strokeWidth='2' d='M1 18l8-8.5L1 1' opacity='.3' />
        </svg>
    );
  }
}
