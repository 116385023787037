import React from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { IHeatmap } from '../../typings/src/trips/ITrips';

import Paper from '../Layout/Paper';
import GoogleMapWithUrl from '../GoogleMap/GoogleMapWithUrl';

import '../Form/Form.sass';
import './TripsStatisticsList.scss';
import '../Layout/Table/Table.sass';
import 'components/Layout/NavLink/NavLink.sass';

interface ITripsStatisticsHeatmapProps {
    heatmap: IHeatmap[];
}

const mapProps = {
    bootstrapURLKeys: { key: 'AIzaSyATqXlZ7pFyKqsSuMyFecSmNiL70UCX5W8', libraries: 'visualization' },
    options: {
        mapTypeId: 'satellite'
    },
    heatmapLibrary: true,
    heatmap: {
        options: {
            radius: 20,
            opacity: 0.9
        },
    },
};

class TripsStatisticsHeatmap extends React.Component<ITripsStatisticsHeatmapProps> {
    heatmap: IHeatmap[];
    tripsList: Element;

    componentWillMount(): void {
        this.heatmap = this.props.heatmap;
    }

    componentDidUpdate(nextProps: ITripsStatisticsHeatmapProps): void {
        this.heatmap = nextProps.heatmap;
    }

    render(): JSX.Element {
        return (
            <div className={cn('TripsList')}>
                <Paper className='Content-paper no-padding' type='nested'>

                    <GoogleMapWithUrl
                        heatmap={this.heatmap}
                        props={Object.assign(
                            mapProps, {
                                heatmap: {
                                    options: {
                                        radius: 20,
                                        opacity: 0.9
                                    },
                                    positions: this.heatmap
                                }
                        })}
                    />

                </Paper>
            </div>
        );
    }

}

export default withRouter<any>(TripsStatisticsHeatmap);
