import Validators from './validators/index';
import { IValidationErrors } from './errors/ValidationError';
import { IMetaData } from '../../../typings/src/reports/IReport';

export function MetaDataValidation(contract: IMetaData): IValidationErrors {

    let errors: IValidationErrors = {};

    Validators.stringOrNull(contract, 'advertiserName', errors);
    Validators.stringOrNull(contract, 'campaignName', errors);
    Validators.stringOrNull(contract, 'campaignGoal', errors);
    Validators.stringOrNull(contract, 'productName', errors);
    Validators.stringOrNull(contract, 'productCategory', errors);
    Validators.stringOrNull(contract, 'adFormat', errors);

    return errors;
}

