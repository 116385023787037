import React, { Component } from 'react';
import { ListStore } from '../../redux/stores/ListStore';
import { ODataStore } from '../../redux/stores/ODataStore';
import { ListStoreWithPagination } from '../../redux/stores/ListStoreWithPagination';

import PageLoader from '../Layout/PageLoader/PageLoader';
import ErrorMessage from '../../components/common/ErrorMessage';

import './ListStatus.sass';


interface ListStatusProps {
    store: ListStore<any> | ODataStore<any> | ListStoreWithPagination<{}>;
    emptyText: string;

    customEmptyValidation?: () => string;
}

export default class ListStatus extends Component<ListStatusProps> {

    doItemsExist() {
        if (this.props.store && this.props.store.items && !!this.props.store.items.length) {
            return true;
        }

        return false;
    }

    getEmptyItemsText(): string {
        const { store, emptyText } = this.props;

        if (store.params && store.params.filter) {
            return 'No items match filter';
        }

        return `Empty ${emptyText}`;
    }

    render() {
        const { store } = this.props;
        this.getEmptyItemsText();

        return (
            <div className={`list-status ${store.isLoading ? 'list-loader ' : ''}`}>
                {store.isLoading && <PageLoader />}
                {!store.isLoading && store.error && <ErrorMessage error={store.error}/> }
                {!store.isLoading && !store.error && !this.doItemsExist() &&
                    <div className='not-found'>
                        {this.getEmptyItemsText()}
                    </div>
                }
            </div>
        );
    }
}
