import React, { Component } from 'react';
import SidebarItemIcon from '../Sidebar/SidebarItem/SidebarItemIcon';
import GoogleMap from './GoogleMap';
import { ChildComponentProps as MapComponentProps } from 'google-map-react';

interface IGoogleMapTooltip extends MapComponentProps {
    title?: string;
    onClose: () => void;
    children: JSX.Element;
}

const GoogleMapTooltip: React.SFC<IGoogleMapTooltip> = (props: IGoogleMapTooltip) => {
    return (
        <div className='gmce mt-default'>
            <div className='mt-close'>
                {props.title || null}
                <span onClick={() => props.onClose ? props.onClose() : void 0}>
                    <SidebarItemIcon src='../../../assets/icons/close-dark.svg' />
                </span>
            </div>
            {props.children || null}
        </div>
    );
};

export default GoogleMapTooltip;
