import { store } from '../../../';

import { Validation } from '../../../common/src';
import { Coords, ChangeEventValue } from 'google-map-react';
import { History, Location } from 'history';
import { match } from 'react-router';

import utils from '../../../utils';
import ReduxActions from '../../../redux/actions';

export class GoogleMapStore {
    reduxActions: ReduxActions;

    isMapLoading: boolean = true;
    center: Coords = {
        lat: 33.83,
        lng: -118.31
    };
    zoom: number = 10;

    constructor(protected reduxStorePath: string, protected _history: History<any>, protected _location: Location<any>, protected _match: match<{ location: string }>) {
        this.reduxActions = new ReduxActions(this.reduxStorePath);
        this.preload();
    }

    preload(): void {
        for (const [key, value] of utils.iterateObject(store.getState()[this.reduxStorePath])) {
            this[key] = value;
        }
        this.reduxActions.update();
    }

    update(): void {
        if (this.zoom && this.center) {
            this.reduxActions.setCustom<Coords>('center', this.center);
            this.reduxActions.setCustom<number>('zoom', this.zoom);
        }
    }

    load(): void {
        const location = this._match.params.location;
        const urlValidationErrors = Validation.GoogleMapsUrlValidation(location);

        if (utils.isEmpty(urlValidationErrors)) {
            this.parseLocation(location);
        } else {
            this.parseLocation(`@${this.center.lat},${this.center.lng},${this.zoom}z`);
        }
        this.reduxActions.update();
    }

    setupDefaultLocation(): void {
        this.locationToPath(33.83, -118.31, 10);
    }

    parseLocation(location: string): void {
        const urlValidationErrors = Validation.GoogleMapsUrlValidation(location);

        if (utils.isEmpty(urlValidationErrors)) {
            location = location.substring(1, location.length);
            location = location.substring(0, location.length - 1);
            const split = location.split(',');

            this.center.lat = this.parseNum(split[0]);
            this.center.lng = this.parseNum(split[1]);
            this.zoom = this.parseNum(split[2]);
            this.update();
        } else {
            this.setupDefaultLocation();
        }
    }

    parseNum(num: string, toFixed: number = 7): number {
        return parseFloat(parseFloat(num).toFixed(7));
    }

    locationToPath(lat: number, lng: number, zoom: number): void {
        let path = this._history.location.pathname;
        const findIndex = path.indexOf('@') - 1;
        path = path.substring(0, findIndex);
        this._history.replace(`${path}/@${this.parseNum(lat.toString())},${this.parseNum(lng.toString())},${zoom}z${this._history.location.search}`);
    }

    onMapChange(value: ChangeEventValue): void {
        const { center, zoom } = value;
        const lng = center.lng.toFixed(7);
        const lat = center.lat.toFixed(7);

        let path = this._history.location.pathname;
        const findIndex = path.indexOf('@') - 1;
        path = path.substring(0, findIndex);
        this._history.replace(`${path}/@${encodeURIComponent(lat)},${encodeURIComponent(lng)},${zoom}z${this._history.location.search}`);
        this.isMapLoading = false;
        this.reduxActions.update();
    }
}
