import * as React from 'react';
import cn from 'classnames';
import { ChildComponentProps as MapComponentProps } from 'google-map-react';

import Button from '../Button/Button';

import './MapPin.scss';
import { IMapChildren } from '../../../components/GoogleMap/GoogleMap';

interface Props extends MapComponentProps {
    isUpdating: boolean;
    className?: string;
    mapOptions?: IMapChildren;
    onPress?: () => void;
}

const MapPin: React.SFC<Props> = (props: Props) => {
    const { onPress, className, isUpdating } = props;

    return (
        <div className={cn('gmce MerchantMarker-Pin', className, isUpdating ? 'loading' : '')}>
            <Button type='primary' className='btn-info' onClick={() => onPress()}>{!isUpdating ? 'ADD LOCATION' : 'loading'}</Button>
        </div>
    );
};

export default MapPin;
