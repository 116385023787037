/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { request } from './RequestAPI';
import { latLng2Tile } from 'google-map-react/utils';

const LOCATIONS_TILE_ZOOM = 10;

export default class AnalyticsServiceAPI {
    static async getLocationInsights(from: string, to: string) {
        const url = `/analytics/insights/locations?from=${from}&to=${to}`;
        return await request(url, 'get');
    }

    static async getLocationVisits(from: string, to: string, center: any, zoom: number) {
        zoom = Math.max(zoom - 1, 1);
        let tile = latLng2Tile(center, zoom);
        tile.x = Math.max(tile.x, 0);
        tile.y = Math.max(tile.y, 0);
        let widthOffset = 1;
        let heightOffset = 1;
        let requests = [];
        for (let i = tile.x - widthOffset; i <= tile.x + widthOffset; i++) {
            for (let j = tile.y - heightOffset; j <= tile.y + heightOffset; j++) {
                requests.push(AnalyticsServiceAPI.getLocationVisitsReq(from, to, i, j, zoom));
            }
        }

        let results = await Promise.all(requests);
        return results.reduce((arr, val) => { arr.push(...val); return arr; });
    }

    static async getLocationVisitsReq(from: string, to: string, x: number, y: number, zoom: number) {
        let parameters = [];
        from ? parameters.push(`from=${from}`) : from = undefined;
        to ? parameters.push(`to=${to}`) : to = undefined;
        x || x === 0 ? parameters.push(`x=${x}`) : x = undefined;
        y || y === 0 ? parameters.push(`y=${y}`) : y = undefined;
        zoom ? parameters.push(`zoom=${zoom}`) : zoom = undefined;

        let url = `/analytics/insights/location-visits/tile`;
        url += parameters.length ? '?' + parameters.join('&') : '';
        return await request(url, 'get');
    }
}
