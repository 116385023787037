/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React, { Component } from 'react';
import cn from 'classnames';
import Content from 'components/Content/Content';
import Table from 'components/Layout/Table/Table';
import PageLoader from 'components/Layout/PageLoader/PageLoader';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import Space from 'components/Layout/Space';
import RewardsApi, { Reward } from '../../api/RewardsApi';
import UpperText from 'components/Layout/UpperText/UpperText';
import ExpandableDropdown from 'components/Layout/Dropdown/ExpandableDropdown';
import { DropdownItem } from 'components/Layout/Dropdown/Dropdown';

import './RewardsList.scss';


const tableHeaders = [
    'Unique identifier', 'Account', 'Reward value', 'Redeemed', 'Redeemed on'
];

export interface Props {
    className?: string;
}

export interface State {
    rewards: string[][];
    account: string;
    accounts: DropdownItem[];
    isUpdating: boolean;
}

class RewardsList extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            rewards: [],
            isUpdating: false,
            account: 'all',
            accounts: [{id: 'all', value: 'all'}]
        };
    }

    async updateAccounts() {
        try {
            let accountsList: string[] = await RewardsApi.getAccounts();
            let accounts: DropdownItem[] = [
                {id: 'all', value: 'all'},
                ...accountsList.map(item => {
                    return {
                        id: item,
                        value: item
                    };
                })
            ];

            await this.setState({ accounts });
        } catch (e) {
            console.error(e);
        }
    }

    async getData() {
        this.setState({ isUpdating: true });
        if (this.state.accounts.length === 1) {
            await this.updateAccounts();
        }

        // Get rewards
        let rewards: string[][] = [];
        try {
            let rewardsList: Reward[] = await RewardsApi.getRewards(this.state.account);
            rewards = rewardsList.map(item => {
                let value: number = Math.round(item.rewardValue / 100);
                return [
                    item.uniqueIdentifier,
                    item.account,
                    `$ ${value < 1 ? (item.rewardValue / 100).toFixed(2) : value}`,
                    item.redeemed ? 'Yes' : 'No',
                    item.redeemedOn
                ];
            });
        } catch (e) {
            console.error(e);
        }
        this.setState({ rewards, isUpdating: false });
    }

    componentDidMount() {
        this.getData();
    }

    async changeAccount(account) {
        await this.setState({ account });
        this.getData();
    }

    render() {
        const { rewards, isUpdating, account, accounts } = this.state;

        if (isUpdating === true) {
            return (
                <PageLoader></PageLoader>
            );
        }

        const header = <Header>
            <h1>Rewards</h1>
            <Space/>
            <UpperText className='Header-upperText' text='Account'>
                <ExpandableDropdown text={ account } data={ accounts } onDataChangeClick={this.changeAccount.bind(this)}/>
            </UpperText>
        </Header>;

        return (
            <Content header={header} className={cn('RewardsList', this.props.className)}>
                <Paper className='Content-paper' type='wide'>
                    <Table className='table table-list' headers={tableHeaders} data={rewards}/>
                </Paper>
            </Content>
        );
    }
}

export default RewardsList;
