import * as React from 'react';
import cn from 'classnames';

import Button from 'components/Layout/Button/Button';
import SidebarItemIcon from '../../../components/Sidebar/SidebarItem/SidebarItemIcon';
import Tooltip from '../Tooltip/Tooltip';

import './Dropdown.sass';

export interface DropdownItem {
    id: string;
    value: string;
    data?: any;
    checked?: boolean;
    tooltip?: string | JSX.Element;
}

export interface Props {
    className?: string;
    text: string | JSX.Element;
    direction?: 'down' | 'up';
    open?: boolean;
    items?: DropdownItem[];
    placeholder?: string;
    filter?: boolean;
    checkbox?: boolean;
    minCheckedItems?: number;
    onButtonClick?(e): void;
    onDataChangeClick?(id: string, value: string);
    onSelectAll?: (type: 'all' | 'none') => void;
}

class Dropdown extends React.Component<Props> {
    displayedItems: DropdownItem[] = [];
    typingTimeout: NodeJS.Timeout;
    ref: React.RefObject<HTMLUListElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.initItems(this.props.items);
    }

    componentWillReceiveProps(nextProps: Props) {
        if ((nextProps.items.length !== this.props.items.length)) {
            this.initItems(nextProps.items);
        }
    }

    getParentNodenameRecursive(parentEl?: HTMLElement): HTMLElement | undefined {
        if (!parentEl) {
            return undefined;
        }
        if (parentEl && parentEl.nodeName === 'TD') {
            return parentEl;
        }
        return this.getParentNodenameRecursive(parentEl.parentElement);
    }

    handleDropdownOverflow(): void {
        if (this.props.open) {
            let el = this.getParentNodenameRecursive(this.ref.current);
            if (el) {
                const rectUL = this.ref.current.getBoundingClientRect();
                const rectTD = el.getBoundingClientRect();

                const maxHeight = 400;
                const overflowCalc = (rectTD.top + rectTD.height) - (rectUL.top + maxHeight);
                if (overflowCalc < 0) {
                    this.ref.current.style.maxHeight = `${maxHeight - Math.abs(overflowCalc) - 20}px`;
                }
            }

        }
    }

    handleDataChangeClick(item: DropdownItem): void {
        const { placeholder } = this.props;

        if (placeholder && item.value === placeholder) {
            this.props.onDataChangeClick(item.id, null);
            return;
        }

        this.props.onDataChangeClick(item.id, item.value);
    }

    handleKeyDown(e: React.ChangeEvent<HTMLInputElement>): void {
        e.persist();
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(() => this.onFilterChange(e),  300);
    }

    onFilterChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const { items } = this.props;
        const tempItems: DropdownItem[] = items.filter(item => item.value.toLowerCase().includes(e.target.value.toLocaleLowerCase()));
        this.initItems(tempItems);
    }

    initItems(items: DropdownItem[]): void {
        this.displayedItems = items;
        this.forceUpdate();
    }

    isMinCheckbox(d: DropdownItem): string {
        const { minCheckedItems, items, checkbox } = this.props;
        if ((!minCheckedItems || !checkbox)) {
            return '';
        }
        const shouldDisable = items.filter(i => i.checked === true);
        if (shouldDisable && !!shouldDisable.length && shouldDisable.length === minCheckedItems && d.checked) {
            return 'disabled';
        }
        return '';
    }

    selectionClick(type: 'all' | 'none') {
        this.props.onSelectAll(type);
    }

    render() {
        const { className, text, open, direction = 'down', onButtonClick, checkbox, filter } = this.props;
        const name = 'dropdown-filter-input';

        if (open) {
            this.handleDropdownOverflow();
        }

        return (
            <div className={cn('Dropdown', className, { open, openUp: direction === 'up', openDown: direction === 'down' })}>

                <Button className='Dropdown-btn' onClick={onButtonClick}>
                    {text}
                    <div className='Dropdown-arrow' />
                </Button>

                <ul
                    className='Paper Dropdown-options'
                    ref={this.ref}
                >

                    {this.props.onSelectAll && this.props.checkbox &&
                        <div className='checkbox-selection'>
                            <span className='select-all' onClick={() => this.selectionClick('all')}>Select All</span>
                            <span className='select-none' onClick={() => this.selectionClick('none')}>Unselect All</span>
                        </div>
                    }

                    {filter &&
                        <li className='dropdown-search'>
                            <input
                                className='form-control dropdown-filter'
                                name={name}
                                placeholder='Search'
                                onChange={(e) => this.handleKeyDown(e)}
                            />
                        </li>
                    }

                    {open && this.displayedItems && this.displayedItems.map((d, index) =>
                        <li className={this.isMinCheckbox(d)} onClick={(e) => { this.handleDataChangeClick(d); onButtonClick(e); }} key={index}>
                            <a>
                                {(checkbox && d.hasOwnProperty('checked')) ?
                                    <div className={`custom-checkbox${d.checked ? ' checked' : ''}`}>
                                        <SidebarItemIcon src='../../../assets/icons/checked.svg' />
                                    </div>
                                    : null
                                }

                                {d.tooltip &&
                                    <Tooltip tooltipText={d.tooltip} icon='information.svg' />
                                }

                                {d.value}
                            </a>
                        </li>
                    )}

                    {(!this.displayedItems || this.displayedItems.length === 0) &&
                        <li className='dropdown-none'>
                            <a>No items</a>
                        </li>
                    }

                </ul>
            </div>
        );
    }
}

export default Dropdown;
