/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import * as config from './../../config/api-config.json';
import { Environment, Environments } from '../components/Environment/Environments';

export interface Config {
    baseUrl: string;
    loginUrl: string;
    clientId: string;
    ocpApimSubscriptionKey: string;
    env: Environment;
}

export class ConfigManager {
    static async getConfig(): Promise<Config> {
        let env: Environment = ConfigManager.getEnvironment();
        return {
            loginUrl: process.env.LOGIN_URL,
            baseUrl: config.env[env.config].baseUrl,
            clientId: env.client ? config.env[env.config].clients[env.client] : config.env[env.config].clientId,
            ocpApimSubscriptionKey: config.env[env.config].ocpApimSubscriptionKey,
            env: env
        };
    }

    static getEnvironment(): Environment {
        let envId: string = localStorage.getItem('env');
        let env: Environment = Environments[process.env.NODE_ENV].find(item => item.id === envId);
        if (!env) {
            throw 'noEnvironmentSelected';
        }
        return env;
    }
}
