interface IReport {
    id: number;
    name: string;
    description: string;
    isProcessing: boolean;
    statusText: string;
    processingTs: string;
    rowsInReport: number;
    startTs: string;
    stopTs: string;
    starterId: string;
    tableName: string;
    reportFileName: string;
}

interface ISingleReport {
    [key: string]: any;
}

interface IMetaData {
    id?: string;
    advertiserName?: string;
    advertiserId?: number;
    campaignName?: string;
    campaignGoal?: string;
    productName?: string;
    productCategory?: string;
    adFormat?: string;
}

interface IReportCSV {
    url: string;
    fileName: string;
}

interface IReportHeaders {
    name: string;
    type: ODataEntityType;
}

enum ODataEntityType {
    ODString = 'Edm.String',
    ODInt32 = 'Edm.Int32',
    ODInt64 = 'Edm.Int64',
    ODDateTime = 'Edm.DateTimeOffset',
    ODDecimal = 'Edm.Decimal',
    ODDouble = 'Edm.Double',
    ODGuid = 'Edm.Guid',
}

export {
    IReport,
    ISingleReport,
    IMetaData,
    IReportCSV,
    IReportHeaders,
    ODataEntityType
};
