import { IOdataParams } from '../typings/src/driverAnalytics/IDriverAnalytics';
import { IPageParams } from '../redux/stores/ListStoreWithPagination';
import { IReportHeaders, ODataEntityType } from '../typings/src/reports/IReport';
import * as validator from 'validator';

import self from './odataUtils';
import utils, { DateTimeFormat } from '.';
import moment from 'moment';

const rules = {
    space: ['desc'],
    dollar: ['count', 'top', 'skip', 'orderby', 'sort'],
    filter: ['filter']
};

export default {

    paramsToOdataQuery(params: IPageParams, entityInfo: IReportHeaders[], countItems: boolean = true): string {

        let odataParams: IOdataParams = {};

        if (countItems) {
            odataParams.count = countItems.toString();
        }

        if (params) {
            if (params.page) {
                if (!params.limit) {
                    params.limit = 10;
                }
                odataParams.skip = (params.page - 1) * params.limit;
                odataParams.top = params.limit;
            }

            odataParams = Object.assign(odataParams, params);
        }

        return self.searchToOdataParams(odataParams, entityInfo);
    },

    searchToOdataParams(odataParams: IOdataParams, entityInfo: IReportHeaders[]): string {
        let query: string = '';

        if (!odataParams) {
            return query;
        }

        query += '?';

        Reflect.ownKeys(odataParams).map(key => {
            Reflect.ownKeys(rules).map(ruleKey => {

                if (rules[ruleKey].some(val => val === key)) {
                    const value = odataParams[key];
                    query += self.handleKey(ruleKey as string, key as string, value, entityInfo);

                    if (key === 'orderby') {
                        query += ` ${odataParams.desc === 'true' ? 'desc' : 'asc'}`;
                    }

                    if (query[query.length - 1] !== '&') {
                        query += '&';
                    }
                }
            });
        });

        query = query.substring(0, query.length - 1);

        return query;
    },

    handleFilter(filterQuery: string, entityInfo: IReportHeaders[]) {
        let odataQuery: string = '';
        const filterQueryParsed = JSON.parse(filterQuery);

        for (const query in filterQueryParsed) {
            if (filterQueryParsed[query]) {
                const type = entityInfo.find(e => e.name === query).type;
                odataQuery += ` and ${self.handleFilterConditions(query, filterQueryParsed[query], type)}`;
            }
        }

        return odataQuery.substr(5, odataQuery.length);
    },

    handleFilterConditions (queryParameter: string, queryValue: string, entityType: ODataEntityType) {
        if (entityType === ODataEntityType.ODString) {
            const htmlEscape = (str) => {
                return str
                    .replace(/'/g, '\'\'')
                    .replace(/&/g, '%26');
            };
            queryValue = htmlEscape(queryValue);
            return `contains(${queryParameter}, '${queryValue}')`;
        }
        const conditions = [{
            condition: '!=',
            odata: 'ne'
        }, {
            condition: '!',
            odata: 'ne'
        }, {
            condition: '>=',
            odata: 'ge'
        }, {
            condition: '<=',
            odata: 'le'
        }, {
            condition: '=',
            odata: 'eq'
        }, {
            condition: '>',
            odata: 'gt'
        }, {
            condition: '<',
            odata: 'lt'
        }];
        queryValue = queryValue.trim();
        const condition = conditions.find(c => queryValue.includes(c.condition));
        if (condition) {
            const query = queryValue.replace(condition.condition, '');
            if (!query) {
                return `${queryParameter} ne null`;
            } else {
                return self.getCorrectFilter(entityType, queryParameter, query, condition);
            }
        } else {
            return self.getCorrectFilter(entityType, queryParameter, queryValue, conditions.find(c => c.condition === '='));
        }
    },

    getCorrectFilter(entityType: ODataEntityType, queryParameter: string, queryValue: string, condition: { odata: string, condition: string }) {
        switch (entityType) {
            case ODataEntityType.ODDateTime:
                return self.handleDateTimeFilter(queryParameter, queryValue, condition);
            case ODataEntityType.ODDecimal:
            case ODataEntityType.ODDouble:
                return self.handleDecimalFilter(queryParameter, queryValue, condition);
            case ODataEntityType.ODGuid:
                return self.handleGuidFilter(queryParameter, queryValue, condition);
            default:
                return self.handleIntFilter(queryParameter, queryValue, condition);
        }
    },

    handleGuidFilter(queryParameter: string, queryValue: string, condition: { odata: string, condition: string }) {
        if (condition.condition !== '=' || !queryValue || !validator.default.isUUID(queryValue)) {
            return `${queryParameter} eq null`;
        }

        return `${queryParameter} ${condition.odata} ${queryValue}`;
    },

    handleDateTimeFilter (queryParameter: string, queryValue: string, condition: { odata: string, condition: string }) {
        const date = moment(queryValue, DateTimeFormat).toISOString();
        const upperDate = moment(queryValue, DateTimeFormat).add(1, 'minute').toISOString();

        if (condition.condition === '=') {
            return `${queryParameter} ge ${date} and ${queryParameter} lt ${upperDate}`;
        } else if (condition.condition === '<=') {
            return `${queryParameter} lt ${upperDate}`;
        } else {
            return `${queryParameter} ${condition.odata} ${date}`;
        }
    },

    handleDecimalFilter (queryParameter: string, queryValue: string, condition: { odata: string, condition: string }) {
        const E = 1 / Math.pow(10, 8);
        const nmbr = parseFloat(queryValue.replace(new RegExp(',', 'g'), ''));

        if (condition.condition === '=') {
            return `${queryParameter} ge ${nmbr - E}d and ${queryParameter} lt ${nmbr + E}d`;
        } else if (condition.condition === '<=') {
            return `${queryParameter} lt ${nmbr + E}d`;
        } else if (condition.condition === '>=') {
            return `${queryParameter} ge ${nmbr - E}d`;
        } else {
            return `${queryParameter} ${condition.odata} ${nmbr}d`;
        }
    },

    handleIntFilter (queryParameter: string, queryValue: string, condition: { odata: string, condition: string }) {
        const parsedInt = parseInt(queryValue.replace(new RegExp(',', 'g'), ''), 10);
        if (!isNaN(parsedInt) && !utils.valueIsEmpty(parsedInt)) {
            return `${queryParameter} ${condition.odata} ${parsedInt}L`;
        }
        return `${queryParameter} eq null`;
    },

    handleKey(ruleKey: string, key: string, value: string | number, entityInfo: IReportHeaders[]): string {

        switch (ruleKey) {

            case 'dollar':
                return `$${key}=${value}`;

            case 'filter':
                return `$${ruleKey}=${self.handleFilter(value as string, entityInfo)}`;

            case 'space':
            default:
                return '';
        }
    },

    getEntityHeaders(arr: any[], name: string): IReportHeaders[] {
        let reportHeaders: IReportHeaders[] = [];

        let entityInfo: any = arr.find(item => item.$.Name === name);

        if (!entityInfo) {
            return [];
        }

        for (const prop of entityInfo.Property) {
            reportHeaders.push({
                name: prop.$.Name,
                type: prop.$.Type
            });
        }

        return reportHeaders;
    },

    findCorrectFormat(value: any, prop: IReportHeaders): string {
        if (!prop.type) {
            if (!utils.valueIsEmpty(value)) {
                return value.toString();
            }
            return value;
        }

        if (prop.type.includes('Int')) {
            return utils.numberWithCommas(value);
        }

        if (prop.type.includes('String')) {
            return value;
        }

        if (prop.type.includes('DateTimeOffset')) {
            return utils.formatDate(value);
        }

        if (!utils.valueIsEmpty(value)) {
            return value.toString();
        }
        return value;
    },

};
