import React, { Component } from 'react';
import { IMerchant } from '../../../typings/src/campaignAttribution/ICampaignAttribution';

import { CampaignAttributionMapStore } from '../../../redux/stores/CampaignAttributionMap/CampaignAttributionMapStore';
import SidebarItemIcon from '../../../components/Sidebar/SidebarItem/SidebarItemIcon';

import '../../Layout/Table/Table.sass';
import './CampaignAttributionMapTooltip.scss';

import utils from '../../../utils';

interface Props {
    merchants: IMerchant[];
    store: CampaignAttributionMapStore;
    onClose: () => void;
    lat?: number;
    lng?: number;
}

class CampaignAttributionMapTolltip extends React.Component<Props> {
    activeIndex: number = 0;

    pageChange(index: number): void {
        this.activeIndex = index;
        this.forceUpdate();
    }

    componentWillReceiveProps(nextProps: Props): void {
        const test = utils.ArrayUniqueValues<IMerchant, IMerchant>(this.props.merchants, nextProps.merchants, 'ID');
        if (test && !!test.length) {
            this.activeIndex = 0;
        }
    }

    render() {

        const { merchants } = this.props;
        const merchant = merchants[this.activeIndex];

        return (
            <table className='Table table table-list map-table map-tooltip'>
                <thead>
                    <tr className='headerRow vm-middle'>
                        <th colSpan={2}>
                            <img src={merchant.icon} style={{ width: 30, height: 30 }} />
                            <span>{merchant.label}</span>
                            <span className='map-tooltip-close-btn' onClick={() => this.props.onClose()}><SidebarItemIcon src='../../../assets/icons/close-dark.svg' /></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Store name</td>
                        <td>{merchant.storefront}</td>
                    </tr>
                    <tr>
                        <td>Store address</td>
                        <td>{merchant.address}</td>
                    </tr>
                    <tr>
                        <td>Visits</td>
                        <td>{merchant.numberOfVisits}</td>
                    </tr>
                    <tr>
                        <td>Unique visitors</td>
                        <td>{merchant.numberOfUniqueUsers}</td>
                    </tr>
                    {merchants.length > 1 &&
                        <tr>
                            <td colSpan={2} className='tooltip-pagination'>
                                <div>
                                    {merchants.map((m, index) =>
                                        <div
                                            key={index}
                                            className={`${this.activeIndex === index ? 'active' : ''}`}
                                            onClick={() => this.pageChange(index)}
                                        >
                                            {index + 1}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }
}

export default CampaignAttributionMapTolltip;
