import * as React from 'react';
import cn from 'classnames';

import './UpperText.sass';


interface IProps {
    className?: string;
    children: React.ReactNode;

    text: string;
}

const UpperText: React.SFC<IProps> = (props) => {
    const {className, children, text} = props;

    return (
        <div className={cn('UpperText', className)}>
            <span className='UpperText-topText'>{text}</span>
            <div className='UpperText-container'>
                {children}
            </div>
        </div>
    );
};

export default UpperText;
