import React, { Component } from 'react';
import { IWideDialog } from '../../../../typings/src/modal/IModal';
import Button from '../../Button/Button';
import SidebarItemIcon from '../../../Sidebar/SidebarItem/SidebarItemIcon';

interface Props {
    onClick: () => void;
}

export default class WideDialog extends Component<IWideDialog & Props> {

    render() {
        return (
            <>
                <div className='modal-header'>
                    <h3>{this.props.title}</h3>
                    <Button type='icon' className='icon-gray' onClick={() => this.props.onClick()}>
                        <SidebarItemIcon src='../../../assets/icons/close.svg' />
                    </Button>

                </div>

                <div className='modal-body'>{this.props.body}</div>
            </>
        );
    }
}
