import * as React from 'react';
import cn from 'classnames';

import './ProgramLogsHeadline.sass';


interface Props {
    className?: string;
    children: string;
}

const ProgramLogsHeadline: React.SFC<Props> = (props) => {
    const {className} = props;
    return (
        <div className={cn('ProgramLogsHeadline', className)}>
            <div><h2>{props.children}</h2></div>
            <div>
                <div className='ProgramLogsLegend'>
                    <div><div className='ProgramLogsLegendCircle gray'></div> <p>Past</p></div>
                    <div><div className='ProgramLogsLegendCircle red'></div> <p>Now playing</p></div>
                    <div><div className='ProgramLogsLegendCircle green'></div> <p>Next up</p></div>
                </div>
            </div>
        </div>
    );
};

export default ProgramLogsHeadline;
