import * as React from 'react';
import { connect } from 'react-redux';

import PointsInsightStore from '../../redux/stores/PointsInsight/PointsInsightStore';

import Content from 'components/Content/Content';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import Headline from 'components/Content/Headline';
import BulletLegend from 'components/Layout/Legend/BulletLegend';
import LineChart from 'components/Layout/LineChart/LineChart';
import OverlayLoader from '../Layout/OverlayLoader/OverlayLoader';

const legendData = [
    {color: '#57df71', label: 'Redeemed', id: 'redeemed'},
    {color: '#787c7d', label: 'Earned', id: 'earned'},
    {color: '#5d27cc', label: 'Remaining', id: 'remaining'}
];
class Points extends React.Component<{}> {
    store = new PointsInsightStore();

    async componentDidMount() {
       await this.store.loadPointsInsights();
    }

    getLines() {
        const pluralizePointsText = (value: number) => `${value.toLocaleString()} point${value === 1 ? 's' : ''}`;

        const redeemed = [], earned = [], remaining = [];
        this.store.items.sort((d1, d2) => d1.startDate.localeCompare(d2.startDate));
        for (let i = 0; i < this.store.items.length; i++) {
            const { startDate, redeemed: redeemedPoints, earned: earnedPoints, remaining: remainingPoints } = this.store.items[i];
            redeemed.push({ date: new Date(startDate), value: redeemedPoints || 0, tooltipText: `${pluralizePointsText(redeemedPoints || 0)} redeemed` });
            earned.push({ date: new Date(startDate), value: earnedPoints || 0, tooltipText: `${pluralizePointsText(earnedPoints || 0)} earned` });
            remaining.push({ date: new Date(startDate), value: remainingPoints || 0, tooltipText: `${pluralizePointsText(remainingPoints || 0)} remaining` });
        }

        return { earned, redeemed, remaining };
    }

    render() {
        const header = <Header>
            <h1>Points Insight</h1>
        </Header>;

        return <Content header={header}>
            <Paper className='Content-paper' type='wide'>
                <Headline>Insight</Headline>
                <BulletLegend data={legendData} chartTypeId={'line'} />

                <OverlayLoader isLoading={ this.store.isLoading } />
                { this.store.items.length > 0 && <LineChart lines={this.getLines()} /> }

            </Paper>
        </Content>;
    }
}

const mapStateToProps = (state) => ({
    pointsInsight: state.pointsInsight
});

export default connect(mapStateToProps)(Points);
