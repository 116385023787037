import history from '../components/React/history';
import { request } from '../api/RequestAPI';

class AuthService {

    async logout(newEnv?: string): Promise<void> {
        const token = localStorage.getItem('accessToken');

        if (token) {
            await request(`/oauth2/token?${encodeURIComponent('accessToken')}=${encodeURIComponent(token)}`, 'delete');
        }

        localStorage.clear();

        if (newEnv) {
            localStorage.setItem('tmpEnv', newEnv);
            history.push('/login');
        } else {
            history.push('/environment');
        }
    }
}

export default new AuthService();
