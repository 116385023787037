import * as React from 'react';
import cn from 'classnames';

import ClickableSidebarItem from 'components/Sidebar/SidebarItem/ClickableSidebarItem';
import GeneralSidebar, {IProps as GeneralSidebarProps} from 'components/Sidebar/GeneralSidebar';
import OutsideClickHandler from 'components/React/OutsideClickHandler';
import SidebarItemIcon from 'components/Sidebar/SidebarItem/SidebarItemIcon';

import './MobileSidebar.sass';


interface IProps extends GeneralSidebarProps {
    className?: string;
}

interface IState {
    open: boolean;
}

class MobileSidebar extends React.Component<IProps> {
    state: IState = {
        open: false
    };

    private handleExpandBtnClick(e: React.MouseEvent<HTMLElement>) {
        const {open} = this.state;
        e.preventDefault();

        this.setState({open: !open});
    }

    private closeSidebar() {
        this.setState({open: false});
    }

    render() {
        const {className, ...generalSidebarProps} = this.props;
        const {open} = this.state;

        return (
            <React.Fragment>
                <OutsideClickHandler className={cn('SidebarOutsideClickHandler', {open})}
                                     onHandleClickOutside={() => this.closeSidebar()}>
                    <GeneralSidebar onItemClick={() => this.closeSidebar()}
                                    className={cn({open})} {...generalSidebarProps}/>
                </OutsideClickHandler>
                <aside className={cn('MobileSidebar', className)}>
                    <ClickableSidebarItem onClick={(e) => this.handleExpandBtnClick(e)}>
                        <SidebarItemIcon src={'/assets/icons/bars.svg'}/>
                    </ClickableSidebarItem>
                </aside>
            </React.Fragment>
        );
    }
}

export default MobileSidebar;
