import { request } from '../../../api/RequestAPI';
import { ListStore } from '../ListStore';
import { IHeatmap } from '../../../typings/src/trips/ITrips';
import { ITripsStatistics, ILocation2, IDistanceCluster } from '../../../typings/src/trips/ITripsStatistics';

import utils from '../../../utils';
import modalStore from '../ModalStore';

export class TripsStatisticsStore extends ListStore<ITripsStatistics> {
    continuationToken: string;

    csvLoading: boolean;
    heatmapData: IHeatmap[] = [];
    preferredTableFilter = ['Start Date', 'End Date', 'Start Location', 'End Location', 'Duration (seconds)', 'Distance (meters)', 'Total Score', 'Points Earned'];

    timezone: string = 'America/Los_Angeles';

    constructor() {
        super('/trips/statistics', '', 'tripsStatistics');
        this.preload();
    }

    async loadFromSearch(): Promise<void> {
        this.items = [];
        this.continuationToken = undefined;
        await this.getTrips();
    }

    updatePreferredTableFilter(): void {
        this.reduxActions.setCustom('preferredTableFilter', this.preferredTableFilter);
    }

    saveTripsData(): void {
        this.reduxActions.setItems(this.items);
        this.reduxActions.setCustom('continuationToken', this.continuationToken);
        this.reduxActions.setCustom('params', this.params);
        this.reduxActions.setCustom('heatmapData', this.heatmapData);
    }

    async loadTrips(isInitialLoad: boolean = false): Promise<void> {
        if (isInitialLoad && this.items.length) {
            this.isLoading = false;
            this.reduxActions.update();
            return;
        }
        await this.getTrips();
    }

    async getTrips(): Promise<void> {
        this.isLoading = true;
        this.error = null;
        this.reduxActions.update();

        let tempHeader = {};
        if (this.continuationToken) {
            tempHeader = { continuation: this.continuationToken };
        }

        let pageSearchParams = utils.paramsToQuery(this.params);
        if (this.params && this.params.filter) {
            const filterObj = utils.searchToParams(`?${this.params.filter}`, false);
            if (filterObj['from']) {
                filterObj['from'] = utils.convertLocalDateToUTC(filterObj['from'], this.timezone);
            }
            if (filterObj['to']) {
                filterObj['to'] = utils.convertLocalDateToUTC(filterObj['to'], this.timezone);
            }
            if (filterObj['timezone']) {
                delete filterObj['timezone'];
            }
            pageSearchParams = utils.paramsToPath('', filterObj);
        }

        try {
            const { data, headers } = await request(`${this.path}${pageSearchParams}`, 'get', {
                headers: tempHeader,
                getHeader: 'continuation' }
            );

            this.items = this.items.concat(data);

            this.continuationToken = headers;
        } catch (error) {
            this.error = error;
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
            this.initHeatmap();
            this.saveTripsData();
        }
    }

    async updateTrips(itemsCount: number = 0, continuationToken?: string, trips?: ITripsStatistics[]): Promise<void> {
        if (!this.items || !this.items.length) {
            this.saveTripsData();
            return;
        }

        if (itemsCount >= this.items.length) {
            this.continuationToken = continuationToken;
            this.initHeatmap();
            this.reduxActions.update();
            return;
        }

        let tempHeader = {};
        if (this.continuationToken) {
            tempHeader = { continuation: this.continuationToken };
        }

        const pageSearchParams = utils.paramsToPath('', this.params);

        try {
            const { data, headers } = await request(`${this.path}${pageSearchParams}`, 'get', {
                headers: tempHeader,
                getHeader: 'continuation'
                }
            );
            if (headers) {
                const tempData = trips ? trips.concat(data) : data;
                return await this.updateTrips(tempData.length, headers, tempData);
            }
        } catch (error) {
            this.error = error;
        }
    }

    async downloadCsvFile() {
        this.csvLoading = true;
        this.reduxActions.update();

        const pageSearchParams = utils.paramsToPath('', this.params);

        try {
            let url = `/trips/statistics/csv${pageSearchParams}`;
            const csvBlob = await request(url, 'get', { isBlob: true });
            const csvUrl = URL.createObjectURL(csvBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = csvUrl;
            downloadLink.setAttribute('download', 'trips-statistics.csv');
            downloadLink.click();
        } catch (error) {
            modalStore.showError(error);
        } finally {
            this.csvLoading = false;
            this.reduxActions.update();
        }
    }

    initHeatmap() {
        this.heatmapData = [];
        this.reduxActions.update();
        const locations: ILocation2[] = this.items.map(trip => trip.endLocation.location);
        let clusters: IDistanceCluster[] = this.combineLocationsWithDistance(locations);
        let heatmapPositions: IHeatmap[] = [];

        for (const endTrip of clusters) {
            heatmapPositions.push(
                {
                    lat: endTrip.point.lat,
                    lng: endTrip.point.lon,
                    weight: endTrip.intensity
                },
            );
        }
        this.heatmapData = heatmapPositions;
        this.reduxActions.update();
    }


    rad(x): number {
        return x * Math.PI / 180;
    }

    getDistance(p1: ILocation2, p2: ILocation2): number {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = this.rad(p2.lat - p1.lat);
        const dLong = this.rad(p2.lon - p1.lon);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d; // returns the distance in meter
    }

    combineLocationsWithDistance(locations: ILocation2[], newLocations: IDistanceCluster[] = [], distance: number = 100): IDistanceCluster[] {
        if (!locations.length) {
            return newLocations;
        }

        let newLocation: IDistanceCluster = {
            point: locations[0],
            intensity: 1
        };
        locations.splice(0, 1);

        let deleteLocations: number[] = [];
        for (let i = 0; i < locations.length; i++) {
            if (Math.abs(this.getDistance(newLocation.point, locations[i])) < distance) {
                deleteLocations.push(i);
                newLocation.intensity += 1;
            }
        }

        deleteLocations.sort((a, b) => b - a);
        for (const delIndex of deleteLocations) {
            locations.splice(delIndex, 1);
        }

        return this.combineLocationsWithDistance(locations, newLocations.concat(newLocation));
    }

    changeTimezone (timezone) {
        this.timezone = timezone;
        this.reduxActions.setCustom('timezone', this.timezone);
    }
}
