import React, { Component } from 'react';
import cn from 'classnames';

import './FilterWrapper.scss';

interface IFilterWrapperProps {
    className?: string;
}

export default class FilterWrapper extends Component<IFilterWrapperProps> {
    render() {
        const { children, className } = this.props;

        return (
            <div className={cn('FilterWrapper', className)}>
                {children}
            </div>
        );
    }
}
