import * as React from 'react';
import cn from 'classnames';

import OverlayLoader from 'components/Layout/OverlayLoader/OverlayLoader';

import { CampaignAttributionMapStore } from '../../../redux/stores/CampaignAttributionMap/CampaignAttributionMapStore';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ChangeEventValue, Coords, Point, Bounds } from 'google-map-react';
import { GoogleMapShowEnum } from '../../../components/GoogleMap/GoogleMap';
import { IMerchant, ILocation } from '../../../typings/src/campaignAttribution/ICampaignAttribution';

import Marker from '../../../components/Layout/Map/Marker';
import FilterWrapper from '../../../components/Layout/Filter/FilterWrapper';
import UpperText from '../../../components/Layout/UpperText/UpperText';

import Filter from '../../../components/List/Filter';
import GoogleMapWithUrl from '../../../components/GoogleMap/GoogleMapWithUrl';
import supercluster from 'points-cluster';
import MapMarker from '../../../components/Layout/Map/MapMarker';
import CampaignAttributionMapTolltip from './CampaignAttributionMapTolltip';
import ExpandableDropdown from '../../../components/Layout/Dropdown/ExpandableDropdown';

import utils from '../../../utils';

import '../../Layout/Table/Table.sass';
import './CampaignAttributionMap.scss';

const CAMPGAIN_ATTRIBUTION_MAP_POSITION = 'CAMPGAIN_ATTRIBUTION_MAP_POSITION';

const MAX_ZOOM_TO_LOAD = 15;
const CLUSTER_ZOOM_LIMIT = 17;

interface ClusterOptions {
    zoom?: number;
    scale?: boolean;
}

interface ILocationBubblesCoords extends Coords {
    numberOfVisits?: number;
}

interface ILocationBubbles extends Point {
    numPoints: number;
    points: ILocationBubblesCoords[];
    wx: number;
    wy: number;
    zoom: number;
}

interface Props {
    className?: string;
}

interface State {
    center: any;
    zoom: any;
    bounds?: any;
    isMapLoading: boolean;
    locations: ILocationBubbles[];
    allLocationVisits: ILocationBubbles[];
    merchantsWithVisits: (IMerchant[])[];
    scale?: any;
    tooltip?: IMerchant[];
}

export class CampaignAttributionMap extends React.Component<Props & RouteComponentProps<{}>, State> {
    store = new CampaignAttributionMapStore();
    googleMapRef: React.RefObject<any>;
    state: State = {
        isMapLoading: true,
        locations: [],
        allLocationVisits: [],
        merchantsWithVisits: [],
        center: {
            lat: 33.83,
            lng: -118.31
        },
        tooltip: [],
        zoom: 10
    };

    constructor(props) {
        super(props);

        let storedLocation = localStorage.getItem(CAMPGAIN_ATTRIBUTION_MAP_POSITION);
        if (storedLocation) {
            let parsed = JSON.parse(storedLocation);
            this.store.setCenter(parsed.center);
            this.store.setZoom(parsed.zoom);
        }
    }

    async componentDidMount(): Promise<void> {
        await this.initialLoad();
    }

    async initialLoad(): Promise<void> {
        const searchParams = utils.searchToParams(this.props.location.search, false);

        this.store.params = Object.assign(
            this.store.params,
            searchParams
        );

        this.store.setupParams();
        await this.store.loadCategories();
    }

    async componentWillReceiveProps(props: Props & RouteComponentProps<{}>) {
        if (props.location.search !== this.props.location.search) {
            this.store.reduxActions.setCustom('params', this.store.params);
            const tempMerchantsWithVisitsFiltered = [].concat(this.store.merchantsWithVisitsFiltered);
            this.store.merchantsWithVisitsFiltered = [];
            this.store.merchantsWithVisits = [];
            this.store.allLocationVisits = [];
            this.setState({ tooltip: [] });
            await this.store.getMerchantLocationVisits(tempMerchantsWithVisitsFiltered);
            await this.init();
        }
    }

    async init(): Promise<void> {

        const { zoom, bounds, center } = this.state;

        if (zoom < MAX_ZOOM_TO_LOAD) {
            await this.store.getAllLocationVisits(bounds);
            this.initPossibleLocationVisists();
        } else {
            let allMerchants = await this.store.getMechants(center, zoom - 1);
            await this.store.getMerchantLocationVisits(allMerchants);
            this.initMerchantVisitsBubbles();
        }
    }

    initMerchantVisitsBubbles(): void {
        if (!this.store.merchantsWithVisitsFiltered || !this.store.merchantsWithVisitsFiltered.length) {
            this.setState({ locations: [] });
            return;
        }

        const { zoom } = this.state;

        if (zoom >= CLUSTER_ZOOM_LIMIT) {
            let { merchantsWithVisits } = this.state;
            if (merchantsWithVisits.flat().length === this.store.merchantsWithVisitsFiltered.length) {
                return;
            }
            merchantsWithVisits = this.store.merchantsWithVisitsFiltered.reduce(
                (unique, value) => {
                    const index = unique.findIndex(u => (u[0].latitude === value.latitude && u[0].longitude === value.longitude));
                    if (index !== -1) {
                        unique[index] = [...unique[index], value];
                    } else {
                        unique.push([value]);
                    }
                    return unique;
            },  [] as (IMerchant[])[]);
            this.setState({ merchantsWithVisits });
        } else {
            const locations = this.getClusterPoints(this.store.merchantsWithVisitsFiltered);
            this.setState({ locations });
        }
    }

    initPossibleLocationVisists(): void {
        if (!this.store.allLocationVisits || !this.store.allLocationVisits.length) {
            this.setState({ allLocationVisits: [] });
            return;
        }
        const allLocationVisits = this.getClusterPoints(this.store.allLocationVisits);
        this.setState({ allLocationVisits });
    }

    zoomToClusterBreakingPoint(item: ILocationBubbles, index: number, zoom: number): void {
        if (item.numPoints && item.numPoints === 1) {
            this.googleMapRef.current.map_.panTo({ lat: item.y, lng: item.x });
            this.googleMapRef.current.map_.setZoom(17);
            return;
        }

        const tempItem: ILocationBubbles = Object.assign({}, item);

        let newList: ILocation[] = tempItem.points.map((point) => { return { latitude: Number(point.lat), longitude: Number(point.lng) }; });
        const locations = this.getClusterPoints(
            newList,
            {
                zoom,
                scale: false
            }
        );

        if (locations.length === 1) {
            this.zoomToClusterBreakingPoint(tempItem, index, zoom + 1);
        } else {
            this.googleMapRef.current.map_.panTo({ lat: tempItem.y, lng: tempItem.x });
            this.googleMapRef.current.map_.setZoom(zoom);
        }
    }

    getClusterPoints(data: any[], options: ClusterOptions = { scale: true }): ILocationBubbles[] {
        if (!data || !data.length) {
            return [];
        }

        const shouldDisplayVisits = data[0].hasOwnProperty('numberOfVisits');

        const { zoom, bounds } = this.state;
        let newList: Coords[] = [];

        if (shouldDisplayVisits) {
            newList = data.map((item) => { return { lat: Number(item.latitude), lng: Number(item.longitude), numberOfVisits: item.numberOfVisits }; });
        } else {
            newList = data.map((item) => { return { lat: Number(item.latitude), lng: Number(item.longitude) }; });
        }

        let cluster = supercluster(newList);
        let tempbound = Object.assign({}, bounds);
        tempbound = this.adaptBounds(tempbound);

        let values: number[] = [];
        let clusterData: ILocationBubbles[] = [];

        clusterData = cluster({
            bounds: tempbound,
            zoom: options && options.zoom ? options.zoom : zoom
        });

        if (!options.scale) {
            return clusterData;
        }

        if (shouldDisplayVisits) {
            clusterData.map(item => values.push(item.points.map(point => point.numberOfVisits).reduce((a, b) => a + b), 0));
        } else {
            clusterData.map(item => values.push(item.numPoints));
        }

        this.setState({
            scale: {
                min: Math.min(...values),
                max: Math.max(...values)
            }
        });

        return clusterData;
    }

    async onTooltipOpened(merchants: IMerchant[]): Promise<void> {
        this.setState({ tooltip: merchants });
        this.googleMapRef.current.map_.panTo({ lat: merchants[0].latitude, lng: merchants[0].longitude });
    }

    async onPossibleLocationsClick(item: ILocationBubbles, e: React.MouseEvent<HTMLDivElement>): Promise<void> {
        this.googleMapRef.current.map_.panTo({ lat: item.y, lng: item.x });
        this.googleMapRef.current.map_.setZoom(MAX_ZOOM_TO_LOAD);
    }

    async onMapChange(event: ChangeEventValue): Promise<void> {
        const { center, zoom, bounds } = event;
        this.setState({ isMapLoading: false, center, zoom, bounds });
        if (zoom < CLUSTER_ZOOM_LIMIT) {
            this.setState({ tooltip: [] });
        }
        this.store.setZoom(zoom);
        this.store.setCenter(center);
        this.initMerchantVisitsBubbles();
        await this.init();
    }

    render() {
        const isFullScreen = this.isMapInFullScreen();

        const { zoom, merchantsWithVisits } = this.state;

        return (
            <div>
                <FilterWrapper>
                    <UpperText className='Header-upperText' text='From Date'>
                        <Filter field='from' store={this.store} type='date picker' />
                    </UpperText>

                    <UpperText className='Header-upperText' text='To Date'>
                        <Filter field='to' store={this.store} type='date picker' />
                    </UpperText>

                    <UpperText className='Header-upperText' text='Categories'>
                        <ExpandableDropdown
                            filter={true}
                            checkbox={true}
                            data={this.store.categoryDropdown}
                            text='Filter'
                            onSelectAll={(type) => {
                                this.store.allSelectionChanged(type);
                                this.initMerchantVisitsBubbles();
                                const { tooltip } = this.state;
                                if (tooltip && !!tooltip.length) {
                                    this.onTooltipOpened(tooltip);
                                }
                            }}
                            onDataChangeClick={(id, value) => {
                                this.store.handleDropdownChange('category', value);
                                this.initMerchantVisitsBubbles();
                                const { tooltip } = this.state;
                                if (tooltip && !!tooltip.length) {
                                    this.onTooltipOpened(tooltip);
                                }
                            }}
                        ></ExpandableDropdown>
                    </UpperText>

                </FilterWrapper>

                <div className={cn('CampaignAttributionMap', `${isFullScreen ? 'gm-fullscrean' : ''}`)}>

                    <GoogleMapWithUrl
                        onChange={(event: ChangeEventValue) => this.onMapChange(event)}
                        mounted={(ref) => this.googleMapRef = ref}
                    >

                        {/* All possible location visits - green dots */}
                        {zoom < MAX_ZOOM_TO_LOAD && this.state.allLocationVisits && !!this.state.allLocationVisits.length && this.state.allLocationVisits.map((item, i) => (
                            <MapMarker
                                className='bubble-green cursor-pointer'
                                key={`cluster_${i}`}
                                lat={item.y}
                                lng={item.x}
                                scale={this.state.scale}
                                numPoints={item.numPoints}
                                hideNumPoints={true}
                                onClick={(e) => this.onPossibleLocationsClick(item, e)}
                            />
                        ))}

                        {/* Merchants with location visits as clusters */}
                        {zoom >= MAX_ZOOM_TO_LOAD && this.state.zoom < CLUSTER_ZOOM_LIMIT && this.state.locations && !!this.state.locations.length && this.state.locations.map((item, i) => (
                            <MapMarker
                                className='cursor-pointer'
                                key={`cluster_${i}`}
                                lat={item.y}
                                lng={item.x}
                                scale={this.state.scale}
                                numPoints={item.points.map(loc => loc.numberOfVisits).reduce((a, b) => a + b, 0)}
                                onClick={() => this.zoomToClusterBreakingPoint(item, i, this.state.zoom)}
                            />
                        ))}

                        {/* Each merchants with location visits */}
                        {zoom >= MAX_ZOOM_TO_LOAD && this.state.zoom >= CLUSTER_ZOOM_LIMIT && !!merchantsWithVisits.length && merchantsWithVisits.map((merchants, index) =>
                            <Marker
                                mapOptions={{ show: GoogleMapShowEnum.ZOOM }}
                                key={index}
                                lat={merchants[0].latitude}
                                lng={merchants[0].longitude}
                                imageUrl={''}
                                toogleChildren='toogle'
                                numPoints={merchants.reduce((sum, merchant) => sum + merchant.numberOfVisits, 0)}
                                onTooltipOpen={() => this.onTooltipOpened(merchants)}
                            />
                        )}

                        {zoom >= MAX_ZOOM_TO_LOAD && this.state.tooltip && this.state.tooltip.length &&
                            <CampaignAttributionMapTolltip
                                lat={this.state.tooltip[0].latitude}
                                lng={this.state.tooltip[0].longitude}
                                onClose={() => this.setState({ tooltip: undefined })}
                                store={this.store}
                                merchants={this.state.tooltip}
                            />
                        }

                    </GoogleMapWithUrl>

                    <OverlayLoader isLoading={ this.state.isMapLoading } />
                </div>
            </div>
        );
    }

    isMapInFullScreen = () => {
        return document.fullscreen;
    }

    adaptBounds(bounds: Bounds): Bounds {
        const diff = 0.5;

        Object.keys(bounds).map(bound => {
            switch (bound) {
                case 'nw':
                    bounds[bound].lat += diff;
                    bounds[bound].lng -= diff;
                    break;
                case 'se':
                    bounds[bound].lat -= diff;
                    bounds[bound].lng += diff;
                    break;
                case 'sw':
                    bounds[bound].lat -= diff;
                    bounds[bound].lng -= diff;
                    break;
                case 'ne':
                    bounds[bound].lat += diff;
                    bounds[bound].lng += diff;
                    break;

                default:
            }
        });

        return bounds;
    }
}

const mapStateToProps = state => ({
    merchants: state.merchants
});

export default withRouter( connect(mapStateToProps)(CampaignAttributionMap) );
