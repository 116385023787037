import React, { Component } from 'react';
import * as H from 'history';
import moment from 'moment';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { IReport } from '../../../typings/src/reports/IReport';

import Button from 'components/Layout/Button/Button';
import Content from 'components/Content/Content';
import Header from 'components/Content/Header/Header';
import Paper from 'components/Layout/Paper';
import ListStatus from '../../List/ListStatus';
import HorizontalScroll from '../../Layout/HorizontalScroll';

import ReportsStore from '../../../redux/stores/Reports/ReportsStore';
import utils from '../../../utils';

import './ReportsList.scss';

export interface Props {
    history: H.History;
}

class ReportsList extends Component<Props & RouteComponentProps<{}>> {
    store = new ReportsStore();
    refreshInterval: NodeJS.Timeout;

    componentDidMount(): void {
        this.load();
        this.refreshInterval = setInterval(() => this.store.loadSilently(), 1000 * 5);
    }

    componentWillUnmount(): void {
        clearInterval(this.refreshInterval);
    }

    async load(): Promise<void> {
        await this.store.load();
    }

    formatDate(date: string): string {
        if (!date) {
            return '';
        }
        return moment(date).format('MM/DD/YYYY  HH:mm');
    }

    getStartedByText(starterId: string): string {
        if (starterId === 'CRON_TASK') {
            return 'CRON TASK';
        }

        const found = this.store.users.find(usr => usr.id === starterId);
        if (found && found.displayName) {
            return found.displayName;
        }

        return 'Unknown';
    }

    getReportStatusText(report: IReport): string {
        if (this.didReportFailed(report)) {
            return 'Something went wrong';
        }
        return report.statusText ? report.statusText : 'Unknown';
    }

    didReportFailed(report: IReport): boolean {
        return (report.isProcessing === false && report.startTs !== null && report.stopTs === null);
    }

    getTableNames(tableNames: string | string[]): string {
        if (typeof tableNames === 'string') {
            return tableNames;
        } else if (Array.isArray(tableNames)) {
            return tableNames.map(name => name).join(', ');
        }
        return '';
    }

    render() {
        const url = this.props.match.url;
        const { items } = this.store;

        const header = <Header><h1>Reports</h1></Header>;

        return (
            <Content header={header} className={cn('AdManagementPage')}>
                <Paper className='Content-paper' type='wide'>

                    <HorizontalScroll>
                        <table className='Table table table-list'>

                            <thead>
                                <tr className='headerRow'>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Started by</th>
                                    <th>Started At</th>
                                    <th>Finished At</th>
                                    <th>Rows In Report</th>
                                    <th>Table Names</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {items && !!items.length && items.map((report, index, arr, colIndex = -1) =>
                                    <tr key={report.id}>
                                        <td>
                                            <NavLink className='row-link' to={`${url}/${report.name}`}>{report.name}</NavLink>
                                        </td>
                                        <td className='large-cell'>{report.description}</td>
                                        <td>{this.getStartedByText(report.starterId)}</td>
                                        <td>{this.formatDate(report.startTs)}</td>
                                        <td>{this.formatDate(report.stopTs)}</td>
                                        <td>{utils.numberWithCommas(report.rowsInReport)}</td>
                                        <td>{this.getTableNames(report.tableName)}</td>
                                        <td>{this.getReportStatusText(report)}</td>
                                        <td className='d-flex table-buttons'>
                                            <Button className={cn('', report.isProcessing ? 'disabled' : '')} type='primary' onClick={() => this.store.generateReport(report.name)}>Start</Button>
                                            <Button type='primary' onClick={() => this.store.downloadCsvFile(report.name)} onFetchDisabled={true}>Download</Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </HorizontalScroll>

                    <ListStatus store={this.store} emptyText='reports' />

                </Paper>
            </Content>
        );
    }
}

const mapStateToProps = state => ({
    reports: state.reports
});

export default connect(mapStateToProps)(ReportsList);
