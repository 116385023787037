import * as React from 'react';
import cn from 'classnames';
import supercluster from 'points-cluster';
import GoogleMapReact, { Coords, Point, ChangeEventValue, Props as GoogleMapProps } from 'google-map-react';

import MapMarker from '../../components/Layout/Map/MapMarker';
import OverlayLoader from '../../components/Layout/OverlayLoader/OverlayLoader';
import { ICoords } from '../../api/AdvertisersServiceAPI';

import './GoogleMap.scss';

const ADVERTISERS_MAP_POSITION = 'ADVERTISERS_MAP_POSITION';
const DEFAULT_SHOW_ZOOM = 14;

// View options
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let timeOptions = [];
let date = new Date();
date.setUTCDate(1);
for (let i = 1; i <= 12; i++) {
    timeOptions.push({
        id: date.getUTCMonth() + 1,
        value: `${months[date.getUTCMonth()]} ${date.getFullYear()}`,
        date: new Date(date)
    });
    date.setUTCMonth(date.getUTCMonth() - 1);
}

/*
    Show children depending on map zoom
    If property is not added ALWAYS is taken as default
*/
export enum GoogleMapShowEnum {
    ALWAYS = 1,
    ZOOM = 2
}

export interface IMapChildren {
    show?: GoogleMapShowEnum;
}

interface ILocationBubbles extends Point {
    numPoints: number;
    points: Coords[];
    wx: number;
    wy: number;
    zoom: number;
}

interface Props {
    className?: string;
    mapProps?: GoogleMapProps;
    locations?: Coords[];
    onChange?: (changeEvent: ChangeEventValue) => void;
}

interface MapState {
    center: any;
    zoom: any;
    bounds?: any;
    view?: any;
    scale?: any;
}

interface State extends MapState {
    isMapLoading: boolean;
    locations: ILocationBubbles[];
}

export class GoogleMap extends React.Component<Props, State> {
    state: State = {
        isMapLoading: true,
        locations: [],
        center: {
            lat: 33.83,
            lng: -118.31
        },
        zoom: 10
    };

    constructor(props) {
        super(props);
        this.setState({ view: timeOptions[0] });
        let storedLocation = localStorage.getItem(ADVERTISERS_MAP_POSITION);
        if (storedLocation) {
            let parsed = JSON.parse(storedLocation);
            this.state.center = parsed.center;
            this.state.zoom = parsed.zoom;
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps && nextProps.locations && nextProps.locations.length !== this.state.locations.length) {
            this.setupLocationBubbles();
        }
    }

    setupLocationBubbles() {
        const { zoom } = this.state;
        if (zoom > DEFAULT_SHOW_ZOOM) {
            this.setState({ locations: [] });
            return;
        }
        this.loadLocationsAsBubbles();
    }

    async loadLocationsAsBubbles(): Promise<void> {
        if (!this.props.locations) {
            return;
        }

        let { zoom, bounds } = this.state;
        let list: Coords[] = [];
        let newList: Coords[] = [];

        list = this.props.locations;
        newList = list.map((item) => { return { lat: Number(item.lat), lng: Number(item.lng) }; });
        let cluster = supercluster(newList);

        let locations: ILocationBubbles[] = [];
        locations = cluster({ bounds, zoom });

        let values: number[] = [];
        locations.map(item => values.push(item.numPoints));

        this.setState({
            locations,
            scale: {
                min: Math.min(...values),
                max: Math.max(...values)
            }
        });
    }

    isMapInFullScreen(): boolean {
        return document.fullscreen;
    }

    onChange = async (value: ChangeEventValue) => {
        const { center, zoom, bounds } = value;
        localStorage.setItem(ADVERTISERS_MAP_POSITION, JSON.stringify({ center, zoom }));
        await this.setState({ center, zoom, bounds });
        this.setupLocationBubbles();
        this.setState({ isMapLoading: false });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    handleChildren(child: any): any {
        if (child && child.props && child.props.mapOptions) {
            const { mapOptions } = child.props;
            if (mapOptions.show) {
                const { zoom } = this.state;

                switch (mapOptions.show as GoogleMapShowEnum) {
                    case GoogleMapShowEnum.ZOOM:
                        if (zoom > DEFAULT_SHOW_ZOOM) {
                            return child;
                        } else {
                            return null;
                        }
                    case GoogleMapShowEnum.ALWAYS:
                    default:
                        return child;
                }
            }
        }
        return child;
    }

    render() {
        const isFullScreen = this.isMapInFullScreen();

        const { mapProps, children } = this.props;
        const { center, zoom, locations, isMapLoading } = this.state;

        return (
            <div className={cn('GoogleMap', `${isFullScreen ? 'gm-fullscrean' : ''}`)}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyATqXlZ7pFyKqsSuMyFecSmNiL70UCX5W8' }}
                    center={center}
                    zoom={zoom}
                    onChange={this.onChange}
                    onChildClick={this.markerClick.bind(this)}
                    {...mapProps}
                >
                    {locations.map((item, i) => (
                        <MapMarker
                            key={`cluster_${i}`}
                            lat={item.y}
                            lng={item.x}
                            numPoints={item.numPoints}
                            scale={this.state.scale} />
                        ))
                    }
                    {React.Children.map(children, (child) => { return this.handleChildren(child); })}
                </GoogleMapReact>
                <OverlayLoader isLoading={isMapLoading} />
            </div>
        );
    }

    markerClick(key, props) {

        if (typeof (key) === 'string' && key.includes('cluster_')) {
            let center = {
                lat: props.location.y,
                lng: props.location.x
            };
            let zoom = this.state.zoom + 2;
            if (zoom > DEFAULT_SHOW_ZOOM) {
                this.setState({ locations: [] });
            }
            this.setState({ center, zoom });
        }
    }
}

export default GoogleMap;
