import { ListStoreWithPagination } from '../ListStoreWithPagination';
import { IAdvertiser } from '../../../api/AdvertisersServiceAPI';

export default class AdvertisersStore extends ListStoreWithPagination<IAdvertiser> {

    constructor() {
        super('/radio/advertiser', '/list', 'advertisers');
        this.hiddenParams = {
            sort: 'name',
            desc: false
        };
        this.preload();
    }
}
