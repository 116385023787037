// Dummy validations
import { IValidationErrors, ValidationErrorCode, IValidationErrorData } from '../errors/ValidationError';

function ensureString(value: string | undefined | null, trim: boolean): string {
    const val = value ? value.toString() : '';
    if (trim) {
        return val.trim();
    }
    return val;
}

function addValidationError<T>(errors: IValidationErrors, field: string, error: IValidationErrorData): void {
    let errorList = errors[field];
    if (errors.hasOwnProperty(field)) {
        return;
    }
    if (!errorList) {
        errorList = errors[field] = [];
    }
    errorList.push(error);
}

// Utils
function getNestedObjectByString<T>(nestedObj: T, path: string): T {
    let pathArr = path.split('.');
    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined,
                          nestedObj);
}

export default {

    // Validation functions
    require: function<T>(contract: T | undefined, field: string, errors: IValidationErrors, trim: boolean = true): boolean {
        const value = contract ? contract[field] : void 0;

        if (typeof value === 'number' && !isNaN(value)) {
            return false;
        }

        const val = typeof value === 'string' ? ensureString(value, trim) : value;

        if (!val) {
            addValidationError(
                errors,
                field as string,
                { code: ValidationErrorCode.RequireError }
            );
            return true;
        }
        return false;
    },

    url: function<T>(contract: T | undefined, field: string, errors: IValidationErrors, trim: boolean = true): boolean {

        const value = contract ? getNestedObjectByString(contract, field) : void 0;
        const val = typeof value === 'string' ? ensureString(value, trim) : value;

        if ((typeof val !== 'string') || val.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm) === null) {
            addValidationError(
                errors,
                field,
                { code: ValidationErrorCode.UrlError}
            );
            return true;
        }

        return false;
    },

    stringOrNull: function <T>(contract: T | undefined, field: string, errors: IValidationErrors, trim: boolean = true): void {
        let value = contract ? contract[field] : void 0;

        if (typeof value === 'string') {
            if (value.length === 0) {
                contract[field] = null;
            }
        } else if (value && value !== null) {
            addValidationError(
                errors,
                field,
                { code: ValidationErrorCode.stringOrNull }
            );
        }
    },

    googleMapsUrlLocation: function(contract: string, field: string, errors: IValidationErrors, trim: boolean = true): boolean {
        const decimalRegexExpression = /^(-)?\d*\.?\d*$/g;

        if (!contract || !contract.length || contract[0] !== '@' || contract[contract.length - 1] !== 'z') {
            addValidationError(
                errors,
                field,
                { code: ValidationErrorCode.UrlError }
            );
            return true;
        }

        contract = contract.substring(1, contract.length);
        contract = contract.substring(0, contract.length - 1);
        const splitted = contract.split(',');

        if (splitted.length !== 3) {
            addValidationError(
                errors,
                field,
                { code: ValidationErrorCode.UrlError }
            );
            return true;
        }

        [splitted[0], splitted[1], splitted[2]].forEach(value => {
            if (value.match(decimalRegexExpression) === null) {
                addValidationError(
                    errors,
                    field,
                    { code: ValidationErrorCode.UrlError }
                );
                return true;
            }
        });

        return false;
    }
};
