/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

interface IEnviromentConfig {
    develop: Environment[];
    production: Environment[];
}

export const Environments: IEnviromentConfig = {
    develop: [
        {
            id: 'develop-on-trail',
            config: 'develop',
            value: 'On Trail (dev)',
            client: 'on-trail',
            pages: [
                {
                    path: '/trips-statistics',
                    name: 'Trips Statistics',
                    type: 'tripsStatistics'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                }
            ]
        },
        {
            id: 'develop-usaa',
            config: 'develop',
            value: 'USAA (dev)',
            client: 'usaa',
            pages: [
                {
                    path: '/trips-statistics',
                    name: 'Trips Statistics',
                    type: 'tripsStatistics'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/points-insight',
                    name: 'Points Insight',
                    type: 'pointsInsight'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                }
            ]
        },
        {
            id: 'develop-hyperradio',
            config: 'develop',
            value: 'Radio Rewards (dev)',
            client: 'hyperradio',
            pages: [
                {
                    path: '/advertisers',
                    name: 'Advertisers',
                    type: 'advertisers'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/points-insight',
                    name: 'Points Insight',
                    type: 'pointsInsight'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                },
                {
                    path: '/program-logs',
                    name: 'Program Logs',
                    type: 'programLogs'
                }
            ]
        },
        {
            id: 'develop-attribution',
            config: 'develop',
            value: 'Attribution (dev)',
            client: 'attribution',
            pages: [
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                },
            ]
        },
        {
            id: 'develop-life-rewards',
            config: 'develop',
            value: 'Life Rewards (dev)',
            client: 'life-rewards',
            pages: [
                {
                    path: '/trips-statistics',
                    name: 'Trips Statistics',
                    type: 'tripsStatistics'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/points-insight',
                    name: 'Points Insight',
                    type: 'pointsInsight'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                }
            ]
        },
    ],

    production: [
        {
            id: 'on-trail',
            config: 'ontrail',
            value: 'On Trail',
            pages: [
                {
                    path: '/trips-statistics',
                    name: 'Trips Statistics',
                    type: 'tripsStatistics'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                }
            ]
        },
        {
            id: 'usaa',
            config: 'usaa',
            value: 'USAA',
            pages: [
                {
                    path: '/trips-statistics',
                    name: 'Trips Statistics',
                    type: 'tripsStatistics'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/points-insight',
                    name: 'Points Insight',
                    type: 'pointsInsight'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                }
            ]
        },
        {
            id: 'hyperradio',
            config: 'hyperradio',
            value: 'Radio Rewards',
            pages: [
                {
                    path: '/advertisers',
                    name: 'Advertisers',
                    type: 'advertisers'
                },
                {
                    path: '/reports',
                    name: 'Reports',
                    type: 'reports'
                },
                {
                    path: '/points-insight',
                    name: 'Points Insight',
                    type: 'pointsInsight'
                },
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                },
                {
                    path: '/campaign-attribution',
                    name: 'Campaign Attribution',
                    type: 'campaignAttribution'
                },
                {
                    path: '/location-insights',
                    name: 'Location Insight',
                    type: 'locationInsight'
                },
                {
                    path: '/program-logs',
                    name: 'Program Logs',
                    type: 'programLogs'
                }
            ]
        },
        {
            id: 'life-rewards',
            config: 'life-rewards',
            value: 'Life Rewards',
            client: 'life-rewards',
            pages: [
                {
                    path: '/data-dictionary',
                    name: 'Data Dictionary',
                    type: 'dataDictionary'
                }
            ]
        }
    ]
};

export interface Environment {
    config: string;
    id: string;
    value: string;
    pages: PageComponent[];
    client?: string;
}

export interface PageComponent {
    path: string;
    name: string;
    type: string;
}
