import { ODataStore } from '../ODataStore';
import { ISingleReport, IReportCSV } from '../../../typings/src/reports/IReport';
import { request } from '../../../api/RequestAPI';

import ModalStore from '../ModalStore';
import utils from '../../../utils';

export default class SingleReportStore extends ODataStore<ISingleReport> {
    csvLoading: boolean;

    constructor(reportName: string, hiddenFilter: string[]) {
        super(`/odata/${reportName}Export`, '', `Reporting${reportName}Export`, hiddenFilter);
        this.preload();
    }

    async downloadCsvFile(reportName: string): Promise<void> {
        this.csvLoading = true;
        this.reduxActions.update();

        try {
            const exportFileInfo: IReportCSV = await request(`/reporting/report/${reportName}`, 'get');
            await utils.downloadAppendableBlob(exportFileInfo);
            this.csvLoading = false;
            this.reduxActions.update();
        } catch (error) {
            this.csvLoading = false;
            this.reduxActions.update();
            ModalStore.showError(error);
        }
    }
}
