import React, { Component } from 'react';
import { NavLink, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import AdvertisersServiceAPI, { IAdvertiser } from '../../api/AdvertisersServiceAPI';

import AdvertisersConfiguration from './AdvertisersConfiguration';
import AdvertisersLocations from './AdvertisersLocations';
import Header from '../Content/Header/Header';
import Content from '../Content/Content';
import Paper from '../Layout/Paper';
import TabSvgNext from '../Tab/TabNextSvg';
import DetailsStatus from '../Details/DetailsStatus';

import '../Tab/Tab.scss';
import './AdvertisersTab.sass';

interface IAdvertiserTabRouteProps {
    id: string;
}

interface IAdvertiserTabRouteState {
    advertiserStore: IAdvertiser;
    isUpdating: boolean;
    error: Error;
}

export default class AdvertisersTab extends Component<RouteComponentProps<IAdvertiserTabRouteProps>, IAdvertiserTabRouteState> {
    advertiserId: number;

    constructor(props) {
        super(props);

        this.state = {
            isUpdating: false,
            advertiserStore: null,
            error: null
        };
    }

    async componentDidMount(): Promise<void> {
        this.advertiserId = parseInt(this.props.match.params.id, 10);
        await this.getAdvertiser(this.advertiserId);
    }

    async componentWillReceiveProps(nextProps: RouteComponentProps<IAdvertiserTabRouteProps>): Promise<void> {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.setState({ advertiserStore: null });
            this.advertiserId = parseInt(nextProps.match.params.id, 10);
        }
        // On tab change get latest information
        this.getAdvertiser(this.advertiserId);
    }

    async getAdvertiser(id: number): Promise<void> {

        if (id === 0) {
            this.setState({ advertiserStore: {} as IAdvertiser });
            return;
        }

        this.setState({ isUpdating: true, error: null });
        try {
            const advertiserStore = await AdvertisersServiceAPI.getAdvertiser(id);
            this.setState({ advertiserStore });
        } catch (error) {
            this.setState({ error });
        } finally {
            this.setState({ isUpdating: false });
        }
    }

    doesAdvertiserExist(): boolean {
        if (this.advertiserId !== 0 && this.state.advertiserStore) {
            return true;
        }
        return false;
    }

    getAdvertiserHeaderText(): string {
        const { advertiserStore } = this.state;

        if (advertiserStore) {
            if (!advertiserStore.id) {
                return 'New Advertiser';
            }
            return `Edit (${advertiserStore.name})`;
        }
        return '';
    }

    render(): JSX.Element {
        const url = this.props.match.url;

        const header = <Header>
            <h1>{this.getAdvertiserHeaderText()}</h1>
        </Header>;

        const { isUpdating, advertiserStore, error } = this.state;

        return (
            <Content header={header} className={'AdvertisersTab'}>
                <div className='tab-control tab-radius'>
                    <div className='tab-center'>
                        <NavLink to={`${url}/edit`} replace={true}>Edit</NavLink>
                        <TabSvgNext />
                        <NavLink to={`${url}/locations`} className={`${!this.doesAdvertiserExist() ? 'disabled-link' : ''}`} replace={true}>Locations</NavLink>
                    </div>
                </div>

                <Paper className='Content-paper' type='wide'>
                    {advertiserStore ?
                        <Switch>
                            <Route path={`${url}/edit`} exact={true} render={() => <AdvertisersConfiguration id={this.advertiserId} advertiser={advertiserStore} updateParentComponent={() => this.getAdvertiser(this.advertiserId)}/>} />
                            <Route path={`${url}/locations`} render={() => <AdvertisersLocations id={this.advertiserId} advertiser={advertiserStore} />} />
                            <Redirect from={url} to={url.substring(0, url.lastIndexOf('/'))} />
                        </Switch>
                        :
                        <DetailsStatus store={advertiserStore} isUpdating={isUpdating} error={error} />
                    }
                </Paper>
            </Content>
        );
    }
}
