import { Point, Coords, Bounds } from 'google-map-react';
import supercluster from 'points-cluster';

interface IClusterPoints<T> extends Coords {
    numberOfVisits?: number;
    item: T;
}

export interface ICluster<T> extends Point {
    numPoints: number;
    points: IClusterPoints<T>[];
    wx: number;
    wy: number;
    zoom: number;
}

export interface IScale {
    min: number;
    max: number;
}

interface IClusterSettings {
    zoom: number;
    bounds: Bounds;
}

class GoogleMapUtils {

    // View options
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    timeOptions = [];
    date = new Date();

    constructor() {
        this.date.setUTCDate(1);

        for (let i = 1; i <= 12; i++) {
            this.timeOptions.push({
                id: this.date.getUTCMonth() + 1,
                value: `${this.months[this.date.getUTCMonth()]} ${this.date.getFullYear()}`,
                date: new Date(this.date)
            });
            this.date.setUTCMonth(this.date.getUTCMonth() - 1);
        }
    }

    getClusterPoints<T>(data: Coords[], settings: IClusterSettings): { clusters: ICluster<T>[], scale: IScale } {
        const { zoom, bounds } = settings;
        let newList: Coords[] = [];

        newList = data.map((item) => { return { lat: Number(item.lat), lng: Number(item.lng), item }; });

        let cluster = supercluster(newList);

        let values: number[] = [];
        let clusterData: ICluster<T>[] = [];

        clusterData = cluster({ bounds, zoom });
        clusterData.map(item => values.push(item.numPoints));

        return {
            clusters: clusterData,
            scale: {
                min: Math.min(...values),
                max: Math.max(...values)
            }
        };
    }



    zoomToClusterBreakingPoint<T>(item: ICluster<T>, index: number, zoom: number, bounds: Bounds): { coords: Coords, zoom: number } {
        if (item.numPoints && item.numPoints === 1) {
            return {
                coords: {
                    lat: item.y,
                    lng: item.x
                },
                zoom: 17
            };
        }

        const tempItem: ICluster<T> = Object.assign({}, item);

        let newList: Coords[] = tempItem.points.map((point) => { return { lat: Number(point.lat), lng: Number(point.lng) }; });
        const { clusters } = this.getClusterPoints(newList, { zoom, bounds });

        if (clusters.length === 1) {
            return this.zoomToClusterBreakingPoint(tempItem, index, zoom + 1, bounds);
        } else {
            return {
                coords: {
                    lat: tempItem.y,
                    lng: tempItem.x
                },
                zoom
            };
        }
    }


}

export default new GoogleMapUtils();
