import * as React from 'react';
import cn from 'classnames';

import './Space.sass';


interface IProps {
    className?: string;
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
}

const Space: React.SFC<IProps> = (props) => {
    const {className, top = true, right = true, bottom = true, left = true} = props;

    return (
        <div className={cn('Space', {top, right, bottom, left}, className)}/>
    );
};

export default Space;
