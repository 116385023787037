import * as React from 'react';
import cn from 'classnames';
import * as d3 from 'd3';

import './BarChartAxis.sass';


interface Props {
    className?: string;
    axisRef?: React.RefObject<SVGGElement>;
    axis: any;
}

interface State {
    axisRef: React.RefObject<SVGGElement>;
}

class BarChartAxis extends React.Component<Props, State> {
    state: State = {
        axisRef: null,
    };

    constructor(props) {
        super(props);

        this.state.axisRef = this.props.axisRef || React.createRef();
    }

    componentDidUpdate = () => {
        this.renderGrid();
    }

    componentDidMount = () => {
        this.renderGrid();
    }

    renderGrid = () => {
        const {axis} = this.props;
        const {axisRef} = this.state;

        d3.select(axisRef.current).call(axis);
    }


    render() {
        const {className} = this.props;
        const {axisRef} = this.state;

        return (
            <React.Fragment>
                <line className='BarChartAxis-borderLine' y1={0} x1={0} x2='100%' />
                <g className={cn('BarChartAxis', className)} ref={axisRef}>
                </g>
            </React.Fragment>
        );
    }
}

export default BarChartAxis;
