import * as React from 'react';
import './DateTimePicker.scss';
import moment from 'moment';
import SidebarItemIcon from '../../../components/Sidebar/SidebarItem/SidebarItemIcon';

interface IDateTimePickerProps {
    defaultValue: string;
    className?: string;
    name?: string;
    onChange?: (val: string) => void;
    minDate?: string;
    maxDate?: string;
    enableTime?: boolean;
    mustSelect?: boolean; // Date must be selected to be displayed in input field. Default = false
    disabled?: boolean;
}

interface ITimeData {
    time: moment.Moment;
    active: boolean;
}

interface DateTimePickerState {
    calendarDate: moment.Moment;
    prevMonth: moment.Moment;
    nextMonth: moment.Moment;
    visible: boolean;
    mouseHover: boolean;
    weekList: moment.Moment[][];
    timeList: ITimeData[];
    selectedDate: moment.Moment | void;
    minDateMom: moment.Moment;
    maxDateMom: moment.Moment;
    value: string;
}

export default class DateTimePicker extends React.Component<IDateTimePickerProps, DateTimePickerState> {
    timeRef;

    constructor(props) {
        super(props);

        this.state = {
            calendarDate: null,
            nextMonth: null,
            prevMonth: null,
            visible: false,
            mouseHover: true,
            weekList: null,
            timeList: null,
            selectedDate: null,
            minDateMom: null,
            maxDateMom: null,
            value: null
        };

        this.timeRef = React.createRef();
        this.onClick = this.onClick.bind(this);
    }

    prevDefaultValue: string;
    private timeScrolledIntoView: boolean;

    private rootElement: HTMLDivElement | null;
    private timeContainer: HTMLDivElement | null;
    private scrollHeight: number;
    private offsetHeight: number;
    private scrollTimeIntoViewTimeoutHandler: NodeJS.Timer;

    async componentDidMount(): Promise<void> {
        let { minDateMom, maxDateMom } = this.state;

        if (!minDateMom) {
            minDateMom = this.getMomentDate([1, 1, 1]);
            await this.setState({ minDateMom });
        }

        if (!maxDateMom) {
            maxDateMom = this.getMomentDate([9999, 12, 31]);
            await this.setState({ maxDateMom });
        }

        this.prevDefaultValue = this.props.defaultValue;
        this.setValue(this.props.defaultValue);
        document.addEventListener('click', this.onClick);
    }

    componentWillReceiveProps(nextProps: Readonly<IDateTimePickerProps>): void {
        if (nextProps.minDate !== this.props.minDate) {
            this.updateMinMaxDate();
        }

        if (nextProps.maxDate !== this.props.maxDate) {
            this.updateMinMaxDate();
        }

        if (nextProps.defaultValue !== this.prevDefaultValue) {
            this.prevDefaultValue = nextProps.defaultValue;
            this.setValue(nextProps.defaultValue);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.onClick);
    }

    render(): JSX.Element {
        const name = 'time-picker';
        const { calendarDate, nextMonth, prevMonth, visible, weekList, timeList, selectedDate, minDateMom, maxDateMom } = this.state;
        const { enableTime } = this.props;

        return (
            <div
                onClick={(e) => this.onDateClick(e)}
                ref={(el) => this.initRootElement(el)}
                className={`date-time-picker${visible ? ' focused' : ''}${this.props.disabled ? ' disabled' : ''}${!this.props.defaultValue ? ' empty' : ''}`}
            >
                <div className='date-time-picker-icon'>
                    <SidebarItemIcon src='../../../assets/icons/date.svg' />
                </div>
                <div className='date-time-picker-input-container'>
                    <input
                        name={name}
                        className={`date-time-picker-input date-time-picker${enableTime ? '-1' : '-2'}`}
                        disabled
                        ref={this.timeRef}
                    />

                    {visible &&
                        <div className='date-time-popup'>
                            <div className='date-time-popup-arrow-up' />

                            <div className='date-time-popup-table'>
                                <div className='date-time-popup-cell'>
                                    <table>
                                        <caption className='date-time-caption'>
                                            <button
                                                type='button'
                                                className='date-time-btn date-time-btn-left'
                                                onKeyDown={(e) => this.onKeyDown(e)}
                                                onClick={(e) => this.moveTo(prevMonth)}
                                            >
                                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240.823 240.823'>
                                                    <g>
                                                        <path d='M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179   l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816   C52.942,116.507,52.942,124.327,57.633,129.007z' />
                                                    </g>
                                                </svg>
                                            </button>
                                            <button
                                                type='button'
                                                className='date-time-btn date-time-btn-right'
                                                onKeyDown={(e) => this.onKeyDown(e)}
                                                onClick={(e) => this.moveTo(nextMonth)}
                                            >
                                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240.823 240.823'>
                                                    <g>
                                                        <path d='M183.189,111.816L74.892,3.555c-4.752-4.74-12.451-4.74-17.215,0c-4.752,4.74-4.752,12.439,0,17.179   l99.707,99.671l-99.695,99.671c-4.752,4.74-4.752,12.439,0,17.191c4.752,4.74,12.463,4.74,17.215,0l108.297-108.261   C187.881,124.315,187.881,116.495,183.189,111.816z' />
                                                    </g>
                                                </svg>
                                            </button>
                                            {calendarDate.format('MMMM YYYY')}
                                        </caption>
                                        <thead>
                                            <tr>
                                                {weekList && weekList[0].map(day =>
                                                    <th key={day.format('d')}>{day.format('dd')}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {weekList.map((weekDays, index) =>
                                                <tr key={index}>
                                                    {weekDays.map(day =>
                                                        <td key={day.format('d')}>
                                                            <button
                                                                type='button'
                                                                className={`date-time-day-btn ${day.month() !== calendarDate.month() ? 'date-time-gray' : ''} ${day.isSame(selectedDate, 'day') ? 'date-time-selected' : ''}`}
                                                                disabled={minDateMom.isAfter(day, 'day') || maxDateMom.isBefore(day, 'day')}
                                                                onKeyDown={(e) => this.onKeyDown(e)}
                                                                onClick={(e) => this.onDateTimeChange(day)}
                                                            >
                                                                {day.date()}
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {this.props.enableTime &&
                                    <div className='date-time-popup-cell'>
                                        <div className='date-time-caption'>Time</div>
                                        <div className='date-time-popup-time-container' ref={e => this.scrollTimeIntoView(e, false)}>
                                            {timeList.map((timeData) =>
                                                <button
                                                    type='button'
                                                    key={timeData.time.valueOf()}
                                                    onClick={(e) => this.onDateTimeChange(timeData.time)}
                                                    onKeyDown={(e) => this.onKeyDown(e)}
                                                    className={`date-time-popup-time${timeData.active ? ' active' : ''}`}>
                                                    {timeData.time.format('HH:mm')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='Dropdown-arrow'></div>
                </div>
            </div>
        );
    }

    private onDateTimeChange(date: moment.Moment): void {
        this.selectDate(date);
    }

    private onClick(event: MouseEvent): void {
        const el = event.target as HTMLElement;
        if (el && el.closest) {
            const picker = el.closest('.date-time-picker');
            if (!this.rootElement || picker !== this.rootElement) {
                this.setState({ visible: false });
                this.timeScrolledIntoView = false;
            }
        }
    }

    private initRootElement(el: HTMLDivElement | null): void {
        this.rootElement = el;
    }

    onDateClick(focusedElement: React.MouseEvent): void {
        let { visible, selectedDate, calendarDate } = this.state;

        if (!visible) {
            this.invalidate();
            this.setState({ visible: true });

            if (!selectedDate && !this.props.mustSelect) {
                this.selectDate(calendarDate.startOf('day'));
            }
        }
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLElement>, key?: moment.unitOfTime.DurationConstructor): void {
        let { calendarDate } = this.state;

        if (e.keyCode === 9) {
            if (!key || (!this.props.enableTime && key === 'days') || (this.props.enableTime && key === 'm') || (e.shiftKey && key === 'y')) {
                if ((e.target as HTMLElement).blur) {
                    (e.target as HTMLElement).blur();
                }
                this.setState({ visible: false });
                this.timeScrolledIntoView = false;
            }
        } else if (key) {
            if (e.keyCode === 38) {
                e.preventDefault();
                e.stopPropagation();

                // this.selectDate(calendarDate.clone().add(key, 1));
                this.selectElementText(e.target as HTMLInputElement);
                this.scrollTimeIntoView(this.timeContainer, true);
            } else if (e.keyCode === 40) {
                e.preventDefault();
                e.stopPropagation();

                // this.selectDate(calendarDate.clone().add(key, -1));
                this.selectElementText(e.target as HTMLInputElement);
                this.scrollTimeIntoView(this.timeContainer, true);
            }
        }
    }

    private async setValue(newValue: string): Promise<void> {
        let { value, calendarDate, selectedDate, minDateMom, maxDateMom } = this.state;
        value = newValue;

        if (value) {
            calendarDate = this.getMomentDate(value);
            selectedDate = this.getMomentDate(value);

            if (minDateMom && selectedDate.isBefore(minDateMom, 'day')) {
                this.selectDate(minDateMom);
            }

            if (maxDateMom && selectedDate.isAfter(maxDateMom, 'day')) {
                this.selectDate(maxDateMom);
            }

        } else {
            calendarDate = this.getMomentDate(void 0).startOf('day');
            selectedDate = void 0;
        }
        await this.setState({ calendarDate, selectedDate });
        if (this.props.onChange && selectedDate) {
            this.props.onChange(newValue);
        }
        this.invalidate();
    }

    private async moveTo(date: moment.Moment): Promise<void> {
        await this.setState({ calendarDate: date });
        this.invalidate();
    }

    private async selectDate(date: moment.Moment): Promise<void> {
        if (this.scrollTimeIntoViewTimeoutHandler) {
            clearTimeout(this.scrollTimeIntoViewTimeoutHandler);
        }
        this.setValue(date.toISOString());
    }

    private getMomentDate(val?: moment.MomentInput): moment.Moment {
        let date;

        date = moment.utc(val);
        date.milliseconds(0);
        date.seconds(0);

        if (!this.props.enableTime) {
            date.set({ 'h': 0, 'm': 0, 's': 0 });
        }

        return date;
    }

    async invalidate(): Promise<void> {
        let { calendarDate } = this.state;

        let tmpDate = calendarDate.clone().set('date', 1);

        const prevMonth = moment(tmpDate.clone()).add(-1, 'months');
        const nextMonth = moment(tmpDate.clone()).add(1, 'months');

        const weekList = [];

        for (let i = 0; i < 6; ++i) {
            const weekDays: moment.Moment[] = [];

            for (let j = 0; j < 7; ++j) {
                tmpDate.weekday(j);
                weekDays.push(tmpDate);
                tmpDate = tmpDate.clone();
            }

            weekList.push(weekDays);
            tmpDate.add(1, 'days');
        }

        await this.setState({ weekList, prevMonth, nextMonth });

        const timeList: ITimeData[] = [];
        tmpDate = calendarDate.clone().startOf('day');
        const date = tmpDate.date();

        while (tmpDate.date() === date) {
            timeList.push({
                time: tmpDate.clone(),
                active: tmpDate.format('HH:mm') === calendarDate.format('HH:mm')
            });
            tmpDate.add(15, 'm');
        }
        await this.setState({ timeList, calendarDate });
        this.invalidateInputs();
    }

    private async updateMinMaxDate(): Promise<void> {
        let { selectedDate, calendarDate, maxDateMom, minDateMom } = this.state;
        minDateMom = this.getMomentDate(this.props.minDate ? this.props.minDate : [1, 1, 1]);
        if (selectedDate && selectedDate.isBefore(minDateMom, 'day')) {
            this.selectDate(minDateMom);
        }

        maxDateMom = this.getMomentDate(this.props.maxDate ? this.props.maxDate : [9999, 12, 31]);
        if (maxDateMom.isBefore(minDateMom)) {
            maxDateMom = minDateMom;
        }

        if (selectedDate && selectedDate.isAfter(maxDateMom, 'day')) {
            this.selectDate(maxDateMom);
        }
        await this.setState({ selectedDate, calendarDate, maxDateMom, minDateMom });
    }

    private async scrollTimeIntoView(timeContainer: HTMLDivElement | null, ignoreTimeScrolledIntoView: boolean): Promise<void> {
        const { calendarDate } = this.state;

        this.timeContainer = timeContainer;

        if (!timeContainer) {
            return;
        }

        if (this.timeScrolledIntoView && !ignoreTimeScrolledIntoView) {
            return;
        }

        this.timeScrolledIntoView = true;

        const minDif = calendarDate.diff(calendarDate.clone().set({ h: 0, m: 0 }), 'm');
        const ratio = minDif / (24 * 60 - 15);

        if (!this.scrollHeight) {
            this.scrollHeight = timeContainer.scrollHeight;
        }

        if (!this.offsetHeight) {
            this.offsetHeight = timeContainer.offsetHeight;
        }

        timeContainer.scrollTop = this.scrollHeight * ratio - this.offsetHeight / 2 - 5;
    }

    private invalidateInputs(): void {
        const { selectedDate } = this.state;
        const { enableTime } = this.props;

        if (selectedDate) {
            if (enableTime) {
                this.timeRef.current.value = selectedDate.format('MM/DD/YYYY   HH:mm');
            } else {
                this.timeRef.current.value = selectedDate.format('MM/DD/YYYY');
            }
        } else {
            this.timeRef.current.value = 'Select Date';
        }
    }

    private selectElementText(elem: HTMLInputElement): void {
        if (elem.setSelectionRange) {
            elem.setSelectionRange(0, elem.value.length);
        } else if (elem.selectionStart) {
            elem.selectionStart = 0;
            elem.selectionEnd = elem.value.length;
        }
    }

    isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
}
