/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import * as React from 'react';
import { Redirect } from 'react-router';
import { ConfigManager } from '../../../api/GetConfigAPI';


const NotFound: React.SFC<{}> = () => {
    let env = ConfigManager.getEnvironment();
    let redirect = '/';
    if (env.pages[0]) {
        redirect = env.pages[0].path;
    }

    return (
        <Redirect to={ redirect } />
    );
};

export default NotFound;
