import xmlParser from 'xml2js';

import { request } from '../../api/RequestAPI';
import { IPageParams } from './ListStoreWithPagination';
import { DropdownItem } from '~/components/Layout/Dropdown/Dropdown';
import { IReportHeaders } from '../../typings/src/reports/IReport';
import { Parser } from 'json2csv';

import ReduxActions from '../actions/index';
import odataUtils from '../../utils/odataUtils';
import TableFilterStore from './TableFilterStore';
import ModalStore from './ModalStore';

import utils from '../../utils';

export class ODataStore<T> extends TableFilterStore {
    isLoading: boolean = true;
    isFilteredCsvLoading: boolean = false;
    items: T[] = [];
    itemsCount: number = 0;
    error: Error;

    params: IPageParams = {};

    tableAllHeaders: IReportHeaders[] = [];
    dropDown: DropdownItem[] = [];

    constructor(protected path: string, protected listPath: string = '', protected reduxStorePath: string, private hiddenFilter: string[]) {
        super(new ReduxActions(reduxStorePath));
    }

    preload(): void {
        //
    }

    async loadFromSearch(search: string) {
        await this.load(utils.searchToParams(search));
    }

    async load(params?: IPageParams): Promise<void> {
        this.isLoading = true;
        this.error = null;
        this.items = [];
        this.reduxActions.update();

        if (params) {
            this.params = params;
        }

        this.params = Object.assign(
            {
                page: 1,
                limit: 10
            },
            this.params);
        const query = odataUtils.paramsToOdataQuery(this.params, this.tableAllHeaders);

        try {
            const response = await request(`${this.path.replace(/ /g, '')}${this.listPath}${query}`, 'get');
            this.itemsCount = response['@odata.count'];
            this.items = response.value;
        } catch (error) {
            this.items = [];
            this.itemsCount = 0;
            this.error = error;
        } finally {
            this.isLoading = false;
            this.reduxActions.update();
        }
    }

    initDropdownByOdata(): void {
        const values = this.tableAllHeaders;
        if (!values || !values.length) {
            return;
        }

        this.dropDown = [];
        for (const value of values) {
            this.dropDown.push({
                checked: this.isDropdownValueChecked(value.name),
                id: value.name,
                value: utils.capitalizeWords(value.name),
            });
        }

        this.reduxActions.setCustom('tableAllHeaders', this.tableAllHeaders);
        this.reduxActions.setCustom('dropDown', this.dropDown);
    }

    isDropdownValueChecked (id: string): boolean {
        return !this.hiddenFilter.includes(id);
    }

    resetSelectedHeaders () {
        this.dropDown = [];
        this.reduxActions.setCustom('dropDown', this.dropDown);
    }

    async getReportsHeaders(): Promise<void> {

        try {
            let entityName = this.reduxStorePath;
            let metadata;
            const metadataAsXmlText = await request('/odata/$metadata', 'get', { return: 'text' });
            xmlParser.parseString(metadataAsXmlText, (err, json) => metadata = json);

            const path = ['edmx:Edmx', 'edmx:DataServices', '0', 'Schema', '0', 'EntityType'];
            const entities = utils.getNestedObjectByString(metadata, path.join('.'));
            this.tableAllHeaders = odataUtils.getEntityHeaders(entities, entityName);
            this.initDropdownByOdata();
        } catch (error) {
            //
        }
    }

    async downloadFilteredCsvFile(reportName, params) {
        this.isFilteredCsvLoading = true;
        this.reduxActions.update();

        try {
            const parser = new Parser({});
            const filteredData = await this.getFilteredData(params);
            const data = parser.parse(filteredData);
            await utils.downloadCSV(reportName, data);
        } catch (error) {
            ModalStore.showError(error);
        } finally {
            this.isFilteredCsvLoading = false;
            this.reduxActions.update();
        }
    }

    async getFilteredData(parameters) {
        const params = JSON.parse(JSON.stringify(parameters));
        params.limit = this.itemsCount;
        params.page = 1;
        const query = odataUtils.paramsToOdataQuery(params, this.tableAllHeaders);
        const response = await request(`${this.path.replace(/ /g, '')}${this.listPath}${query}`, 'get');
        return response.value;
    }
}
