import Validators from './validators/index';
import { IValidationErrors } from './errors/ValidationError';
import { IAdvertiser } from '../../../api/AdvertisersServiceAPI';

export function AdvertiserValidation(advertiser: IAdvertiser): IValidationErrors {

    let errors: IValidationErrors = {};

    Validators.require(advertiser, 'name', errors);
    Validators.require(advertiser, 'description', errors);
    Validators.require(advertiser, 'imageUrl', errors);
    Validators.url(advertiser, 'imageUrl', errors);

    return errors;
}

