/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { ConfigManager } from 'api/GetConfigAPI';

type Props = RouteComponentProps<any>;

class ProtectedRoute extends React.Component<Props, {}> {
    isLoggedIn() {
        return new Date(localStorage.getItem('accessTokenExpiresAt')) > new Date();
    }

    render() {
        if (!this.isLoggedIn()) {
            ConfigManager.getConfig().then(config => {
                this.redirect(config);
            }).catch(e => {
                if (e === 'noEnvironmentSelected') {
                    return window.location.assign('/environment');
                } else {
                    console.error(e);
                }
            });
            return null;
        }

        return this.props.children;
    }

    redirect(apiConfig) {
        let scopes = [
            'admin:*:write',
            'superadmin:*:*',
            'marketingmanager:*:*',
            'dataentry:*:*',
            'service:giftcard:reward'
        ];
        localStorage.setItem('tmpEnv', apiConfig.env.id);
        localStorage.removeItem('env');

        const redirectUrl = `${process.env.LOGIN_URL}/login-azure?` +
            `redirect=${encodeURIComponent(process.env.SITE_URL + '/login/')}` +
            `&client_id=${apiConfig.clientId}` +
            `&scope=${scopes.join(' ')}` +
            `&state=${JSON.stringify({from: this.props.location.pathname})}` +
            `&env=${apiConfig.env.config}`;

        window.location.assign(redirectUrl);
    }
}

export default withRouter<any>(ProtectedRoute);
