/*
*  Copyright © 2016, Connected Travel, LLC  All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC.   If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import { ConfigManager } from './GetConfigAPI';

export async function request(url: string, method: string, options?: any, data?: any): Promise<any> {
    let res;

    let apiConfig;
    try {
        apiConfig = await ConfigManager.getConfig();
    } catch (e) {
        if (e === 'noEnvironmentSelected') {
            return window.location.assign('/environment');
        }
        throw e;
    }
    const accessToken = localStorage.getItem('accessToken');

    options = options ? options : {};
    options.method = method;
    options.headers = options.headers ? options.headers : {};
    options.headers['Ocp-Apim-Subscription-Key'] = apiConfig.ocpApimSubscriptionKey;
    options.headers['Authorization'] = `Bearer ${accessToken}`;

    if (['post', 'put'].includes(method)) {
        options.body = data;
    }

    try {
        res = await fetch(`${apiConfig.baseUrl}${url}`, options);
    } catch (e) {
        throw new Error(`API request failed: ${e}`);
    }

    if (res.status === 401) {
        localStorage.clear();
        return window.location.assign('/environment');
    }

    if (!res.ok) {
        let err = await res.json();
        err.statusCode = res.status;
        throw err;
    }

    if (options.isBlob) {
        return res.blob();
    }

    if (options.getHeader) {
        return {
            headers: res.headers.get(options.getHeader),
            data: await res.json()
        };
    }

    if (options.return === 'text') {
        return await res.text();
    }

    try {
        const response = await res.json();
        return response;
    } catch (error) {
        throw new Error(`Oops, something went wrong.`);
    }
}
